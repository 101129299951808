import { Injectable, inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpResponse, HttpClient, HttpParams, HttpContext } from '@angular/common/http';
import { Guid } from 'guid-typescript';
import { CustomerResourceParameter } from '@core/domain-classes/customer/customer-resource-parameter';
import { Customer } from '@core/domain-classes/customer/customer';
import { CustomerPayment } from '@core/domain-classes/customer/customer-payment';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { mergeMap, take } from 'rxjs/operators';
import { AppRoutes } from '../../shared/constants';
import { PASS_SPINNER } from '../../shared/interceptors/http-interceptor.module';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  constructor(private http: HttpClient) {}

  sendCustomerExtra(id) {
    return this.http.post(`customer/sendcustomerextre`, {
      customerId: id,
    });
  }

  addTransaction(transaction) {
    return this.http.post('customertransaction', transaction);
  }

  updateTransaction(transaction) {
    return this.http.put(`customertransaction/${transaction.id}`, transaction);
  }

  deleteTransaction(id) {
    return this.http.delete(`customertransaction/${id}`);
  }

  static getCustomersResolver() {
    const resource = new CustomerResourceParameter();
    resource.pageSize = 50;
    resource.orderBy = 'customerName asc';

    return inject(CustomerService).getCustomers(resource);
  }

  static getTransactionsResolver(route: ActivatedRouteSnapshot) {
    return inject(CustomerService).getTransactions(route.paramMap.get('id'));
  }

  static getCustomerResolver(route: ActivatedRouteSnapshot) {
    const id = route.paramMap.get('id');
    if (id === 'add') {
      return null;
    }
    return inject(CustomerService)
      .getCustomer(id)
      .pipe(
        take(1),
        mergeMap((customer) => {
          if (customer) {
            return of(customer);
          } else {
            inject(Router).navigate([AppRoutes.customersList]);
            return null;
          }
        }),
      );
  }

  getTransactions(id: string) {
    return this.http.get(`customertransaction?customerId=${id}`);
  }

  getTransaction(id: string) {
    return this.http.get(`customertransaction/${id}`);
  }

  getCustomers(resourceParams: CustomerResourceParameter, passSpinner = false): Observable<HttpResponse<Customer[]>> {
 
    const url = 'customer';
    const customParams = new HttpParams()
      .set('fields', resourceParams?.fields ?? '')
      .set('orderBy', resourceParams?.orderBy ?? '')
      .set('pageSize', resourceParams?.pageSize?.toString() ?? '')
      .set('skip', resourceParams?.skip?.toString() ?? '')
      .set('searchQuery', resourceParams?.searchQuery ?? '')
      .set('customerName', resourceParams?.customerName ?? '')
      .set('mobileNo', resourceParams?.mobileNo ?? '')
      .set('phoneNo', resourceParams?.phoneNo ?? '')
      .set('email', resourceParams?.email ?? '')
      .set('contactPerson', resourceParams?.contactPerson ?? '')
      .set('website', resourceParams?.website ?? '');
    return this.http.get<Customer[]>(url, {
      params: customParams,
      observe: 'response',
      context: new HttpContext().set(PASS_SPINNER, passSpinner),
    });
  }

  getCustomersForDropDown(searchBy: string, searchString: string): Observable<Customer[]> {
    const url = 'customer/search';
    if (searchString && searchBy) {
      let params = `?searchQuery=${searchString.trim()}&searchBy=${searchBy}&pageSize=10`;
      return this.http.get<Customer[]>(url + params);
    }
    return of([]);
  }

  calculateBalance(debt, credit, isBagde = false) {
    return debt - credit < 0
      ? {
          total: (debt - credit) * -1,
          color: isBagde ? 'badge-danger' : 'text-danger',
        }
      : {
          total: debt - credit,
          color: isBagde ? 'badge-success' : 'text-success',
        };
  }

  calculateTotalBalance(items: [debt: number, credit: number][] = []) {
    if (items.length === 0) {
      return {
        total: 0,
        color: 'text-secondary',
      };
    }

    const total = items.map((x) => x[0] - x[1]).reduce((sum, a) => (sum += a));
    if (total < 0) {
      return {
        total: total * -1,
        color: 'text-danger',
      };
    } else {
      return {
        total: total,
        color: 'text-success',
      };
    }
  }

  static getExtrasResolver(route: ActivatedRouteSnapshot) {
    return inject(CustomerService).getExtras(route.paramMap.get('id'));
  }

  getExtras(id: string) {
    return this.http.get(`customer/getcustomerextre/${id}`).toPromise();
  }

  getWalkInCustomer() {
    const url = 'customer/walkIn';
    return this.http.get<Customer>(url);
  }

  getCustomer(id: string): Observable<Customer> {
    const url = 'customer/' + id;
    return this.http.get<Customer>(url);
  }

  deleteCustomer(id: string): Observable<void> {
    const url = 'customer/' + id;
    return this.http.delete<void>(url);
  }
  updateCustomer(id: string, customer: Customer): Observable<Customer> {
    const url = 'customer/' + id;
    return this.http.put<Customer>(url, customer);
  }
  saveCustomer(customer: Customer): Observable<Customer> {
    const url = 'customer';
    return this.http.post<Customer>(url, customer);
  }
  checkEmailOrPhoneExist(email: string, mobileNo: string, id: string | Guid): Observable<boolean> {
    const url = `customer/${id}/Exist?email=${email}&mobileNo=${mobileNo}`;
    return this.http.get<boolean>(url);
  }

  static getCustomerPaymentsResolver() {
    const customerResource = new CustomerResourceParameter();
    customerResource.orderBy = 'customerName asc';

    return inject(CustomerService).getCustomerPayments(customerResource);
  }

  getCustomerPayments(resourceParams: CustomerResourceParameter): Observable<HttpResponse<CustomerPayment[]>> {
    const url = 'customer/GetCustomerPayment';
    const customParams = new HttpParams()
      .set('Fields', resourceParams.fields)
      .set('OrderBy', resourceParams.orderBy)
      .set('PageSize', resourceParams.pageSize.toString())
      .set('Skip', resourceParams.skip.toString())
      .set('SearchQuery', resourceParams.searchQuery)
      .set('customerName', resourceParams.customerName);
    return this.http.get<CustomerPayment[]>(url, {
      params: customParams,
      observe: 'response',
    });
  }

  getCustomerPaymentsReport(resourceParams: CustomerResourceParameter): Observable<HttpResponse<CustomerPayment[]>> {
    const url = 'customer/GetCustomerPayment/report';
    const customParams = new HttpParams()
      .set('Fields', resourceParams.fields)
      .set('OrderBy', resourceParams.orderBy)
      .set('PageSize', resourceParams.pageSize.toString())
      .set('Skip', resourceParams.skip.toString())
      .set('SearchQuery', resourceParams.searchQuery)
      .set('customerName', resourceParams.customerName);
    return this.http.get<CustomerPayment[]>(url, {
      params: customParams,
      observe: 'response',
    });
  }
}
