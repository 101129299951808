import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ToastrModule } from 'ngx-toastr';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { HttpInterceptorModule } from './shared/interceptors/http-interceptor.module';
import { AppStoreModule } from './store/app-store.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PendingInterceptorModule } from '@shared/loading-indicator/pending-interceptor.module';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  OWL_DATE_TIME_FORMATS,
  OWL_DATE_TIME_LOCALE,
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
} from 'ng-pick-datetime-ex';
import { TranslationService } from '@core/services/translation.service';
import { delayBootstrapping } from './boostrap-loader';
import { DOCUMENT } from '@angular/common';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { CommonService } from './core/services/common.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { LOADING_BAR_CONFIG, LoadingBarModule, LoadingBarService } from '@ngx-loading-bar/core';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { AppSettings } from './app-settings';
import { NgxSpinnerModule } from 'ngx-spinner';
import { I18nMaterialModule } from './shared/i18n';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogConfig } from '@angular/material/dialog';
import { NgxEditorModule } from 'ngx-editor';
import { AppRoutes } from './shared/constants';
import { MatSelectModule } from '@angular/material/select';
import {
  MAT_SELECTSEARCH_DEFAULT_OPTIONS,
  MatSelectSearchOptions,
  NgxMatSelectSearchModule,
} from 'ngx-mat-select-search';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    CoreModule,
    BrowserAnimationsModule,
    HttpClientModule,
    I18nMaterialModule,
    HttpInterceptorModule,
    LoadingBarModule,
    LoadingBarRouterModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => {
          return new TranslateHttpLoader(http, './assets/i18n/', '.json');
        },
        deps: [HttpClient],
      },
    }),
    AppStoreModule,
    PendingInterceptorModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgxEditorModule,
    ToastrModule.forRoot({
      enableHtml: true,
    }),
    NgxSpinnerModule,
    NgSelectModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: delayBootstrapping,
      deps: [TranslationService, DOCUMENT, TranslateService],
      multi: true,
    },
    { provide: LOADING_BAR_CONFIG, useValue: { latencyThreshold: 100 } },
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'tr' },
    { provide: OWL_DATE_TIME_FORMATS, useValue: 'tr' },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        disableClose: false,
      } as MatDialogConfig,
    },
    {
      provide: MAT_SELECTSEARCH_DEFAULT_OPTIONS,
      useFactory: (translate: TranslateService) =>
        ({
          noEntriesFoundLabel: translate.instant('NG_SELECT.NOT_FOUND_TEXT'),
          placeholderLabel: translate.instant('NG_SELECT.TYPE_TO_SEARCH_TEXT'),
        } as MatSelectSearchOptions),
      deps: [TranslateService],
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
  constructor(
    router: Router,
    commonService: CommonService,
    translate: TranslationService,
    progress: LoadingBarService,
    appSettings: AppSettings,
  ) {
    new MutationObserver((mutations) => {
      mutations.forEach((mutation: any) => {
        if (mutation.type === 'childList') {
          mutation.addedNodes.forEach((node) => {
            if (node.nodeName === 'TD' && node.innerHTML.trim() === '') {
              node.innerHTML = '----';
            } else if (node.id == 'pass') {
              node.innerHTML = '----';
            }
          });
        }
      });
    }).observe(document.body, {
      childList: true,
      subtree: true,
    });

    const opener = progress.useRef('opener');

    opener.start();

    const lang = translate.getSelectedLanguage();
    const html = document.querySelector('html');

    html.setAttribute('lang', lang);
    html.setAttribute('dir', lang === 'ar' ? 'rtl' : 'ltr');

    router.events.subscribe((e: any) => {
      if (e instanceof NavigationStart) {
        appSettings.navigationCount.next(appSettings.navigationCount.value + 1);

        if (e.url == router.url || router.url == AppRoutes.initRoute) {
          progress.useRef('router').stop();
        }
      }

      if (e instanceof NavigationEnd || e instanceof NavigationCancel || e instanceof NavigationError) {
        appSettings.navigationCount.next(appSettings.navigationCount.value - 1);
      }

      if (e instanceof NavigationEnd) {
        commonService.setCurrentUrl(e.url);

        setTimeout(() => {
          opener.stop();
        }, 250);

        setTimeout(() => {
          appSettings.appLoaded.next(true);
        }, 600);
      }
    });
  }
}
