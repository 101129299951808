import { Component, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { CompanyProfile } from '@core/domain-classes/company-profile';
import { OnlineUser } from '@core/domain-classes/online-user';
import { UserAuth } from '@core/domain-classes/user/user-auth';
import { SecurityService } from '@core/security/security.service';
import { SignalrService } from '@core/services/signalr.service';
import { TranslationService } from '@core/services/translation.service';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from './base.component';
import { AppSettings } from './app-settings';

@Component({
  selector: 'app-root',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
  template: `<router-outlet *ngIf="appSettings.appLoaded.value"></router-outlet>

    <div *ngIf="!appSettings.appLoaded.value" class="opener">
      <div class="brand">
        <img alt="favicon" height="125" src="../favicon.ico" width="125" />
      </div>

      <h3 class="text-primary">{{ env.title }}</h3>

      <div class="border border-1 rounded-4 bg-body-secondary bg-opacity-25" style="width: 250px">
        <ngx-loading-bar
          class="p-1"
          style="top: 0.6px; margin-left: 0.5px; margin-right: 0.5"
          [includeSpinner]="false"
          height="8px"
          ref="opener"
          [fixed]="false"
        >
        </ngx-loading-bar>
      </div>
      <span class="pt-4 fw-light">{{ 'LOADING' | translate }}</span>
    </div>

    <ngx-loading-bar [includeSpinner]="false" height="6px" ref="router"></ngx-loading-bar>

    <ngx-spinner color="var(--primary)" size="large" type="ball-clip-rotate"
      ><span class="text-white">{{ 'LOADING' | translate }}</span></ngx-spinner
    > `,
})
export class AppComponent extends BaseComponent {
  loaded = false;

  constructor(
    private signalrService: SignalrService,
    private securityService: SecurityService,
    public translate: TranslateService,
    public translationService: TranslationService,
    private route: ActivatedRoute,
    private titleService: Title,
    public appSettings: AppSettings,
  ) {
    super(translationService);
    this.setProfile();
    this.signalrService.startConnection().then((resolve) => {
      if (resolve) {
        this.signalrService.handleMessage();
        this.getAuthObj();
      }
    });
    this.companyProfileSubscription();
  }

  ngAfterViewInit(): void {
    // (function () {
    //   var h, a, f;
    //   a = document.getElementsByTagName('link');
    //   for (h = 0; h < a.length; h++) {
    //     f = a[h];
    //     if (f.rel.toLowerCase().match(/stylesheet/) && f.href) {
    //       var g = f.href.replace(/(&|\?)rnd=\d+/, '');
    //       f.href = g + (g.match(/\?/) ? '&' : '?');
    //       f.href += 'rnd=' + new Date().valueOf();
    //     }
    //   }
    // })();
  }

  setProfile() {
    this.route.data.subscribe((data: { profile: CompanyProfile }) => {
      if (data.profile) {
        this.securityService.updateProfile(data.profile);
      }
    });
  }

  companyProfileSubscription() {
    this.securityService.companyProfile.subscribe((profile) => {
      if (profile) {
        this.titleService.setTitle(profile.title);
      }
    });
  }

  getAuthObj() {
    this.sub$.sink = this.securityService.securityObject$.subscribe((c: UserAuth) => {
      if (c) {
        const online: OnlineUser = {
          email: c.email,
          id: c.id,
          connectionId: this.signalrService.connectionId,
          companyId: c.companyId,
        };
        this.signalrService.addUser(online);
      }
    });
  }
}
