import { Component } from '@angular/core';
import { of } from 'rxjs';
import { Tax } from '@core/domain-classes/tax';
import { FormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Product } from '@core/domain-classes/product/product';
import { Customer } from '@core/domain-classes/customer/customer';
import { Warehouse } from '@core/domain-classes/warehouse';
import { CustomerResourceParameter } from '@core/domain-classes/customer/customer-resource-parameter';
import { ProductResourceParameter } from '@core/domain-classes/product/product-resource-parameter';
import { UnitConversation } from '@core/domain-classes/unit-conversation';
import { CustomerService } from '../../pages/customers/customer.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductService } from '../../pages/products/products.service';
import { QuantitiesUnitPriceTaxPipe } from '@shared/pipes/quantities-unitprice-tax.pipe';
import { QuantitiesUnitPricePipe } from '@shared/pipes/quantities-unitprice.pipe';
import { TranslationService } from '@core/services/translation.service';
import { BaseComponent } from '../../base.component';
import { debounceTime, delay, distinctUntilChanged, finalize, switchMap, tap } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import { Operators } from '@core/domain-classes/operator';
import { SalesInvoiceService } from '../../pages/sales/sales-invoice.service';
import { MatTableDataSource } from '@angular/material/table';
import { PurchaseInvoiceService } from '../../pages/purchases/purchase-invoice/purchase-invoice.service';
import { SupplierService } from '../../pages/suppliers/supplier.service';
import { SupplierResourceParameter } from '@core/domain-classes/supplier-resource-parameter';

@Component({
  selector: 'manage-invoice',
  host: { class: 'position-relative' },
  template: `<form [formGroup]="salesInvoiceForm" (ngSubmit)="submit($event)">
    <div class="page-header-main mb-3">
      <div class="row align-items-center justify-content-between">
        <div class="col-md-auto col-sm-auto">
          <div class="content-header">
            <h1>
              {{ 'TITLES.MANAGE' | translate : { value: title | translate } }}
              (<a class="text-info">{{ salesInvoiceForm.get('invoiceNumber').value }}</a
              >)
            </h1>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col form-group">
        <label class="form-label"> {{ 'INVOICE_NUMBER' | translate }} <span class="text-danger">*</span></label>
        <input class="form-control" type="text" formControlName="invoiceNumber" />
        <div
          class="text-danger"
          *ngIf="
            salesInvoiceForm.get('invoiceNumber')?.hasError('required') && salesInvoiceForm.get('invoiceNumber').touched
          "
        >
          {{ 'VALIDATIONS.REQUIRED' | translate : { value: 'INOVICE_NUMBER' | translate } }}
        </div>
      </div>
      <div class="col form-group">
        <label class="form-label"> {{ 'FIELDS.INVOICE_DATE' | translate }} <span class="text-danger">*</span></label>
        <input
          class="form-control"
          type="text"
          formControlName="siCreatedDate"
          [owlDateTimeTrigger]="owlSiCreatedDate"
          [owlDateTime]="owlSiCreatedDate"
          placeholder="{{ 'PLACEHOLDERS.CHOOSE_INVOICE_DATE' | translate }}"
        />
        <owl-date-time [pickerType]="'calendar'" #owlSiCreatedDate></owl-date-time>
        <div
          class="text-danger"
          *ngIf="
            salesInvoiceForm.get('siCreatedDate')?.hasError('required') && salesInvoiceForm.get('siCreatedDate').touched
          "
        >
          {{ 'VALIDATIONS.REQUIRED' | translate : { value: 'FIELDS.INVOICE_DATE' | translate } }}
        </div>
      </div>
      <div class="col form-group">
        <label class="form-label text-danger">{{ (data.isSales ? 'CUSTOMER' : 'SUPPLIER') | translate }} *</label>
        <ng-select
          appendTo="manage-invoice"
          formControlName="customerId"
          [placeholder]="
            'PLACEHOLDERS.SELECT' | translate : { value: (data.isSales ? 'CUSTOMER' : 'SUPPLIER') | translate }
          "
          [items]="customers"
          [hideSelected]="true"
          [bindLabel]="data.isSales ? 'customerName' : 'supplierName'"
          bindValue="id"
          [virtualScroll]="true"
          [searchable]="true"
          [selectableGroup]="true"
          [loading]="customerResource.loading"
          (search)="customerNameSearch($event.term)"
        >
          <ng-template ng-option-tmp let-item="item">
            {{ data.isSales ? item.customerName : item.supplierName }}
          </ng-template>
        </ng-select>
        <div
          class="text-danger"
          *ngIf="salesInvoiceForm.get('customerId')?.hasError('required') && salesInvoiceForm.get('customerId').touched"
        >
          {{ 'VALIDATIONS.REQUIRED' | translate : { value: (data.isSales ? 'CUSTOMER' : 'SUPPLIER') | translate } }}
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col form-group">
        <label class="form-label">{{ 'FIELDS.TERMS_AND_CONDITIONS' | translate }} </label>
        <div class="input-group">
          <textarea
            [placeholder]="'PLACEHOLDERS.INPUT' | translate : { value: 'FIELDS.TERMS_AND_CONDITIONS' | translate }"
            formControlName="termAndCondition"
            class="form-control"
          >
          </textarea>
        </div>
      </div>
      <div class="col form-group">
        <label class="form-label">{{ 'FIELDS.NOTES' | translate }} </label>
        <div class="input-group">
          <textarea
            [placeholder]="'PLACEHOLDERS.INPUT' | translate : { value: 'FIELDS.NOTES' | translate }"
            formControlName="note"
            class="form-control"
          >
          </textarea>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-6 form-group">
        <h6 class="form-label">{{ 'SCAN_BARCODE' | translate }}</h6>
        <input
          class="form-control"
          formControlName="filterBarCodeValue"
          type="text"
          [placeholder]="'PLACEHOLDERS.SCAN' | translate : { value: 'BARCODE' | translate }"
        />
      </div>
    </div>

    <div class="row">
      <div class="col form-group text-right">
        <button type="button" class="btn blue-btn btn-sm" (click)="addAnotherProduct()">
          <i class="fas fa-plus"></i> {{ 'ADD_ANOTHER_PRODUCT' | translate }}
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col table-responsive" formArrayName="salesInvoiceItems">
        <table mat-table [dataSource]="dataSource" class="table table-bordered">
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef></th>
            <td
              style="max-width: 2.90vw; min-width: 2.90vw;"
              mat-cell
              [formGroupName]="i"
              *matCellDef="let row; let i = index"
            >
              <div class="d-flex justify-content-center">
                <button
                  class="btn btn-danger btn-sm"
                  type="button"
                  *ngIf="salesInvoiceItems?.controls.length !== 1"
                  (click)="removeSalesInvoiceItem(i)"
                >
                  <i class="fas fa-trash-alt"></i>
                </button>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="productId">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'PRODUCT' | translate }}
              <span class="text-danger">*</span>
            </th>
            <td
              style="max-width: 10vw; min-width: 10vw;"
              mat-cell
              [formGroupName]="i"
              *matCellDef="let row; let i = index"
            >
              <mat-select
                class="form-control"
                formControlName="productId"
                (selectionChange)="productNameChange($event.value, i)"
                [placeholder]="'PLACEHOLDERS.SELECT' | translate : { value: 'PRODUCT' | translate }"
              >
                <ng-option>
                  <ngx-mat-select-search [searching]="productResource.loading" formControlName="filterProductValue">
                  </ngx-mat-select-search>
                </ng-option>
                <mat-option *ngFor="let item of filterProductsMap[i]" [value]="item.id">
                  {{ item.name }}
                </mat-option>
              </mat-select>

              <div
                class="text-danger p-1"
                *ngIf="row.get('productId').touched && row.get('productId').hasError('required')"
              >
                {{ 'REQUIRED_FIELD' | translate }}
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="warehouseId">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'WAREHOUSE' | translate }}
            </th>
            <td
              style="max-width: 8vw; min-width: 8vw;"
              mat-cell
              [formGroupName]="i"
              *matCellDef="let row; let i = index"
            >
              <mat-select
                [placeholder]="'PLACEHOLDERS.SELECT' | translate : { value: 'WAREHOUSE' | translate }"
                class="form-control"
                formControlName="warehouseId"
              >
                <mat-option *ngFor="let item of warehouseMap[i]" [value]="item.id">
                  {{ item.name }}
                </mat-option>
              </mat-select>
            </td>
          </ng-container>
          <ng-container matColumnDef="unitId">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'UNIT' | translate }}
              <span class="text-danger">*</span>
            </th>
            <td
              style="max-width: 10vw; min-width: 10vw;"
              mat-cell
              [formGroupName]="i"
              *matCellDef="let row; let i = index"
            >
              <mat-select
                [placeholder]="'PLACEHOLDERS.SELECT' | translate : { value: 'UNIT' | translate }"
                class="form-control"
                formControlName="unitId"
                (selectionChange)="unitChange($event, i)"
              >
                <mat-option *ngFor="let item of unitsMap[i]" [value]="item.id">
                  {{ item.name }}
                </mat-option>
              </mat-select>

              <div class="text-danger p-1" *ngIf="row.get('unitId').touched && row.get('unitId').hasError('required')">
                {{ 'REQUIRED_FIELD' | translate }}
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="unitPrice">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'PRICE' | translate }}
              <span class="text-danger">*</span>
            </th>
            <td
              style="max-width: 10vw; min-width: 10vw;"
              mat-cell
              [formGroupName]="i"
              *matCellDef="let row; let i = index"
            >
              <input
                class="form-control"
                type="number"
                min="0"
                (change)="getAllTotal()"
                formControlName="unitPrice"
                [placeholder]="'PLACEHOLDERS.INPUT' | translate : { value: 'FIELDS.UNIT_PRICE' | translate }"
              />
              <div
                class="text-danger p-1"
                *ngIf="row.get('unitPrice').touched && row.get('unitId').hasError('unitPrice')"
              >
                {{ 'REQUIRED_FIELD' | translate }}
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="quantity">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'QUANTITY' | translate }}
              <span class="text-danger">*</span>
            </th>
            <td
              style="max-width: 8vw; min-width: 8vw;"
              mat-cell
              [formGroupName]="i"
              *matCellDef="let row; let i = index"
            >
              <input
                min="0"
                class="form-control"
                type="number"
                (change)="getAllTotal()"
                formControlName="quantity"
                [placeholder]="'PLACEHOLDERS.INPUT' | translate : { value: 'QUANTITY' | translate }"
              />

              <div
                class="text-danger p-1"
                *ngIf="row.get('quantity').touched && row.get('quantity').hasError('required')"
              >
                {{ 'REQUIRED_FIELD' | translate }}
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="subtotalBeforeDiscount">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'SUBTOTAL_BEFORE_DISCOUNT' | translate }}
            </th>
            <td
              style="max-width: 11vw; min-width: 11vw;"
              class="text-right"
              mat-cell
              [formGroupName]="i"
              *matCellDef="let row; let i = index"
            >
              {{ row.get('quantity').value | quantitiesunitprice : row.get('unitPrice').value | customCurrency }}
            </td>
          </ng-container>

          <ng-container matColumnDef="discountPercentage">
            <th mat-header-cell *matHeaderCellDef>{{ 'DISCOUNT' | translate }}%</th>
            <td
              style="max-width: 5.5vw; min-width: 5.5vw;"
              mat-cell
              [formGroupName]="i"
              *matCellDef="let row; let i = index"
            >
              <input
                max="100"
                maxlength="3"
                min="0"
                type="number"
                class="form-control"
                formControlName="discountPercentage"
                (change)="getAllTotal()"
              />
            </td>
          </ng-container>

          <ng-container matColumnDef="subtotalAfterDiscount">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'SUBTOTAL_AFTER_DISCOUNT' | translate }}
            </th>
            <td
              style="max-width: 11vw; min-width: 11vw;"
              class="text-right"
              mat-cell
              [formGroupName]="i"
              *matCellDef="let row; let i = index"
            >
              {{
                row.get('quantity').value
                  | quantitiesunitprice : row.get('unitPrice').value : row.get('discountPercentage').value
                  | customCurrency
              }}
              <div class="text-secondary" *ngIf="row.get('discountPercentage').value != 0">
                {{ 'DISCOUNT' | translate }}:

                <span class="text-danger">
                  {{
                    row.get('quantity').value
                      | quantitiesunitpriceTax : row.get('unitPrice').value : row.get('discountPercentage').value
                      | customCurrency
                  }}
                </span>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="taxValue">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'TAX' | translate }}
              <span class="text-danger">*</span>
            </th>
            <td
              style="max-width: 10vw; min-width: 10vw;"
              class="p-2"
              mat-cell
              [formGroupName]="i"
              *matCellDef="let row; let i = index"
            >
              <mat-select
                formControlName="taxValue"
                [placeholder]="'PLACEHOLDERS.SELECT' | translate : { value: 'TAX' | translate }"
                class="form-control"
                (selectionChange)="getAllTotal()"
                [multiple]="true"
              >
                <mat-option *ngFor="let tax of taxesMap[i]" [value]="tax.id"
                  >{{ tax.name }} ({{ tax.percentage }}%)
                </mat-option>
              </mat-select>

              <div class="text-danger p-1" *ngIf="row.get('taxValue').touched && row.get('taxValue').errors">
                {{ 'REQUIRED_FIELD' | translate }}
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="total">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'TOTAL' | translate }}
              <span class="text-danger">*</span>
            </th>
            <td
              style="max-width: 12vw; min-width: 12vw;"
              class="text-right"
              mat-cell
              [formGroupName]="i"
              *matCellDef="let row; let i = index"
            >
              {{
                row.value.quantity
                  | quantitiesunitprice
                    : row.value.unitPrice
                    : row.value.discountPercentage
                    : row.value.taxValue
                    : taxesMap[i]
                  | customCurrency
              }}
              <div class="text-secondary" *ngIf="row.value.taxValue">
                {{ 'TAX' | translate }}:

                <span class="text-danger">
                  {{
                    row.value.quantity
                      | quantitiesunitpriceTax
                        : row.value.unitPrice
                        : row.value.discountPercentage
                        : row.value.taxValue
                        : taxesMap[i]
                      | customCurrency
                  }}
                </span>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-9 text-right font-weight-bold">{{ 'SUB_TOTAL_BEFORE_DISCOUNT' | translate }}:</div>
      <div class="col-3 text-left font-weight-bold">{{ totalBeforeDiscount | customCurrency }}</div>
    </div>

    <div class="row">
      <div class="col-9 text-right font-weight-bold">{{ 'TOTAL_DISCOUNT' | translate }}:</div>
      <div class="col-3 text-left text-danger font-weight-bold">{{ totalDiscount | customCurrency }}</div>
    </div>

    <div class="row">
      <div class="col-9 text-right font-weight-bold">{{ 'TOTAL_TAX' | translate }}:</div>
      <div class="col-3 text-left font-weight-bold">{{ totalTax | customCurrency }}</div>
    </div>

    <div class="row">
      <div class="col">
        <mat-divider></mat-divider>
      </div>
    </div>

    <div class="row">
      <div class="col-9 text-right font-weight-bold">{{ 'GRAND_TOTAL' | translate }} :</div>
      <div class="col-3 text-left font-weight-bold">{{ grandTotal | customCurrency }}</div>
    </div>

    <div class="row">
      <div class="col">
        <button type="sumbit" class="col-1 btn btn-success btn-sm m-right-10" [disabled]="salesInvoiceForm.invalid">
          <i class="fas fa-save"></i> {{ 'SAVE' | translate }}
        </button>
        <a [routerLink]="[data.list]" class="col-1 btn btn-danger btn-sm">
          <i class="fas fa-times-circle"></i>
          {{ 'CANCEL' | translate }}
        </a>
      </div>
    </div>
  </form> `,
})
export class ManageInvoicePage extends BaseComponent {
  isEdit: boolean = false;
  isReturn: boolean = false;
  invoiceType: number = 0;

  title: string = '';

  displayedColumns = [
    'action',
    'productId',
    'warehouseId',
    'unitId',
    'unitPrice',
    'quantity',
    'subtotalBeforeDiscount',
    'discountPercentage',
    'subtotalAfterDiscount',
    'taxValue',
    'total',
  ];

  data: any;

  salesInvoiceForm: UntypedFormGroup;

  products: Product[] = [];
  customers: Customer[] = [];
  warehouses: Warehouse[] = [];
  taxes: Tax[] = [];
  customerResource: CustomerResourceParameter = new CustomerResourceParameter();
  productResource: ProductResourceParameter = new ProductResourceParameter();

  filterProductsMap: { [key: string]: Product[] } = {};
  unitsMap: { [key: string]: UnitConversation[] } = {};
  warehouseMap: { [key: string]: Warehouse[] } = {};
  unitConversations: UnitConversation[] = [];
  taxesMap: { [key: string]: Tax[] } = {};
  totalBeforeDiscount: number = 0;
  totalAfterDiscount: number = 0;
  totalDiscount: number = 0;
  grandTotal: number = 0;
  totalTax: number = 0;

  salesInvoice: any;

  dataSource = new MatTableDataSource();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private customerService: CustomerService,
    private supplierService: SupplierService,
    private toastrService: ToastrService,
    private salesInvoiceService: SalesInvoiceService,
    private router: Router,
    private productService: ProductService,
    public activatedRoute: ActivatedRoute,
    private quantitiesUnitPricePipe: QuantitiesUnitPricePipe,
    private quantitiesUnitPriceTaxPipe: QuantitiesUnitPriceTaxPipe,
    public translationService: TranslationService,
    private purchaseInvoiceService: PurchaseInvoiceService,
  ) {
    super(translationService);

    activatedRoute.data.subscribe((data) => {
      this.isReturn = data.isReturn;
      this.invoiceType = data.invoiceType;
      this.title = data.title;

      this.data = {
        title: data.title,
        claims: data.claimType,
        list: data.list,
        detail: data.detail,
        isSales: data.isSales,
      };

      this.unitConversations = data.unitConversations;
      this.warehouses = data.warehouses;
      this.customers = data?.customers?.body ?? data?.suppliers?.body;
      this.taxes = data.taxes;
      this.products = data.products;

      this.salesInvoice = data.salesInvoice ?? data.purchaseInvoice;

      console.log(this.salesInvoice);

      if (this.salesInvoice) {
        this.isEdit = true;

        if (activatedRoute?.snapshot?.queryParams?.id) {
          this.isEdit = false;
        }

        this.salesInvoiceForm = this.formBuilder.group({
          invoiceNumber: [
            {
              value: activatedRoute?.snapshot?.queryParams?.id
                ? data.invoiceNumber.invoiceNumber
                : this.salesInvoice.invoiceNumber,
              disabled: true,
            },
            [Validators.required],
          ],
          termAndCondition: [this.salesInvoice.termAndCondition],
          note: [this.salesInvoice.note],
          siCreatedDate: [this.salesInvoice.siCreatedDate ?? this.salesInvoice.piCreatedDate, [Validators.required]],
          invoiceType: [this.salesInvoice.invoiceType],
          customerId: [this.salesInvoice.customerId ?? this.salesInvoice.supplierId, [Validators.required]],
          filterBarCodeValue: [''],
          salesInvoiceItems: this.formBuilder.array([]),
        });

        this.salesInvoice[this.data.isSales ? 'salesInvoiceItems' : 'purchaseInvoiceItems'].forEach((c) => {
          this.salesInvoiceItems.push(this.createSalesInvoiceItemPatch(this.salesInvoiceItems.length, c));
        });

        this.getAllTotal();
      } else {
        this.salesInvoiceForm = this.formBuilder.group({
          invoiceNumber: [{ value: data.invoiceNumber.invoiceNumber, disabled: true }, [Validators.required]],
          termAndCondition: [''],
          note: [''],
          siCreatedDate: [new Date(), [Validators.required]],
          invoiceType: [this.invoiceType],
          customerId: [null, [Validators.required]],
          filterBarCodeValue: [''],
          salesInvoiceItems: this.formBuilder.array([]),
        });
        this.salesInvoiceItems.push(this.createSalesInvoiceItem(this.salesInvoiceItems.length));
      }

      this.dataSource.data = this.salesInvoiceItems.controls;
    });

    this.sub$.sink = this.salesInvoiceForm
      .get('filterBarCodeValue')
      .valueChanges.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((c) => {
          if (c) {
            this.toastrService.clear();
            this.toastrService.info(
              this.translationService.instant('TOASTR.SEARCHING', {
                value: this.translationService.instant('PRODUCT'),
              }),
            );
            this.productResource.barcode = c;
            return this.productService.getProducts(this.productResource);
          } else {
            return of([]);
          }
        }),
      )
      .subscribe((resp: HttpResponse<Product[]>) => {
        if (resp && resp.headers) {
          if (resp.body.length == 1) {
            if (this.salesInvoiceItems.length == 1) {
              if (!this.salesInvoiceItems.controls[0].get('productId').value) {
                this.removeSalesInvoiceItem(0);
              }
            }
            const productId = (resp.body[0] as Product).id;
            let salesInvoiceItems: any[] = this.salesInvoiceItems.value;
            var existingProductIndex = salesInvoiceItems.findIndex((c) => c.productId == productId);
            if (existingProductIndex >= 0) {
              let iteamToUpdate = salesInvoiceItems[existingProductIndex];
              this.salesInvoiceItems
                .at(existingProductIndex)
                .get('quantity')
                .patchValue(iteamToUpdate.quantity + 1);
            } else {
              this.addAnotherProduct();
              const currentIndex = this.salesInvoiceItems.length - 1;
              this.filterProductsMap[currentIndex.toString()] = [...resp.body];
              this.productNameChange(resp.body[0].id, currentIndex, false);
            }
            this.toastrService.clear();
            this.toastrService.success(
              this.translationService.instant('TOASTR.ADDED', {
                value: this.translationService.instant('PRODUCT'),
              }),
            );

            this.getAllTotal();
          } else {
            this.toastrService.clear();
            this.toastrService.warning(
              this.translationService.instant('TOASTR.NOT_FOUND', {
                value: this.translationService.instant('PRODUCT'),
              }),
            );
          }
          this.productResource.barcode = '';
          this.salesInvoiceForm.get('filterBarCodeValue').patchValue('');
        }
      });
  }

  get salesInvoiceItems() {
    return this.salesInvoiceForm.get('salesInvoiceItems') as FormArray;
  }

  addAnotherProduct() {
    this.salesInvoiceItems.push(this.createSalesInvoiceItem(this.salesInvoiceItems.length));
    this.dataSource.data = this.salesInvoiceItems.controls;
  }

  createSalesInvoiceItemPatch(index: number, salesInvoiceItem: any) {
    const taxes = (salesInvoiceItem?.salesInvoiceItemTaxes ?? salesInvoiceItem?.purchaseInvoiceItemTaxes).map(
      (c) => c.taxId,
    );
    const formGroup = this.formBuilder.group({
      productId: [salesInvoiceItem.productId, [Validators.required]],
      filterProductValue: [null],
      productName: [salesInvoiceItem.productName],
      unitPrice: [salesInvoiceItem.unitPrice, [Validators.required]],
      quantity: [salesInvoiceItem.quantity, [Validators.required]],
      taxValue: [taxes, [Validators.required]],
      unitId: [salesInvoiceItem.unitId, [Validators.required]],
      unitName: [salesInvoiceItem.unitName],
      warehouseId: [salesInvoiceItem.warehouseId],
      discountPercentage: [salesInvoiceItem.discountPercentage],
    });
    this.unitsMap[index] = this.unitConversations.filter(
      (c) => c.id == salesInvoiceItem.product.unitId || c.parentId == salesInvoiceItem.product.unitId,
    );
    this.taxesMap[index] = this.taxes;
    this.warehouseMap[index] = this.warehouses;
    this.filterProductsMap[index.toString()] = [salesInvoiceItem.product];

    this.searchFilterProductValue(formGroup, index);

    return formGroup;
  }

  searchFilterProductValue(formGroup: UntypedFormGroup, index: number) {
    formGroup
      .get('filterProductValue')
      .valueChanges.pipe(
        tap(() => (this.productResource.loading = true)),
        debounceTime(750),
        distinctUntilChanged(),
      )
      .subscribe((term) => {
        if (term != '') {
          this.productResource.name = term;
          this.productResource.id = null;
          this.productService.getProducts(this.productResource).subscribe((resp) => {
            if (resp && resp.headers) {
              this.filterProductsMap[index] = [...resp.body];
            }
          });
        } else {
          this.productService.getProducts(this.productResource).subscribe((resp) => {
            if (resp && resp.headers) {
              this.filterProductsMap[index] = [...resp.body];
            }
          });
        }
        this.productResource.loading = false;
        this.productResource.name = null;
      });
  }

  createSalesInvoiceItem(index: number) {
    const formGroup = this.formBuilder.group({
      productId: [null, [Validators.required]],
      filterProductValue: [null],
      productName: [''],
      unitPrice: [0, [Validators.required, Validators.min(0)]],
      quantity: [1, [Validators.required, Validators.min(1)]],
      taxValue: [null, [Validators.required]],
      unitId: [null, [Validators.required]],
      unitName: [''],
      warehouseId: [null],
      discountPercentage: [0, [Validators.min(0)]],
    });
    this.taxesMap[index] = this.taxes;
    this.warehouseMap[index] = this.warehouses;
    this.filterProductsMap[index.toString()] = this.products;

    this.getProductByNameValue(formGroup, index);
    this.searchFilterProductValue(formGroup, index);

    return formGroup;
  }

  getProductByNameValue(formGroup: UntypedFormGroup, index: number) {
    if (this.salesInvoice) {
      this.getProducts(index);
    }
    this.sub$.sink = formGroup
      .get('filterProductValue')
      .valueChanges.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((c) => {
          this.productResource.name = c;
          return this.productService.getProducts(this.productResource);
        }),
      )
      .subscribe((resp: HttpResponse<Product[]>) => {
        if (resp && resp.headers) {
          this.filterProductsMap[index.toString()] = [...resp.body];
        }
      });
  }

  getAllTotal() {
    let salesInvoiceItems = this.salesInvoiceForm.get('salesInvoiceItems').value;
    this.totalBeforeDiscount = 0;
    this.grandTotal = 0;
    this.totalDiscount = 0;
    this.totalTax = 0;
    if (salesInvoiceItems && salesInvoiceItems.length > 0) {
      salesInvoiceItems.forEach((so) => {
        if (so.unitPrice && so.quantity) {
          const totalBeforeDiscount =
            this.totalBeforeDiscount + parseFloat(this.quantitiesUnitPricePipe.transform(so.quantity, so.unitPrice));
          this.totalBeforeDiscount = parseFloat(totalBeforeDiscount.toFixed(2));
          const gradTotal =
            this.grandTotal +
            parseFloat(
              this.quantitiesUnitPricePipe.transform(
                so.quantity,
                so.unitPrice,
                so.discountPercentage,
                so.taxValue,
                this.taxesMap[0],
              ),
            );
          this.grandTotal = parseFloat(gradTotal.toFixed(2));
          const totalTax =
            this.totalTax +
            parseFloat(
              this.quantitiesUnitPriceTaxPipe.transform(
                so.quantity,
                so.unitPrice,
                so.discountPercentage,
                so.taxValue,
                this.taxesMap[0],
              ),
            );
          this.totalTax = parseFloat(totalTax.toFixed(2));
          const totalDiscount =
            this.totalDiscount +
            parseFloat(this.quantitiesUnitPriceTaxPipe.transform(so.quantity, so.unitPrice, so.discountPercentage));
          this.totalDiscount = parseFloat(totalDiscount.toFixed(2));
        }
      });
    }
  }

  removeSalesInvoiceItem(index: number) {
    this.salesInvoiceItems.removeAt(index);
    this.salesInvoiceItems.controls.forEach((c: UntypedFormGroup, index: number) => {
      const productId = c.get('productId').value;
      if (productId) {
        (this.salesInvoice?.salesInvoiceItems ?? this.salesInvoice?.purchaseInvoiceItems).map((pi) => {
          if (pi.product.id === productId) {
            if (this.products.find((c) => c.id === productId)) {
              this.getProducts(index);
            } else {
              this.getProducts(index, productId);
            }
          }
        });
      } else {
        this.getProducts(index);
      }
    });

    this.dataSource.data = this.salesInvoiceItems.controls;

    this.getAllTotal();
  }

  getProducts(index: number, productId?: string) {
    if (this.products.length === 0 || productId) {
      this.productResource.name = '';
      this.productResource.id = productId ? productId : '';
      this.productService.getProducts(this.productResource).subscribe((resp: HttpResponse<Product[]>) => {
        this.products = [...resp.body];
        this.filterProductsMap[index] = [...resp.body];
      });
    } else {
      this.filterProductsMap[index] = [...this.products];
    }
  }

  unitChange(unitId: string, index: number) {
    const unit = this.unitsMap[index].find((c) => c.id === unitId);

    const productId = this.salesInvoiceItems.controls[index].get('productId').value;
    const product = this.filterProductsMap[index].find((c) => c.id === productId);
    let price = 0;

    if (unit.value) {
      switch (unit.operator) {
        case Operators.Plus:
          price = this.data.isSales ? product.salesPrice : product.purchasePrice + parseFloat(unit.value);
          break;
        case Operators.Minus:
          price = this.data.isSales ? product.salesPrice : product.purchasePrice - parseFloat(unit.value);
          break;
        case Operators.Multiply:
          price = this.data.isSales ? product.salesPrice : product.purchasePrice * parseFloat(unit.value);
          break;
        case Operators.Divide:
          price = this.data.isSales ? product.salesPrice : product.purchasePrice / parseFloat(unit.value);
          break;
      }
      this.salesInvoiceItems.controls[index].patchValue({
        unitPrice: price,
      });
    } else {
      this.salesInvoiceItems.controls[index].patchValue({
        unitPrice: this.data.isSales ? product.salesPrice : product.purchasePrice,
        warehouseId: product.warehouseId,
      });
    }
  }

  productNameChange(productId: string, index: number, isFromUI = true) {
    const product = this.filterProductsMap[index].find((c) => c.id === productId);

    this.unitsMap[index] = this.unitConversations.filter((c) => c.id == product.unitId || c.parentId == product.unitId);

    if (isFromUI) {
      this.salesInvoiceItems.controls[index].patchValue({
        unitPrice: 0,
        filterProductValue: null,
      });
    } else {
      this.salesInvoiceItems.controls[index].patchValue({
        productId: product.id,
        productName: product.name,
      });
    }

    this.salesInvoiceItems.controls[index].patchValue({
      unitPrice: this.data.isSales ? product.salesPrice : product.purchasePrice,
      unitId: product.unitId,
      unitName: product.unitName,
      warehouseId: product.warehouseId,
    });

    if (product.productTaxes.length) {
      this.salesInvoiceItems.controls[index].patchValue({
        taxValue: product.productTaxes.map((c) => c.taxId),
      });
    }

    this.getAllTotal();
  }

  customerNameSearch(term: string) {
    of(term)
      .pipe(
        tap(() => (this.customerResource.loading = true)),
        debounceTime(500),
        delay(1000),
        distinctUntilChanged(),
        finalize(() => (this.customerResource.loading = false)),
      )
      .subscribe((term) => {

        if (this.data.isSales){
          var customerResource : CustomerResourceParameter ;
          customerResource = new CustomerResourceParameter();
          customerResource.customerName = term; 
          this.customerService.getCustomers(customerResource).subscribe((resp) =>
            {
              if (resp && resp.headers) {
                this.customers = [...resp.body] as any; 
              }
            } 
            );


        }     
        else if (!this.data.isSales){
          var supplierResource : SupplierResourceParameter ;
          supplierResource = new SupplierResourceParameter();
          supplierResource.supplierName = term; 
          this.supplierService.getSuppliers(supplierResource).subscribe((resp) =>
            {
              if (resp && resp.headers) {
                this.customers = [...resp.body] as any; 
              }
            } 
            );
        }
 
      });
  }

  submit(e: Event) {
    e.preventDefault();

    if (this.salesInvoiceForm.invalid) {
      this.salesInvoiceForm.markAllAsTouched();

      if (!this.salesInvoiceForm.controls.salesInvoiceItems.invalid) {
        return;
      }
    }

    const salesInvoice = this.buildSalesInvoice();

    if (salesInvoice.id) {
      (this.data.isSales
        ? this.salesInvoiceService.updateSalesInvoice(salesInvoice, this.isReturn)
        : this.purchaseInvoiceService.updatePurchaseInvoice(salesInvoice, this.isReturn)
      ).subscribe(() => {
        this.toastrService.success(
          this.translationService.instant('TOASTR.UPDATED_SUCCESSFULLY', {
            value: this.translationService.instant(this.data.title),
          }),
        );

        this.router.navigate([this.data.list]);
      });
    } else {
      (this.data.isSales
        ? this.salesInvoiceService.addSalesInvoice(salesInvoice)
        : this.purchaseInvoiceService.addPurchaseInvoice(salesInvoice)
      ).subscribe(() => {
        this.toastrService.success(
          this.translationService.instant('TOASTR.ADDED_SUCCESSFULLY', {
            value: this.translationService.instant(this.data.title),
          }),
        );

        this.router.navigate([this.data.list]);
      });
    }
  }

  buildSalesInvoice() {
    let entity;

    if (this.data.isSales) {
      entity = {
        id: this.salesInvoice ? this.salesInvoice.id : '',
        invoiceNumber: this.salesInvoiceForm.get('invoiceNumber').value,
        note: this.salesInvoiceForm.get('note').value,
        termAndCondition: this.salesInvoiceForm.get('termAndCondition').value,
        siCreatedDate: this.salesInvoiceForm.get('siCreatedDate').value,
        invoiceType: this.invoiceType,
        customerId: this.salesInvoiceForm.get('customerId').value,
        totalAmount: this.grandTotal,
        totalTax: this.totalTax,
        totalDiscount: this.totalDiscount,
        salesInvoiceItems: [],
      };
    } else {
      entity = {
        id: this.salesInvoice ? this.salesInvoice.id : '',
        invoiceNumber: this.salesInvoiceForm.get('invoiceNumber').value,
        note: this.salesInvoiceForm.get('note').value,
        termAndCondition: this.salesInvoiceForm.get('termAndCondition').value,
        piCreatedDate: this.salesInvoiceForm.get('siCreatedDate').value,
        invoiceType: this.invoiceType,
        supplierId: this.salesInvoiceForm.get('customerId').value,
        totalAmount: this.grandTotal,
        totalTax: this.totalTax,
        totalDiscount: this.totalDiscount,
        purchaseInvoiceItems: [],
      };
    }

    const salesInvoiceItems = this.salesInvoiceForm.get('salesInvoiceItems').value;

    if (salesInvoiceItems && salesInvoiceItems.length > 0) {
      salesInvoiceItems.forEach((si) => {
        entity[this.data.isSales ? 'salesInvoiceItems' : 'purchaseInvoiceItems'].push({
          discount: parseFloat(
            this.quantitiesUnitPriceTaxPipe.transform(si.quantity, si.unitPrice, si.discountPercentage),
          ),
          discountPercentage: si.discountPercentage,
          productId: si.productId,
          productName: si.productName,
          unitId: si.unitId,
          unitName: si.unitName,
          warehouseId: si.warehouseId,
          quantity: si.quantity,
          taxValue: parseFloat(
            this.quantitiesUnitPriceTaxPipe.transform(
              si.quantity,
              si.unitPrice,
              si.discountPercentage,
              si.taxValue,
              this.taxesMap[0],
            ),
          ),
          unitPrice: parseFloat(si.unitPrice),
          [this.data.isSales ? 'salesInvoiceItemTaxes' : 'purchaseInvoiceItemTaxes']: si.taxValue
            ? [
                ...si.taxValue.map((element) => {
                  return {
                    taxId: element,
                  };
                }),
              ]
            : [],
        });
      });
    }
    return entity;
  }
}
