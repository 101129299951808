import { Component, ViewChild } from '@angular/core';
import { TranslationService } from '../../services/translation.service';
import { BaseComponent } from '../../../base.component';
import { ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { TransactionService } from '../../services/transaction.service';
import { ToastrService } from 'ngx-toastr';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SupplierService } from '../../../pages/suppliers/supplier.service';
import { CustomerService } from '../../../pages/customers/customer.service';

@Component({
  template: ` <style>
      .grid-height {
        height: calc(100vh - 225px) !important;
      }
    </style>

    <section>
      <div class="page-header-main">
        <div class="row align-items-center justify-content-between">
          <div class="col-md-auto col-sm-auto col">
            <div class="content-header">
              <h1>
                {{ data.title | translate }} (<a
                  class="text-info"
                  [routerLink]="[customer.customerName ? routes.customersDetail : routes.suppliersDetail, customer.id]"
                  >{{ customer.customerName ?? customer.supplierName }}</a
                >)
              </h1>
            </div>
          </div>
          <div class="col-md-auto col-sm-auto col-auto">
            <div class="detail-item">
              {{ 'TOTAL_BALANCE' | translate }}:
              <span class="detail-header" [ngClass]="totalBalance.color">{{
                totalBalance.total | customCurrency
              }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="table-responsive">
        <table
          id="extra-table"
          mat-table
          multiTemplateDataRows
          matSort
          [dataSource]="dataSource"
          class="table table-bordered table-hover grid-height"
        >
          <ng-container matColumnDef="documentDate">
            <th class="w-10" mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'DATE' | translate }}
            </th>
            <td class="w-10" mat-cell *matCellDef="let extra">
              <div class="badge badge-date">
                {{ extra.documentDate | utcToLocalTime : 'shortDate' }}
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="documentNo">
            <th class="w-15" mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'DOCUMENT_NO' | translate }}
            </th>
            <td class="w-15" mat-cell *matCellDef="let extra">
              {{ extra.documentNo }}
            </td>
          </ng-container>

          <ng-container matColumnDef="paymentMethod">
            <th class="w-15" mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'PAYMENT_METHOD' | translate }}
            </th>
            <td class="w-15 text-center fs-20" mat-cell *matCellDef="let extra">
              <div class="badge badge-warning w-100 p-1">
                {{ extra.paymentMethod }}
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="debt">
            <th class="w-20" mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'DEBT' | translate }}
            </th>
            <td class="w-20" mat-cell *matCellDef="let extra">
              <div class="badge badge-success">
                {{ extra.debt | customCurrency }}
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="credit">
            <th class="w-20" mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'CREDIT' | translate }}
            </th>
            <td class="w-20" mat-cell *matCellDef="let extra">
              <div class="badge badge-danger">
                {{ extra.credit | customCurrency }}
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="balance">
            <th class="w-20" mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'BALANCE' | translate }}
            </th>
            <td class="w-20" mat-cell *matCellDef="let extra">
              <div class="badge" [ngClass]="transactionService.calculateBalance(extra.debt, extra.credit, true).color">
                {{ transactionService.calculateBalance(extra.debt, extra.credit).total | customCurrency }}
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="footer">
            <td mat-footer-cell colspan="12" *matFooterCellDef>
              <mat-paginator> </mat-paginator>
            </td>
          </ng-container>

          <tr *matNoDataRow>
            <td colspan="12">
              <span class="p-4 mt-4">
                <b> {{ 'NO_DATA_FOUND' | translate }}</b>
              </span>
            </td>
          </tr>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          <tr mat-footer-row *matFooterRowDef="['footer']; sticky: true"></tr>
        </table>
      </div>

      <div class="row">
        <div class="col">
          <button (click)="sendCustomerExtre(customer.id)" class="col-2 btn btn-info btn-sm m-right-10">
            <i class="fas fa-share"></i> {{ data.send | translate }}
          </button>
          <a class="col-1 btn btn-danger btn-sm" [routerLink]="routes.customersList">
            <i class="fas fa-times-circle"></i> {{ 'BUTTONS.BACK' | translate }}
          </a>
        </div>
      </div>
    </section>`,
})
export class ExtraPage extends BaseComponent {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  dataSource: MatTableDataSource<any>;
  customer: any;

  displayedColumns = ['documentDate', 'documentNo', 'paymentMethod', 'debt', 'credit', 'balance'];

  get totalBalance() {
    return this.transactionService.calculateTotalBalance(this.dataSource.data.map((x) => [x.debt, x.credit]));
  }

  data: any;

  constructor(
    translate: TranslationService,
    route: ActivatedRoute,
    public transactionService: TransactionService,
    public customerService: CustomerService,
    public supplierService: SupplierService,
    private toastr: ToastrService,
  ) {
    super(translate);

    route.data.subscribe((data) => {
      this.data = {
        title: data.title,
        isCustomer: data.isCustomer,
        send: data.send,
      };

      this.dataSource = new MatTableDataSource(data.extra);
      this.customer = data.customer ?? data.supplier;
    });
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  sendCustomerExtre(id: string) {
    this.data.isCustomer
      ? this.customerService.sendCustomerExtra(id).subscribe(() => {
          this.toastr.success(
            this.translationService.instant('TOASTR.SENDED_SUCCESSFULLY', {
              value: this.translationService.instant(this.data.title),
            }),
          );
        })
      : this.supplierService.sendSupplierExtra(id).subscribe(() => {
          this.toastr.success(
            this.translationService.instant('TOASTR.SENDED_SUCCESSFULLY', {
              value: this.translationService.instant(this.data.title),
            }),
          );
        });
  }
}
