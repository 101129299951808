import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { enable, disable } from 'darkreader';

type Mode = 'dark' | 'light';

@Injectable({
  providedIn: 'root',
})
export class AppSettings {
  constructor() {
    this.mode.subscribe((mode) => {
      this.mode.value == 'dark'
        ? enable({
            brightness: 100,
            contrast: 90,
            darkSchemeBackgroundColor: '#1e1e1e',
            darkSchemeTextColor: '#ffffff',
            sepia: 10,
            scrollbarColor: '#ffffff20',
            selectionColor: '#ffffff20',
            grayscale: 0,
            fontFamily: 'inherit',
            lightSchemeBackgroundColor: '#ffffff',
            lightSchemeTextColor: '#000000',
            styleSystemControls: true,
            mode: 1,
            useFont: false,
          })
        : disable();
      localStorage.setItem('mode', mode);
    });
  }

  appLoaded = new BehaviorSubject<boolean>(false);
  navigationCount = new BehaviorSubject<number>(0);
  footer = new BehaviorSubject<boolean>(true);
  request = new BehaviorSubject<boolean>(true);
  mode = new BehaviorSubject<Mode>(localStorage.getItem('mode') ? (localStorage.getItem('mode') as Mode) : 'light');
}
