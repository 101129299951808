import { Component } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { TranslationService } from '../services/translation.service';
import { MatDialog } from '@angular/material/dialog';
import { RequestComponent } from './request.component';
import { AppSettings } from '../../app-settings';

@Component({
  selector: 'layout',
  template: `
    <style>
      .tooltip-info {
        font-size: 2rem;
        margin-left: 0.5rem;
        font-weight: bold;
      }

      .request-container {
        display: flex;
        background-color: #ddd;
        position: fixed;
        padding: 1.25em;
        bottom: 2.5%;
        z-index: 200;
        opacity: 0.65;
        border-radius: 12.5px;
      }
      .content-wrapper > .content {
        padding: 0 0.5rem;
      }

      .ng-select {
        height: calc(1.5em + 0.75rem + 2px) !important;
      }
    </style>
    <div class="content-main smooth-animation" #lead>
      <header [lead]="lead"></header>
      <div class="sidebar">
        <sidebar></sidebar>
      </div>
      <div class="content-area">
        <div
          *ngIf="appSettings.request.value"
          class="request-container"
          [style]="langDir == 'rtl' ? 'left: 0.50%;' : 'right: 0.50%;'"
        >
          <div (click)="viewRequestForm($event)">
            <a href="#"> {{ 'CONTACT_US' | translate }} </a>
            <i
              mat-raised-button
              matTooltipPosition="above"
              matTooltipClass="fs-18"
              matTooltip="Bize Herhangi Bir İstek, Öneri veya Şikayetlerinizi İletebilirsiniz."
              class="fas fa-xl fa-info-circle"
            ></i>
          </div>
        </div>
        <router-outlet></router-outlet>
        <footer *ngIf="appSettings.footer.value"></footer>
      </div>
    </div>
  `,
})
export class LayoutComponent extends BaseComponent {
  constructor(translate: TranslationService, public dialog: MatDialog, public appSettings: AppSettings) {
    super(translate);
  }

  viewRequestForm(e) {
    e.preventDefault();

    this.dialog.open(RequestComponent, {
      width: '75vh',
    });
  }
}
