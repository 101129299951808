import { AbstractControl, FormGroup, ValidatorFn } from '@angular/forms';

export class AppValidators {
  static exist(flag: boolean) {
    return (): { [key: string]: boolean } | null => {
      if (flag) {
        return { exist: true };
      }
      return null;
    };
  }

  static markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach((control) => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  static validateUrl(control: AbstractControl): { [key: string]: boolean } | null {
    const regexp = new RegExp(
      '^(http://www.|https://www.|http://|https://)?[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$',
    );
    const ctrlValue = control.value;
    if (!regexp.test(ctrlValue) && control.dirty && ctrlValue !== '') {
      return { valid_url: true };
    }
    return null;
  }

  static onlyNumericValidator(name: string): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      const controlValue = c.value;
      if (!controlValue) {
        return null;
      }
      const numbers = /^[0-9]+$/;
      if (!controlValue.match(numbers)) {
        return { only_numeric: true };
      }
      return null;
    };
  }

  static range(min: number, max: number): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      if (c.value && (isNaN(c.value) || c.value < min || c.value > max)) {
        return { range: true };
      }
      return null;
    };
  }

  static dateCompare(): ValidatorFn {
    return (controls: AbstractControl) => {
      const fromDate = controls.get('fromDate').value;
      const toDate = controls.get('toDate').value;
      if (fromDate && toDate) {
        if (Date.parse(fromDate) > Date.parse(toDate)) {
          return { dateRange: true };
        }
        return null;
      } else {
        return null;
      }
    };
  }
}
