import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class IncomingEInvoiceService {
  constructor(private http: HttpClient) {}

  static getIncomingInvoiceResolver() {
    return inject(IncomingEInvoiceService).getIncomingInvoices();
  }

  getIncomingInvoices({
    begDate = String(new Date(new Date().setDate(new Date().getDate() - 7)).toISOString()).split('T')[0],
    endDate = String(new Date().toISOString()).split('T')[0],
  } = {}) {
    return this.http.get(`purchaseinvoice/getincomingeinvoices?begDate=${begDate}&endDate=${endDate}`);
  }

  sendEInvoice({ incomingInvoiceId, eInvoiceAnswer }) {
    return this.http.post(`purchaseinvoice/answerincomingeinvoice`, {
      incomingInvoiceId,
      eInvoiceAnswer,
    });
  }
}
