import { HttpClient, HttpContext, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Product } from '@core/domain-classes/product/product';
import { ProductResourceParameter } from '@core/domain-classes/product/product-resource-parameter';
import { Observable, forkJoin, of } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';
import { AppRoutes } from '../../shared/constants';
import { PASS_SPINNER } from '../../shared/interceptors/http-interceptor.module';
import { BrandService } from '../../core/services/brand.service';
import { UnitConversationService } from './units/unit.service';
import { ProductCategoryService } from '../../core/services/product-category.service';
import { WarehouseService } from '../../core/services/warehouse.service';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  constructor(private http: HttpClient) {}

  static getProductsResponseResolver() {
    const parameters = new ProductResourceParameter();

    parameters.pageSize = 50;
    parameters.orderBy = 'createdDate asc';

    return inject(ProductService).getProducts(parameters);
  }

  static getProductsResolver() {
    return inject(ProductService)
      .getProducts(new ProductResourceParameter())
      .pipe(map((resp: HttpResponse<Product[]>) => resp.body));
  }

  static detailProductResolver(route: ActivatedRouteSnapshot) {
    const brandService = inject(BrandService);
    const unitService = inject(UnitConversationService);
    const categoryService = inject(ProductCategoryService);
    const warehouseSerivce = inject(WarehouseService);
    const router = inject(Router);

    return inject(ProductService)
      .getProudct(route.paramMap.get('id'))
      .pipe(
        take(1),
        mergeMap((product: Product) => {
          if (product) {
            return forkJoin([
              of(product),
              brandService.getByKey(product.brandId),
              unitService.getUnitConversation(product.unitId),
              categoryService.getByKey(product.categoryId),
              warehouseSerivce.getByKey(product.warehouseId),
            ]);
          } else {
            router.navigate([AppRoutes.productsList]);
            return null;
          }
        }),
        mergeMap(([product, brand, unit, category, warehouse]) => {
          product.brandName = brand.name;
          product.unitName = unit[0].name;
          product.categoryName = category.name;
          product.warehouseName = warehouse.name;
          return of(product);
        }),
      );
  }

  static manageProductResolver(route: ActivatedRouteSnapshot) {
    return inject(ProductService)
      .getProudct(route.paramMap.get('id'))
      .pipe(
        take(1),
        mergeMap((expense) => {
          if (expense) {
            return of(expense);
          } else {
            inject(Router).navigate([AppRoutes.productsList]);
            return null;
          }
        }),
      );
  }

  getProducts(resourceParams?: ProductResourceParameter, passSpinner = false): Observable<HttpResponse<Product[]>> {
    const url = 'product';
    const customParams = new HttpParams()
      .set('fields', resourceParams.fields)
      .set('orderBy', resourceParams.orderBy)
      .set('pageSize', resourceParams.pageSize.toString())
      .set('skip', resourceParams.skip.toString())
      .set('searchQuery', resourceParams.searchQuery)
      .set('name', resourceParams.name)
      .set('id', resourceParams.id)
      .set('categoryId', resourceParams.categoryId ? resourceParams.categoryId : '')
      .set('unitId', resourceParams.unitId ? resourceParams.unitId : '')
      .set('barcode', resourceParams.barcode ? resourceParams.barcode : '')
      .set('brandId', resourceParams.brandId ? resourceParams.brandId : resourceParams.brandId);
    return this.http.get<Product[]>(url, {
      params: customParams,
      observe: 'response',
      context: new HttpContext().set(PASS_SPINNER, passSpinner),
    });
  }

  getProudct(id: string): Observable<Product> {
    const url = `product/${id}`;
    return this.http.get<Product>(url);
  }

  addProudct(product: Product): Observable<Product> {
    const url = 'product';
    return this.http.post<Product>(url, product);
  }

  updateProudct(id: string, product: Product): Observable<Product> {
    const url = `product/${id}`;
    return this.http.put<Product>(url, product);
  }

  deleteProudct(id: string): Observable<void> {
    const url = `product/${id}`;
    return this.http.delete<void>(url);
  }
}
