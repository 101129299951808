import { Component } from '@angular/core';
import { CompanyProfile } from '@core/domain-classes/company-profile';
import { SecurityService } from '@core/security/security.service';
import { environment } from '../../../environments/environment';
@Component({
  selector: 'footer',
  template: `<div class="main-footer">
    <span [innerHTML]="'FOOTER' | translate : { year: currentYear, title: companyProfile.title }"></span>
    <div
      class="float-right d-none d-sm-inline-block position-fixed"
      style="
    bottom: 3.5px;
    right: 10px;
"
    >
      <b>{{ 'VERSION' | translate }}</b> {{ env.version }}
    </div>
  </div> `,
})
export class FooterComponent {
  companyProfile: CompanyProfile;
  currentYear: number;

  env = environment;

  constructor(private securityService: SecurityService) {
    this.currentYear = new Date().getFullYear();

    this.securityService.companyProfile.subscribe((profile) => {
      if (profile) {
        this.companyProfile = profile;
      }
    });
  }
}
