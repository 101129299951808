export const environment = {
  title: 'Convert',
  production: false,
  //apiUrl : 'https://localhost:44346/',
  apiUrl: 'https://app.convert.com.tr/',
  //apiUrl: 'https://service.convert.com.tr/',
  //apiUrl: 'https://service.in.com.tr/',
  allowFileExtension: ['pdf', 'doc', 'docx', 'xls', 'xlsx'],
  allowExtesions: ['pdf', 'doc', 'docx'],
  maximumFileSize: 104857600,
  version: '1.0.0',
};
