import { Component, Inject } from '@angular/core';
import { BaseComponent } from '../../../base.component';
import { TranslationService } from '../../services/translation.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogComponent } from '../../dialog.component';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { paymentMethods } from '../../domain-classes/payment-method';
import { customerSupplierTransactions } from '../../domain-classes/customerSupplierTransactionType';
import { ToastrService } from 'ngx-toastr';
import { CustomerService } from '../../../pages/customers/customer.service';
import { SupplierService } from '../../../pages/suppliers/supplier.service';

@Component({
  selector: 'manage-transaction',
  host: { class: 'position-relative' },
  template: `<form class="modal-content1" [formGroup]="transactionForm" (ngSubmit)="submit($event)">
    <div class="modal-header position-relative">
      <h3>
        {{ (data.transactionId ? 'TITLES.EDIT' : 'TITLES.ADD') | translate : { value: data.title | translate } }}
      </h3>
      <div class="cursor-pointer position-absolute" style="top: 2.5px; right: 6px;" (click)="dialogRef.close()">
        <i class="fas fa-times-circle text-danger" style="font-size: 32px;"></i>
      </div>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col form-group">
          <label class="form-label">{{ 'DOCUMENT_NO' | translate }}</label>
          <input
            class="form-control"
            [placeholder]="'PLACEHOLDERS.INPUT' | translate : { value: 'DOCUMENT_NO' | translate }"
            type="text"
            formControlName="documentNo"
          />
        </div>
        <div class="col form-group">
          <label class="form-label">{{ 'DOCUMENT_DATE' | translate }} <span class="text-danger">*</span></label>
          <input
            class="form-control"
            type="text"
            [placeholder]="'PLACEHOLDERS.SELECT' | translate : { value: 'DOCUMENT_DATE' | translate }"
            formControlName="documentDate"
            [owlDateTimeTrigger]="owldocumentDate"
            [owlDateTime]="owldocumentDate"
          />
          <owl-date-time [pickerType]="'calendar'" #owldocumentDate></owl-date-time>
          <div
            class="text-danger"
            *ngIf="
              transactionForm.get('documentDate').touched && transactionForm.get('documentDate')?.hasError('required')
            "
          >
            {{ 'VALIDATIONS.REQUIRED' | translate : { value: 'DOCUMENT_DATE' | translate } }}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col form-group">
          <label class="form-label">{{ 'PAYMENT_METHOD' | translate }} <span class="text-danger">*</span></label>
          <ng-select
            appendTo="manage-transaction"
            formControlName="paymentMethod"
            clearable="false"
            [placeholder]="'PLACEHOLDERS.SELECT' | translate : { value: 'PAYMENT_METHOD' | translate }"
            ><ng-option *ngFor="let paymentMethod of paymentMethods" [value]="paymentMethod.id">
              {{ paymentMethod.name | translate }}
            </ng-option></ng-select
          >
          <div
            class="text-danger"
            *ngIf="
              transactionForm.get('paymentMethod').touched && transactionForm.get('paymentMethod')?.hasError('required')
            "
          >
            {{ 'VALIDATIONS.REQUIRED' | translate : { value: 'PAYMENT_METHOD' | translate } }}
          </div>
        </div>
        <div class="col form-group">
          <label class="form-label">{{ data.type | translate }} <span class="text-danger">*</span></label>
          <ng-select
            appendTo="manage-transaction"
            formControlName="customerTransactionType"
            clearable="false"
            [placeholder]="'PLACEHOLDERS.SELECT' | translate : { value: data.type | translate }"
            ><ng-option *ngFor="let transaction of transactions" [value]="transaction.id">
              {{ transaction.name | translate }}
            </ng-option></ng-select
          >
          <div
            class="text-danger"
            *ngIf="
              transactionForm.get('customerTransactionType').touched &&
              transactionForm.get('customerTransactionType')?.hasError('required')
            "
          >
            {{ 'VALIDATIONS.REQUIRED' | translate : { value: data.type | translate } }}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col form-group">
          <label class="form-label">{{ 'TOTAL_AMOUNT' | translate }} <span class="text-danger">*</span></label>
          <input
            class="form-control"
            type="number"
            min="0"
            [placeholder]="'PLACEHOLDERS.INPUT' | translate : { value: 'TOTAL_AMOUNT' | translate }"
            formControlName="totalAmount"
          />
          <div
            class="text-danger"
            *ngIf="
              transactionForm.get('totalAmount').touched && transactionForm.get('totalAmount')?.hasError('required')
            "
          >
            {{ 'VALIDATIONS.REQUIRED' | translate : { value: 'TOTAL_AMOUNT' | translate } }}
          </div>
          <div
            class="text-danger"
            *ngIf="transactionForm.get('totalAmount').touched && transactionForm.get('totalAmount')?.hasError('min')"
          >
            {{ 'VALIDATIONS.MUST_BE_TOP_ZERO' | translate : { value: 'TOTAL_AMOUNT' | translate } }}
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="row w-100">
        <button type="submit" class="col btn btn-success btn-sm m-right-10 w-100">
          <i class="fas fa-check"></i> {{ 'SAVE' | translate }}
        </button>
        <button type="button" (click)="this.dialogRef.close()" class="col btn btn-danger btn-sm w-100">
          <i class="fas fa-times-circle"></i> {{ 'CANCEL' | translate }}
        </button>
      </div>
    </div>
  </form>`,
})
export class ManageTransactionComponent extends BaseComponent {
  paymentMethods = paymentMethods;
  transactions = customerSupplierTransactions;

  transactionForm = this.formBuilder.group({
    id: [null],
    customerId: [null],
    documentNo: [''],
    paymentMethod: [0, { validators: [Validators.required] }],
    customerTransactionType: [0, { validators: [Validators.required] }],
    documentDate: [new Date(), { validators: [Validators.required] }],
    totalAmount: [null, { validators: [Validators.required, Validators.min(1)] }],
  });

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    translate: TranslationService,
    private formBuilder: UntypedFormBuilder,
    private customerSerivce: CustomerService,
    private supplierService: SupplierService,
    private toastr: ToastrService,
  ) {
    super(translate);
  }

  ngOnInit() {
    this.transactionForm.patchValue({ customerId: this.data.id });

    if (this.data.transactionId) {
      if (this.data.isCustomer) {
        this.customerSerivce.getTransaction(this.data.transactionId).subscribe((res) => {
          this.transactionForm.patchValue({ ...res, customerId: this.data.id });
        });
      } else {
        this.supplierService.getTransaction(this.data.transactionId).subscribe((res) => {
          this.transactionForm.patchValue({ ...res, customerId: this.data.id });
        });
      }
    }
  }

  submit(e: Event) {
    e.preventDefault();

    if (this.transactionForm.invalid) {
      this.transactionForm.markAllAsTouched();
      return;
    }

    const formData = this.transactionForm.getRawValue();

    if (this.data.isCustomer) {
      formData.customerId = this.data.id;
    } else {
      formData.supplierId = this.data.id;
    }

    if (this.data.isCustomer) {
      if (this.data.transactionId) {
        this.customerSerivce.updateTransaction(formData).subscribe(() => {
          this.toastr.success(
            this.translationService.instant('TOASTR.UPDATED_SUCCESSFULLY', {
              value: this.translationService.instant(this.data.title),
            }),
          );
          this.dialogRef.close();
        });
      } else {
        this.customerSerivce.addTransaction(formData).subscribe(() => {
          this.toastr.success(
            this.translationService.instant('TOASTR.ADDED_SUCCESSFULLY', {
              value: this.translationService.instant(this.data.title),
            }),
          );
          this.dialogRef.close();
        });
      }
    } else {
      formData.supplierTransactionType = formData.customerTransactionType;

      if (this.data.transactionId) {
        this.supplierService.updateTransaction(formData).subscribe(() => {
          this.toastr.success(
            this.translationService.instant('TOASTR.UPDATED_SUCCESSFULLY', {
              value: this.translationService.instant(this.data.title),
            }),
          );
          this.dialogRef.close();
        });
      } else {
        this.supplierService.addTransaction(formData).subscribe(() => {
          this.toastr.success(
            this.translationService.instant('TOASTR.ADDED_SUCCESSFULLY', {
              value: this.translationService.instant(this.data.title),
            }),
          );
          this.dialogRef.close();
        });
      }
    }
  }
}
