import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TransactionService {
  constructor() {}

  getBalanceFormat(balance: number, isBagde = false) {
    if (balance == 0) {
      return {
        total: 0,
        color: isBagde ? 'badge-secondary' : 'text-secondary',
      };
    }

    return {
      total: balance < 0 ? balance * -1 : balance,
      color: balance < 0 ? (isBagde ? 'badge-danger' : 'text-danger') : isBagde ? 'badge-success' : 'text-success',
    };
  }

  calculateBalance(debt, credit, isBagde = false) {
    return this.getBalanceFormat(debt - credit, isBagde);
  }

  calculateTotalBalance(items: [debt: number, credit: number][] = []) {
    if (items.length === 0) {
      return {
        total: 0,
        color: 'text-secondary',
      };
    }

    const total = items.map((x) => x[0] - x[1]).reduce((sum, a) => (sum += a));
    if (total < 0) {
      return {
        total: total * -1,
        color: 'text-danger',
      };
    } else {
      return {
        total: total,
        color: 'text-success',
      };
    }
  }
}
