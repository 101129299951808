import { Component, ViewChild } from '@angular/core';
import { BaseComponent } from '../../../base.component';
import { TranslationService } from '../../services/translation.service';
import { ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { TransactionService } from '../../services/transaction.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { CommonDialogService } from '../../common-dialog/common-dialog.service';
import { ToastrService } from 'ngx-toastr';
import { CustomerService } from '../../../pages/customers/customer.service';
import { SupplierService } from '../../../pages/suppliers/supplier.service';
import { customerSupplierTransactions } from '../../domain-classes/customerSupplierTransactionType';
import { ManageTransactionComponent } from './manage-transaction.component';
import { transliterate } from 'transliteration';

@Component({
  selector: 'transactions',
  template: ` <style>
      .grid-height {
        height: calc(100vh - 225px) !important;
      }
    </style>
    <section>
      <div class="page-header-main">
        <div class="row align-items-center justify-content-between">
          <div class="col-md-auto col-sm-auto col">
            <div class="content-header">
              <h1>
                {{ data.title | translate }} (<a
                  class="text-info"
                  [routerLink]="[customer.customerName ? routes.customersDetail : routes.suppliersDetail, customer.id]"
                  >{{ customer.customerName ?? customer.supplierName }}</a
                >)
              </h1>
            </div>
          </div>
          <div class="col-md-auto col-sm-auto col-auto d-flex">
            <div class="mr-3">
              <button *hasClaim="data.claims.add" (click)="manageTransaction(customer.id)" class="btn blue-btn btn-sm">
                <i class="fas fa-plus"></i>
                {{ 'LABELS.ADD' | translate : { value: data.transaction | translate } }}
              </button>
            </div>

            <div class="detail-item">
              {{ 'TOTAL_BALANCE' | translate }}:
              <span class="detail-header" [ngClass]="totalBalance.color">{{
                totalBalance.total | customCurrency
              }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="table-responsive">
        <table
          id="transaction-table"
          mat-table
          multiTemplateDataRows
          matSort
          [dataSource]="dataSource"
          class="table table-bordered table-hover grid-height"
        >
          <ng-container matColumnDef="action">
            <th class="w-2" *matHeaderCellDef mat-header-cell></th>
            <td class="p-2 w-2" mat-cell *matCellDef="let row">
              <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Action" *ngIf="isImmutable(row)">
                <mat-icon>more_vert</mat-icon>
              </button>

              <mat-menu #menu="matMenu">
                <ng-container *ngIf="row.paymentMethod != 'Fatura'">
                  <button *hasClaim="data.claims.update" (click)="manageTransaction(customer.id, row)" mat-menu-item>
                    <mat-icon>edit</mat-icon>
                    <span>{{ 'BUTTONS.EDIT' | translate }}</span>
                  </button>
                </ng-container>
                <ng-container>
                  <button mat-menu-item *hasClaim="data.claims.delete" (click)="deleteTransaction(row)">
                    <mat-icon>delete</mat-icon>
                    <span>{{ 'DELETE' | translate }}</span>
                  </button>
                </ng-container>
              </mat-menu>
            </td>
          </ng-container>

          <ng-container matColumnDef="documentDate">
            <th class="w-8" mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'DATE' | translate }}
            </th>
            <td class="w-8 text-center" mat-cell *matCellDef="let extra">
              <div class="badge badge-date w-100">
                {{ extra.documentDate | utcToLocalTime : 'shortDate' }}
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="documentNo">
            <th class="w-12" mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'DOCUMENT_NO' | translate }}
            </th>
            <td class="w-12" mat-cell *matCellDef="let extra">
              {{ extra.documentNo }}
            </td>
          </ng-container>

          <ng-container matColumnDef="documentType">
            <th class="w-8" mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'DOCUMENT_TYPE' | translate }}
            </th>
            <td class="w-8 text-center fs-18" mat-cell *matCellDef="let extra">
              <div class="badge badge-warning w-100 p-1">
                {{ extra.documentType }}
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="paymentMethod">
            <th class="w-8" mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'PAYMENT_METHOD' | translate }}
            </th>
            <td class="w-8 text-center" mat-cell *matCellDef="let extra">
              <div class="badge badge-info w-100">
                {{ extra.paymentMethod }}
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="debt">
            <th class="w-16" mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'DEBT' | translate }}
            </th>
            <td class="w-16 text-center" mat-cell *matCellDef="let extra">
              <div class="badge badge-success w-100">
                {{ extra.debt | customCurrency }}
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="credit">
            <th class="w-16" mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'CREDIT' | translate }}
            </th>
            <td class="w-16 text-center" mat-cell *matCellDef="let extra">
              <div class="badge badge-danger w-100">
                {{ extra.credit | customCurrency }}
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="balance">
            <th class="w-16" mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'BALANCE' | translate }}
            </th>
            <td class="w-16 text-center" mat-cell *matCellDef="let extra">
              <div
                class="badge w-100"
                [ngClass]="transactionService.calculateBalance(extra.debt, extra.credit, true).color"
              >
                {{ transactionService.calculateBalance(extra.debt, extra.credit).total | customCurrency }}
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="footer">
            <td mat-footer-cell colspan="12" *matFooterCellDef>
              <mat-paginator> </mat-paginator>
            </td>
          </ng-container>

          <tr *matNoDataRow>
            <td colspan="12">
              <span class="p-4 mt-4">
                <b> {{ 'NO_DATA_FOUND' | translate }}</b>
              </span>
            </td>
          </tr>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          <tr mat-footer-row *matFooterRowDef="['footer']; sticky: true"></tr>
        </table>
      </div>

      <div class="row">
        <div class="col">
          <a class="col-1 btn btn-danger btn-sm" [routerLink]="data.list">
            <i class="fas fa-times-circle"></i> {{ 'BUTTONS.BACK' | translate }}
          </a>
        </div>
      </div>
    </section>`,
})
export class TransactionsPage extends BaseComponent {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  customer: any;
  dataSource: MatTableDataSource<any>;

  transactions = customerSupplierTransactions;

  displayedColumns = [
    'action',
    'documentDate',
    'documentNo',
    'documentType',
    'paymentMethod',
    'debt',
    'credit',
    'balance',
  ];

  data: any;

  isImmutable(transaction: any) {
    const editabledPayments = this.paymentMethods.filter((e) => {
      return transliterate(this.translationService.instant(e.name)) == transliterate(transaction.paymentMethod);
    });

    const editabledTypes = this.transactions.filter((e) => {
      return transliterate(this.translationService.instant(e.name)) == transliterate(transaction.documentType);
    });

    return editabledPayments.length > 0 && editabledTypes.length > 0;
  }

  get totalBalance() {
    return this.transactionService.calculateTotalBalance(this.dataSource.data.map((x) => [x.debt, x.credit]));
  }

  constructor(
    translate: TranslationService,
    route: ActivatedRoute,
    public transactionService: TransactionService,
    private dialog: MatDialog,
    private customerService: CustomerService,
    private commonDialogSerivce: CommonDialogService,
    private toastr: ToastrService,
    private supplierService: SupplierService,
  ) {
    super(translate);

    route.data.subscribe((data) => {
      this.data = {
        title: data.title,
        transaction: data.transaction,
        isCustomer: data.isCustomer,
        claims: {
          add: data.claimAdd,
          delete: data.claimDelete,
          update: data.claimUpdate,
        },
        list: data.list,
        type: data.type,
      };

      this.dataSource = new MatTableDataSource(data.transactions.data);
      this.customer = data.customer ?? data.supplier;
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  manageTransaction(id: string, transaction?: any) {
    const dialogRef = this.dialog.open(ManageTransactionComponent, {
      data: {
        title: this.data.transaction,
        id: id,
        transactionId: transaction?.id,
        type: this.data.type,
        isCustomer: this.data.isCustomer,
      },
      width: '50%',
    });

    dialogRef.afterClosed().subscribe(() => {
      if (this.data.isCustomer) {
        this.customerService.getTransactions(id).subscribe((res: any) => {
          this.dataSource.data = res.data;
        });
      } else {
        this.supplierService.getTransactions(id).subscribe((res: any) => {
          this.dataSource.data = res.data;
        });
      }
    });
  }

  deleteTransaction(transaction: any) {
    this.commonDialogSerivce
      .deleteConformationDialog(
        `${this.translationService.instant('ARE_YOU_SURE_YOU_WANT_TO_DELETE')} <div class="text-danger">${
          transaction.documentNo
        }</div>`,
      )
      .subscribe((res) => {
        if (res) {
          if (this.data.isCustomer) {
            this.customerService.deleteTransaction(transaction.id).subscribe(() => {
              this.dataSource.data = this.dataSource.data.filter((x) => x.id !== transaction.id);
              this.toastr.success(
                this.translationService.instant('TOASTR.DELETED_SUCCESSFULLY', {
                  value: this.translationService.instant(this.data.transaction),
                }),
              );
            });
          } else {
            this.supplierService.deleteTransaction(transaction.id).subscribe(() => {
              this.dataSource.data = this.dataSource.data.filter((x) => x.id !== transaction.id);
              this.toastr.success(
                this.translationService.instant('TOASTR.DELETED_SUCCESSFULLY', {
                  value: this.translationService.instant(this.data.transaction),
                }),
              );
            });
          }
        }
      });
  }
}
