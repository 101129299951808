import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { CommonDialogService } from '@core/common-dialog/common-dialog.service';
import { TranslationService } from '@core/services/translation.service';
import { BaseComponent } from '../../../base.component';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'list-payment',
  template: `
    <div class="modal-content1">
      <div class="modal-header position-relative">
        <h3>
          {{ 'PAYMENT_HISTORY' | translate }}
          <ng-container *ngIf="data.data.invoiceNumber ?? data.data.orderNumber">
            (<a
              (click)="dialogRef.close()"
              [routerLink]="[data.detail, data.data.id]"
              (click)="dialogRef.close()"
              class="text-info"
              >{{ data.data.invoiceNumber ?? data.data.orderNumber }}</a
            >)
          </ng-container>
        </h3>
        <div class="cursor-pointer position-absolute" style="top: 2.5px; right: 6px;" (click)="dialogRef.close()">
          <i class="fas fa-times-circle text-danger" style="font-size: 32px;"></i>
        </div>
      </div>

      <div class="modal-body">
        <div class="table-responsive">
          <table
            matSort
            matSortActive="paymentDate"
            mat-table
            [dataSource]="dataSource"
            class="table table-bordered table-hover"
          >
            <ng-container matColumnDef="action">
              <th class="w-4" clk mat-header-cell *matHeaderCellDef></th>
              <td class="w-4" mat-cell *matCellDef="let element">
                <button
                  *hasClaim="data.deleteClaim"
                  (click)="deletePayment(element)"
                  class="btn btn-danger btn-sm"
                  type="button"
                >
                  <i class="fas fa-trash-alt"></i>
                </button>
              </td>
            </ng-container>
            <ng-container matColumnDef="paymentDate">
              <th class="w-24" mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'PAYMENT_DATE' | translate }}</th>
              <td class="w-24" mat-cell *matCellDef="let element">
                <div class="badge badge-date text-center">
                  {{ element.paymentDate | utcToLocalTime }}
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="referenceNumber">
              <th class="w-24" mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'REFERENCE_NUMBER' | translate }}
              </th>
              <td class="w-24" mat-cell *matCellDef="let element">{{ element.referenceNumber }}</td>
            </ng-container>
            <ng-container matColumnDef="amount">
              <th class="w-24" mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'AMOUNT' | translate }}</th>
              <td class="w-24 text-right" mat-cell *matCellDef="let element">
                {{ element.amount | customCurrency }}
              </td>
            </ng-container>
            <ng-container matColumnDef="paymentMethod">
              <th class="w-24" mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'PAYMENT_METHOD' | translate }}</th>
              <td class="w-24 text-center" mat-cell *matCellDef="let element">
                <div class="badge badge-warning">
                  {{ element.paymentMethod | paymentmethod }}
                </div>
              </td>
            </ng-container>

            <tr *matNoDataRow>
              <td colspan="12">
                <span class="p-2 mt-2">
                  <b> {{ 'NO_DATA_FOUND' | translate }}</b>
                </span>
              </td>
            </tr>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
      </div>
    </div>
  `,
})
export class PaymentsComponent extends BaseComponent {
  @ViewChild(MatSort) sort: MatSort;

  dataSource = new MatTableDataSource(this.data.payments);

  displayedColumns: string[] = ['action', 'paymentDate', 'referenceNumber', 'paymentMethod', 'amount'];

  constructor(
    public dialogRef: MatDialogRef<PaymentsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private commonDialogService: CommonDialogService,
    public translationService: TranslationService,
  ) {
    super(translationService);
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  deletePayment(payment: any) {
    this.sub$.sink = this.commonDialogService
      .deleteConformationDialog(
        `${this.translationService.instant('ARE_YOU_SURE_YOU_WANT_TO_DELETE')} <div class="text-danger">${
          payment.amount
        }</div>`,
      )
      .subscribe((isTrue: boolean) => {
        if (isTrue) {
          this.data.handle(payment.id, this.dataSource);
        }
      });
  }
}
