export class AppRoutes {
  static empty = '';

  static login = 'login';

  static initRoute = '/';

  static email = 'email';
  static send = 'send';
  static smtps = 'smtps';
  static templates = 'templates';

  static categories = 'categories';

  static sales = 'sales';
  static purchase = 'purchase';

  static add = 'add';
  static manage = 'manage';
  static manageId = `${this.manage}/:id`;
  static detail = 'detail';
  static detailId = 'detail/:id';
  static detailIdTransactions = 'detail/:id/transactions';
  static detailIdExtra = 'detail/:id/extra';

  static emailSend = `/${this.email}/${this.send}`;

  static emailSmtpsList = `/${this.email}/${this.smtps}`;
  static emailSmtpsManage = `/${this.email}/${this.smtps}/${this.manage}`;

  static emailTemplatesList = `/${this.email}/${this.templates}`;
  static emailTemplatesManage = `/${this.email}/${this.templates}/${this.manage}`;

  static companyProfile = 'company-profile';

  static incomingEInvoice = 'incoming-e-invoice';

  static taxes = 'taxes';
  static taxesList = `/${this.taxes}`;

  static myProfile = 'my-profile';

  static transactions = 'transactions';
  static extra = 'extra';

  static customers = 'customers';
  static customersList = `/${this.customers}`;
  static customersManage = `/${this.customers}/${this.manage}`;
  static customersDetail = `/${this.customers}/${this.detail}`;
  static customersPayment = `${this.customers}/${this.manage}/payment`;

  static suppliers = 'suppliers';
  static suppliersList = `/${this.suppliers}`;
  static suppliersManage = `/${this.suppliers}/${this.manage}`;
  static suppliersDetail = `/${this.suppliers}/${this.detail}`;

  static salesInvoice = 'sales-invoice';
  static salesInvoiceList = `/${this.sales}/${this.salesInvoice}`;
  static salesInvoiceManage = `/${this.sales}/${this.salesInvoice}/${this.manage}`;
  static salesInvoiceDetail = `/${this.sales}/${this.salesInvoice}/${this.detail}`;

  static salesInvoiceReturn = 'sales-invoice-return';
  static salesInvoiceReturnList = `/${this.sales}/${this.salesInvoiceReturn}`;
  static salesInvoiceReturnManage = `/${this.sales}/${this.salesInvoiceReturn}/${this.manage}`;
  static salesInvoiceReturnDetail = `/${this.sales}/${this.salesInvoiceReturn}/${this.detail}`;

  static salesOrder = 'sales-order';
  static salesOrderList = `/${this.sales}/${this.salesOrder}`;
  static salesOrderManage = `/${this.sales}/${this.salesOrder}/${this.manage}`;
  static salesOrderDetail = `/${this.sales}/${this.salesOrder}/${this.detail}`;

  static expenses = 'expenses';
  static expensesList = `/${this.expenses}`;
  static expensesCategories = `/${this.expenses}/${this.categories}`;

  static salesOrderReturn = 'sales-order-return';
  static salesOrderReturnList = `/${this.sales}/${this.salesOrderReturn}`;
  static salesOrderReturnManage = `/${this.sales}/${this.salesOrderReturn}/${this.manage}`;
  static salesOrderReturnDetail = `/${this.sales}/${this.salesOrderReturn}/${this.detail}`;

  static salesPurchase = 'sales-purchase';

  static salesPayment = 'sales-payment';

  static purchaseOrderRequest = 'purchase-order-request';
  static purchaseOrderRequestList = `/${this.purchase}/${this.purchaseOrderRequest}`;
  static purchaseOrderRequestManage = `/${this.purchase}/${this.purchaseOrderRequest}/${this.manage}`;
  static purchaseOrderRequestDetail = `/${this.purchase}/${this.purchaseOrderRequest}/${this.detail}`;

  static purchaseInvoice = 'purchase-invoice';
  static purchaseInvoiceList = `/${this.purchase}/${this.purchaseInvoice}`;
  static purchaseInvoiceManage = `/${this.purchase}/${this.purchaseInvoice}/${this.manage}`;
  static purchaseInvoiceDetail = `/${this.purchase}/${this.purchaseInvoice}/${this.detail}`;

  static purchaseInvoiceReturn = 'purchase-invoice-return';
  static purchaseInvoiceReturnList = `/${this.purchase}/${this.purchaseInvoiceReturn}`;
  static purchaseInvoiceReturnManage = `/${this.purchase}/${this.purchaseInvoiceReturn}/${this.manage}`;
  static purchaseInvoiceReturnDetail = `/${this.purchase}/${this.purchaseInvoiceReturn}/${this.detail}`;

  static purchaseOrder = 'purchase-order';
  static purchaseOrderList = `/${this.purchase}/${this.purchaseOrder}`;
  static purchaseOrderManage = `/${this.purchase}/${this.purchaseOrder}/${this.manage}`;
  static purchaseOrderDetail = `/${this.purchase}/${this.purchaseOrder}/${this.detail}`;

  static purchaseOrderReturn = 'purchase-order-return';
  static purchaseOrderReturnList = `/${this.purchase}/${this.purchaseOrderReturn}`;
  static purchaseOrderReturnManage = `/${this.purchase}/${this.purchaseOrderReturn}/${this.manage}`;
  static purchaseOrderReturnDetail = `/${this.purchase}/${this.purchaseOrderReturn}/${this.detail}`;

  static purchasePayment = 'purchase-payment';

  static profitLoss = 'profit-loss';

  static productSales = 'product-sales';

  static warehouse = 'warehouse';
  static warehouseStock = 'warehouse-stock';

  static supplierPayments = 'supplier-payments';

  static stock = 'stock';

  static productWarehouse = 'product-warehouse';

  static productPurchase = 'product-purchase';

  static units = 'units';
  static brands = 'brands';
  static warehouses = 'warehouses';

  static products = 'products';
  static productsList = `/${this.products}`;
  static productsManage = `/${this.products}/${this.manage}`;
  static productsDetail = `/${this.products}/${this.detail}`;
  static productsCategory = `/${this.products}/${this.categories}`;
  static productsTaxes = `/${this.products}/${this.taxes}`;
  static productsUnits = `/${this.products}/${this.units}`;
  static productsBrands = `/${this.products}/${this.brands}`;
  static productsWarehouses = `/${this.products}/${this.warehouses}`;

  static reports = 'reports';
  static reportsWarehouseStock = `/${this.reports}/${this.warehouseStock}`;
  static reportsSupplierPayments = `/${this.reports}/${this.supplierPayments}`;
  static reportsStock = `/${this.reports}/${this.stock}`;
  static reportsSalesPurchase = `/${this.reports}/${this.salesPurchase}`;
  static reportsSalesPayment = `/${this.reports}/${this.salesPayment}`;
  static reportsSalesOrder = `/${this.reports}/${this.salesOrder}`;
  static reportsPurchasePayment = `/${this.reports}/${this.purchasePayment}`;
  static reportsPurchaseOrder = `/${this.reports}/${this.purchaseOrder}`;
  static reportsProfitLoss = `/${this.reports}/${this.profitLoss}`;
  static reportsProductWarehouse = `/${this.reports}/${this.productWarehouse}`;
  static reportsProductSales = `/${this.reports}/${this.productSales}`;
  static reportsProductPurchase = `/${this.reports}/${this.productPurchase}`;
  static reportsExpenses = `/${this.reports}/${this.expenses}`;
  static reportsCustomersPayment = `/${this.reports}/${this.customersPayment}`;
  static reportsSalesInvoice = `/${this.reports}/${this.salesInvoice}`;
  static reportsPurchaseInvoice = `/${this.reports}/${this.purchaseInvoice}`;

  static pos = 'pos';

  static users = 'users';
  static session = 'session';
  static permission = 'permission';
  static permissionId = `${this.permission}/:id`;

  static roles = 'roles';

  static settings = 'settings';
  static settingsCompanyProfile = `/${this.settings}/${this.companyProfile}`;
  static settingsUsers = `/${this.settings}/${this.users}`;
  static settingsUsersManage = `/${this.settings}/${this.users}/${this.manage}`;
  static settingsUsersPermission = `/${this.settings}/${this.users}/${this.permission}`;
  static settingsRoles = `/${this.settings}/${this.roles}`;
  static settingsRolesManage = `/${this.settings}/${this.roles}/${this.manage}`;
  static settingsSession = `/${this.settings}/${this.session}`;
  static settingsRolesUsers = `/${this.settings}/${this.roles}/${this.users}`;

  static notifications = 'notifications';
  static notificationsList = `/${this.notifications}`;

  static prefix = '**';

  static reminders = 'reminders';

  static loginAudits = 'login-audits';
  static logs = 'logs';
  static inventory = 'inventory';
}
