import { Component, Input, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {
  PurchaseInvoiceResourceParameter,
  SalesInvoiceResourceParameter,
} from '@core/domain-classes/sales-order/sales-order-resource-parameter';
import { TranslationService } from '@core/services/translation.service';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BaseComponent } from 'src/app/base.component';
import { MatTableDataSource } from '@angular/material/table';
import { SalesInvoiceService } from '../../pages/sales/sales-invoice.service';
import { Customer } from '../domain-classes/customer/customer';
import { PurchaseInvoiceService } from '../../pages/purchases/purchase-invoice/purchase-invoice.service';

@Component({
  selector: 'invoices-short',
  template: `<div class="page-header-main">
      <div class="row align-items-center justify-content-between">
        <div class="col-md-auto col-sm-auto">
          <div class="content-header">
            <h1>{{ title | translate }}</h1>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col table-responsive">
        <table
          mat-table
          [dataSource]="dataSource"
          class="table table-bordered table-hover"
          matSort
          matSortActive="soCreatedDate"
        >
          <ng-container matColumnDef="siCreatedDate">
            <th class="w-5" mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'CREATED_DATE' | translate }}
            </th>
            <td class="w-5" mat-cell *matCellDef="let si">
              <div class="badge badge-date">
                {{ si.siCreatedDate || si.piCreatedDate | utcToLocalTime : 'shortDate' }}
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="invoiceNumber">
            <th class="w-12" mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'INVOICE_NUMBER' | translate }}
            </th>
            <td class="w-12" mat-cell *matCellDef="let so">
              <a
                [routerLink]="[
                  isSalesInvoice
                    ? so.invoiceType == 0
                      ? routes.salesInvoiceDetail
                      : routes.salesInvoiceReturnDetail
                    : so.invoiceType == 0
                    ? routes.purchaseInvoiceDetail
                    : routes.purchaseInvoiceReturnDetail,
                  so.id
                ]"
                >{{ so.invoiceNumber }}
              </a>
            </td>
          </ng-container>

          <ng-container matColumnDef="totalDiscount">
            <th class="w-11" mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'TOTAL_DISCOUNT' | translate }}
            </th>
            <td class="w-11 text-right" mat-cell *matCellDef="let so">
              {{ so.totalDiscount | customCurrency }}
            </td>
          </ng-container>

          <ng-container matColumnDef="totalTax">
            <th class="w-11" mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'TOTAL_TAX' | translate }}</th>
            <td class="w-11 text-right" mat-cell *matCellDef="let so">
              {{ so.totalTax | customCurrency }}
            </td>
          </ng-container>

          <ng-container matColumnDef="totalPaidAmount">
            <th class="w-13" mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'TOTAL_PAID_AMOUNT' | translate }}
            </th>
            <td class="w-13 text-right" mat-cell *matCellDef="let so">
              {{ so.totalPaidAmount | customCurrency }}
            </td>
          </ng-container>

          <ng-container matColumnDef="totalAmount">
            <th class="w-14" mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'TOTAL_AMOUNT' | translate }}</th>
            <td class="w-14 text-right" mat-cell *matCellDef="let so">
              {{ so.totalAmount | customCurrency }}
            </td>
          </ng-container>

          <ng-container matColumnDef="paymentStatus">
            <th class="w-8" mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'PAYMENT_STATUS' | translate }}
            </th>
            <td class="w-8" mat-cell *matCellDef="let so">
              <span
                class="badge"
                [ngClass]="{
                  'badge-success': so.paymentStatus == 0,
                  'badge-danger': so.paymentStatus == 1,
                  'badge-warning': so.paymentStatus == 2
                }"
              >
                {{ so.paymentStatus | paymentStatus }}</span
              >
            </td>
          </ng-container>

          <ng-container matColumnDef="canceled">
            <th class="w-5 text-center" mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'SALES_INVOICE.CANCELED' | translate }}
            </th>
            <td class="w-5 text-center" mat-cell *matCellDef="let si" style="text-align: center">
              <div class="badge w-100" [ngClass]="{ 'badge-success': si.canceled, 'badge-danger': !si.canceled }">
                {{ si.canceled ? ('BUTTONS.YES' | translate) : ('BUTTONS.NO' | translate) }}
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="footer">
            <td mat-footer-cell colspan="12" *matFooterCellDef>
              <mat-paginator> </mat-paginator>
            </td>
          </ng-container>

          <tr *matNoDataRow>
            <td colspan="12">
              <span class="p-4 mt-4">
                <b> {{ 'NO_DATA_FOUND' | translate }}</b>
              </span>
            </td>
          </tr>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns" [class.bg-warning1]="row.invoiceType === 1"></tr>
          <tr mat-footer-row *matFooterRowDef="['footer']; sticky: true"></tr>
        </table>
      </div>
    </div> `,
})
export class InvoicesShortComponent extends BaseComponent {
  @Input() customer: Customer;
  @Input() items = [];
  @Input() title = '';
  @Input() isSalesInvoice = true;

  dataSource = new MatTableDataSource(this.items);

  displayedColumns: string[] = [
    'siCreatedDate',
    'invoiceNumber',
    'totalDiscount',
    'totalTax',
    'totalPaidAmount',
    'totalAmount',
    'paymentStatus',
    'canceled',
  ];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  salesInvoiceResource = new SalesInvoiceResourceParameter();
  purchaseInvoiceResource = new PurchaseInvoiceResourceParameter();

  constructor(
    private salesInvoiceService: SalesInvoiceService,
    public translationService: TranslationService,
    private purchaseInvoiceService: PurchaseInvoiceService,
  ) {
    super(translationService);
  }

  ngOnInit() {
    this.dataSource.data = this.items;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    this.sub$.sink = merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => {
          if (this.isSalesInvoice) {
            this.salesInvoiceResource.skip = this.paginator.pageIndex * this.paginator.pageSize;
            this.salesInvoiceResource.pageSize = this.paginator.pageSize;
            this.salesInvoiceResource.customerId = this.customer.id;
            this.salesInvoiceResource.invoiceType = 2;

            this.salesInvoiceService.getAll(this.salesInvoiceResource).subscribe((res: any) => {
              this.dataSource.data = res.body;
            });
          } else {
            this.purchaseInvoiceResource.skip = this.paginator.pageIndex * this.paginator.pageSize;
            this.purchaseInvoiceResource.pageSize = this.paginator.pageSize;
            this.purchaseInvoiceResource.supplierId = this.customer.id;
            this.purchaseInvoiceResource.invoiceType = 2;

            this.purchaseInvoiceService.getAll(this.purchaseInvoiceResource).subscribe((res: any) => {
              this.dataSource.data = res.body;
            });
          }
        }),
      )
      .subscribe();
  }
}
