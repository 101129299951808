import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { User } from '@core/domain-classes/user/user';
import { SecurityService } from '@core/security/security.service';
import { TranslationService } from '@core/services/translation.service';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from 'src/app/base.component';
import { UserService } from './user.service';

@Component({
  selector: 'change-password',
  template: `<form class="modal-content1" [formGroup]="changePasswordForm" (ngSubmit)="submit()">
    <div class="modal-header position-relative">
      <h3>
        {{ 'CHANGE_PASSWORD' | translate }}
        <ng-container *ngIf="data.email">
          (<span class="text-info">{{ data.email }}</span
          >)
        </ng-container>
      </h3>
      <div class="cursor-pointer position-absolute" style="top: 2.5px; right: 6px;" (click)="dialogRef.close()">
        <i class="fas fa-times-circle text-danger" style="font-size: 32px;"></i>
      </div>
    </div>

    <div class="modal-body">
      <div class="row">
        <div class="col form-group">
          <label class="form-label">{{ 'EMAIL' | translate }}</label>
          <input [attr.disabled]="true" formControlName="email" class="form-control" type="email" />
        </div>
        <div class="col form-group">
          <label class="form-label">{{ 'CURRENT_PASSWORD' | translate }} <span class="text-danger">*</span></label>
          <input
            [placeholder]="'PLACEHOLDERS.INPUT' | translate : { value: 'CURRENT_PASSWORD' | translate }"
            formControlName="oldPasswordPassword"
            class="form-control"
            type="password"
          />
          <div
            class="text-danger"
            *ngIf="
              changePasswordForm.get('oldPasswordPassword').touched &&
              changePasswordForm.get('oldPasswordPassword').hasError('required')
            "
          >
            {{ 'VALIDATIONS.REQURIED' | translate : { value: 'CURRENT_PASSWORD' | translate } }}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col form-group">
          <label class="form-label">{{ 'NEW_PASSWORD' | translate }} <span class="text-danger">*</span></label>
          <input
            [placeholder]="'PLACEHOLDERS.INPUT' | translate : { value: 'NEW_PASSWORD' | translate }"
            formControlName="password"
            class="form-control"
            type="password"
          />
          <div
            class="text-danger"
            *ngIf="changePasswordForm.get('password').touched && changePasswordForm.get('password').errors"
          >
            <div *ngIf="changePasswordForm.get('password').errors?.required">
              {{ 'VALIDATIONS.REQURIED' | translate : { value: 'NEW_PASSWORD' | translate } }}
            </div>
            <div *ngIf="changePasswordForm.get('password').errors?.minlength">
              {{ 'YOU_HAVE_TO_ENTER_AT_LEAST_6_DIGIT' | translate }}
            </div>
          </div>
        </div>
        <div class="col form-group">
          <label class="form-label">{{ 'CONFIRM_PASSWORD' | translate }} <span class="text-danger">*</span></label>
          <input
            [placeholder]="'PLACEHOLDERS.INPUT' | translate : { value: 'CONFIRM_PASSWORD' | translate }"
            formControlName="confirmPassword"
            class="form-control"
            type="password"
          />
          <div
            *ngIf="
              changePasswordForm.get('confirmPassword').touched && changePasswordForm.get('confirmPassword').errors
            "
          >
            <div *ngIf="changePasswordForm.get('confirmPassword').errors?.required">
              {{ 'VALIDATIONS.REQURIED' | translate : { value: 'CONFIRM_PASSWORD' | translate } }}
            </div>
          </div>
          <div *ngIf="changePasswordForm.get('confirmPassword').touched && changePasswordForm.hasError('notSame')">
            {{ 'PASSWORDS_DO_NOT_MATCH' | translate }}
          </div>
        </div>
      </div>
    </div>

    <div class="modal-footer">
      <div class="row w-100">
        <button *hasClaim="claims.PRO_MANAGE_TAX" class="col btn btn-success btn-sm m-right-10 w-100" type="submit">
          <i class="fas fa-save"></i>
          {{ 'BUTTONS.SAVE' | translate }}
        </button>
        <button class="col btn btn-danger btn-sm w-100" (click)="dialogRef.close()">
          <i class="fas fa-times-circle"></i>
          {{ 'CANCEL' | translate }}
        </button>
      </div>
    </div>
  </form> `,
})
export class ChangePasswordComponent extends BaseComponent {
  changePasswordForm: UntypedFormGroup = this.fb.group(
    {
      email: [],
      oldPasswordPassword: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', [Validators.required]],
    },
    {
      validator: this.checkPasswords,
    },
  );

  constructor(
    private userService: UserService,
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<ChangePasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: User,
    private toastrService: ToastrService,
    private securityService: SecurityService,
    public translationService: TranslationService,
  ) {
    super(translationService);
  }

  ngOnInit() {
    this.changePasswordForm.get('email').setValue(this.data.userName);
  }

  checkPasswords(group: UntypedFormGroup) {
    let pass = group.get('password').value;
    let confirmPass = group.get('confirmPassword').value;
    return pass === confirmPass ? null : { notSame: true };
  }

  submit() {
    if (this.changePasswordForm.valid) {
      this.sub$.sink = this.userService
        .changePassword({
          email: '',
          oldPassword: this.changePasswordForm.get('oldPasswordPassword').value,
          newPassword: this.changePasswordForm.get('password').value,
          userName: this.changePasswordForm.get('email').value,
        })
        .subscribe(() => {
          this.toastrService.success(this.translationService.instant('SUCCESSFULLY_CHANGED_PASSWORD'));
          this.securityService.logout();
          this.dialogRef.close();
        });
    }
  }
}
