import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { UnitOperator, unitOperators } from '@core/domain-classes/operator';
import { UnitConversation } from '@core/domain-classes/unit-conversation';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UnitConversationService {
  constructor(private http: HttpClient, public translate: TranslateService) {}

  static getUnitConversationsResolver() {
    return inject(UnitConversationService).getUnitConversations();
  }

  getUnitConversations(): Observable<UnitConversation[]> {
    const url = 'unitConversation';
    return this.http.get<UnitConversation[]>(url);
  }

  getUnitConversation(id: string): Observable<UnitConversation> {
    return this.http.get<UnitConversation>(`unitConversation/getUnit?guid=${id}`);
  }
  deleteUnitConversation(id: string): Observable<void> {
    const url = `unitConversation/${id}`;
    return this.http.delete<void>(url);
  }
  updateUnitConversation(id: string, unitConversation: UnitConversation): Observable<UnitConversation> {
    const url = 'unitConversation/' + id;
    return this.http.put<UnitConversation>(url, unitConversation);
  }
  saveUnitConversation(unitConversation: UnitConversation): Observable<UnitConversation> {
    return this.http.post<UnitConversation>('unitConversation', unitConversation);
  }

  getUnitOperator(): Observable<UnitOperator[]> {
    return of(unitOperators).pipe(
      map((c) => {
        return c.map((d) => {
          d.name = this.translate.instant(d.name);
          return d;
        });
      }),
    );
  }

  static getAllBaseUnitResolver() {
    return inject(UnitConversationService).getAllBaseUnit();
  }

  getAllBaseUnit(): Observable<UnitConversation[]> {
    const url = 'unitConversation/dropDown';
    return this.http.get<UnitConversation[]>(url);
  }
}
