import { Component, Input, ViewChild } from '@angular/core';
import { TranslationService } from '@core/services/translation.service';
import { BaseComponent } from '../../base.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { TransactionService } from '../services/transaction.service';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'items',
  template: `<div class="page-header-main">
      <div class="row align-items-center">
        <div class="col-md-auto col-sm-auto">
          <div class="content-header">
            <h1>{{ 'TITLES.ITEMS' | translate : { value: title | translate } }}</h1>
          </div>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <table mat-table matSort [dataSource]="dataSource" class="table table-bordered table-hover">
        <ng-container matColumnDef="productName">
          <th class="w-15" mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'PRODUCT_NAME' | translate }}</th>
          <td class="w-15" mat-cell *matCellDef="let element">
            {{ element.productName }}
          </td>
        </ng-container>
        <ng-container matColumnDef="unitName">
          <th class="w-11" mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'UNIT' | translate }}</th>
          <td class="w-11" mat-cell *matCellDef="let element">
            {{ element.unitName }}
          </td>
        </ng-container>
        <ng-container matColumnDef="unitPrice">
          <th class="w-11" mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'UNIT_PER_PRICE' | translate }}</th>
          <td class="w-11 text-right" mat-cell *matCellDef="let element">
            {{ element.unitPrice | customCurrency }}
          </td>
        </ng-container>
        <ng-container matColumnDef="quantity">
          <th class="w-8" mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'QUANTITY' | translate }}</th>
          <td class="w-8 text-center" mat-cell *matCellDef="let element">
            <div
              class="badge badge-success w-100"
              [ngClass]="
                transactionService.getBalanceFormat(
                  element?.status === 1 ? -1 * element?.quantity : element.quantity,
                  true
                ).color
              "
            >
              {{ element.quantity }}
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="totalDiscount">
          <th class="w-9" mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'TOTAL_DISCOUNT' | translate }}</th>
          <td class="w-9" mat-cell *matCellDef="let element">
            <div
              class="badge"
              [ngClass]="
                transactionService.getBalanceFormat(
                  element?.status === 1 ? -1 * element?.discount : element.discount,
                  true
                ).color
              "
            >
              {{ element.discount | customCurrency }}({{ element.discountPercentage }}%)
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="taxes">
          <th class="w-11" mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'TAX' | translate }}</th>
          <td class="w-11 text-center" mat-cell *matCellDef="let element">
            <div class="badge badge-warning w-100">
              <span
                *ngFor="
                  let tax of element.salesInvoiceItemTaxes ??
                    element.purchaseInvoiceItemTaxes ??
                    element.salesOrderItemTaxes ??
                    element.purchaseOrderItemTaxes ??
                    element.purchaseInvoiceItemTaxes;
                  let last = last
                "
              >
                {{ tax.taxName }} ({{ tax.taxPercentage }}%) <span *ngIf="!last"> ,</span>
              </span>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="totalTax">
          <th class="w-11" mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'TOTAL_TAX' | translate }}</th>
          <td class="w-11" mat-cell *matCellDef="let element">
            <div
              class="badge"
              [ngClass]="
                transactionService.getBalanceFormat(
                  element?.status === 1 ? -1 * element?.taxValue : element.taxValue,
                  true
                ).color
              "
            >
              {{ element.taxValue | customCurrency }}
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="totalAmount">
          <th class="w-11" mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'TOTAL' | translate }}</th>
          <td class="w-11" mat-cell *matCellDef="let element">
            <div
              class="badge"
              [ngClass]="
                transactionService.getBalanceFormat(
                  element?.status === 1
                    ? -1 * element.unitPrice * element.quantity - element.discount + element.taxValue
                    : element.unitPrice * element.quantity - element.discount + element.taxValue,
                  true
                ).color
              "
            >
              {{ element.unitPrice * element.quantity - element.discount + element.taxValue | customCurrency }}
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="warehouse">
          <th class="w-12" mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'WAREHOUSE' | translate }}</th>
          <td class="w-12" mat-cell *matCellDef="let element">
            {{ element.warehouseName }}
          </td>
        </ng-container>

        <ng-container matColumnDef="footer">
          <td colspan="12" *matFooterCellDef mat-footer-cell>
            <mat-paginator> </mat-paginator>
          </td>
        </ng-container>

        <tr *matNoDataRow>
          <td colspan="12">
            <span class="p-2 mt-2">
              <b> {{ 'NO_DATA_FOUND' | translate }}</b>
            </span>
          </td>
        </tr>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" [class.bg-warning1]="row.status == 1"></tr>
      </table>
    </div> `,
})
export class ItemsComponent extends BaseComponent {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @Input() title: string;
  @Input() data: any[] = [];

  dataSource = new MatTableDataSource<any>(this.data);

  displayedColumns: string[] = [
    'productName',
    'unitName',
    'unitPrice',
    'quantity',
    'totalDiscount',
    'taxes',
    'totalTax',
    'totalAmount',
    'warehouse',
  ];

  constructor(public translationService: TranslationService, public transactionService: TransactionService) {
    super(translationService);
  }

  ngAfterViewInit() {
    this.dataSource.data = this.data;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }
}
