import { Component } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { User } from '@core/domain-classes/user/user';
import { SecurityService } from '@core/security/security.service';
import { TranslationService } from '@core/services/translation.service';
import { environment } from '@environments/environment';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from 'src/app/base.component';
import { ChangePasswordComponent } from './change-password.component';
import { UserService } from './user.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'my-profile',
  template: `
    <style>
      .img-wrap {
        position: relative;
        display: inline-block;
        border: 1px #d32f2f solid;
        font-size: 0;
      }
      .img-wrap .close {
        position: absolute;
        top: 2px;
        right: 2px;
        z-index: 100;
        background-color: #fff;
        padding: 2px 2px 3px 2px;
        color: #000;
        font-weight: bold;
        cursor: pointer;
        opacity: 0.2;
        text-align: center;
        font-size: 22px;
        line-height: 10px;
        border-radius: 50%;
      }
      .img-wrap:hover .close {
        opacity: 1;
      }
    </style>

    <section>
      <div class="page-header-main">
        <div class="row align-items-center justify-content-between">
          <div class="col-md-auto col-sm-auto col">
            <div class="content-header">
              <h1>{{ 'USER_PROFILE' | translate }}</h1>
            </div>
          </div>
          <div *ngIf="!user?.provider" class="col-md-auto col-sm-auto col-auto">
            <button class="btn blue-btn btn-sm" (click)="changePassword()">
              <i class="fas fa-key"></i>
              {{ 'LABELS.CHANGE' | translate : { value: 'PASSWORD' | translate } }}
            </button>
          </div>
        </div>
      </div>

      <form [formGroup]="userForm" (ngSubmit)="submit($event)">
        <div class="row">
          <div class="col-md-9">
            <div class="row">
              <div class="col form-group">
                <label class="form-label">{{ 'FIRST_NAME' | translate }} <span class="text-danger">*</span></label>
                <input class="form-control" formControlName="firstName" type="text" />
              </div>
              <div class="col form-group">
                <label class="form-label">{{ 'LAST_NAME' | translate }} <span class="text-danger">*</span></label>
                <input class="form-control" formControlName="lastName" type="text" />
              </div>
            </div>
            <div class="row">
              <div class="col form-group">
                <label class="form-label">{{ 'EMAIL' | translate }} <span class="text-danger">*</span></label>
                <input class="form-control" formControlName="email" type="text" />
              </div>
              <div class="col form-group">
                <label class="form-label">{{ 'PHONE_NUMBER' | translate }}</label>
                <input class="form-control" formControlName="phoneNumber" type="text" />
              </div>
            </div>
            <div class="row">
              <div class="col">
                <label class="form-label">{{ 'ADDRESS' | translate }}</label>
                <textarea class="form-control" formControlName="address" cols="30" rows="4"></textarea>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="row">
              <div class="col">
                <mat-card>
                  <mat-card-content>
                    <div class="d-flex justify-content-center">
                      <input
                        hidden
                        #fileInput
                        (change)="handleImageUpload($event)"
                        name="logo"
                        type="file"
                        name="Profile Photo"
                        fxFlex="30"
                      />
                      <img
                        style="border: 1.5px solid gray; width: 250px; object-fit: cover!important; height: 232.5px; border-radius: 50%"
                        *ngIf="imgURL"
                        [src]="imgURL"
                      />
                      <img
                        style="border: 1.5px solid gray; width: 250px; object-fit: cover!important; height: 232.5px; border-radius: 50%"
                        *ngIf="!imgURL"
                        src="../../assets/images/logo.png"
                      />
                    </div>
                  </mat-card-content>
                  <mat-card-actions>
                    <div class="row justify-content-center">
                      <button
                        *ngIf="!imgURL"
                        class="col-5 btn btn-success btn-sm mr-3"
                        type="button"
                        (click)="fileInput.click()"
                      >
                        <i class="far fa-image"></i> {{ 'ADD_PHOTO' | translate }}
                      </button>

                      <button
                        *ngIf="imgURL"
                        class="col-5 btn btn-success btn-sm mr-3"
                        type="button"
                        (click)="fileInput.click()"
                      >
                        <i class="far fa-image"></i> {{ 'CHANGE_PHOTO' | translate }}
                      </button>
                      <button *ngIf="imgURL" type="button" (click)="removeImage()" class="col-5 btn btn-danger btn-sm">
                        <i class="fas fa-trash-alt"></i> {{ 'REMOVE_PHOTO' | translate }}
                      </button>
                    </div>
                  </mat-card-actions>
                </mat-card>
              </div>
            </div>
          </div>
        </div>
        <div class="row pt-4">
          <div class="col-md">
            <button class="col-md-1 btn btn-success btn-sm mr-3" type="submit" [disabled]="userForm.invalid">
              <i class="fas fa-save"></i> {{ 'SAVE' | translate }}
            </button>
            <button class="col-md-1 btn btn-danger btn-sm" (click)="loc.reload()" type="button">
              <i class="fas fa-times-circle"></i> {{ 'CANCEL' | translate }}
            </button>
          </div>
        </div>
      </form>
    </section>
  `,
})
export class MyProfilePage extends BaseComponent {
  userForm = this.formBuilder.group({
    id: [''],
    firstName: ['', [Validators.required]],
    lastName: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.email]],
    phoneNumber: [''],
    address: [''],
  });

  user: User;
  fileSelected: File;
  imgURL: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private toastr: ToastrService,
    private dialog: MatDialog,
    public translate: TranslationService,
    private securityService: SecurityService,
    route: ActivatedRoute,
  ) {
    super(translate);

    this.sub$.sink = route.data.subscribe((data) => {
      this.user = data.profile;
      if (this.user) {
        if (this.user.profilePhoto) {
          this.imgURL = environment.apiUrl + this.user.profilePhoto;
        }
        this.userForm.patchValue(this.user);
      }
    });
  }

  handleImageUpload(e: any) {
    const file = e.target.files[0];

    if (!file) {
      return;
    }

    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = (e: any) => {
      const formData = new FormData();

      formData.append(file.name, file);

      this.userService.updateProfilePhoto(formData).subscribe((user: User) => {
        this.toastr.success(
          this.translate.instant('TOASTR.UPDATED_SUCCESSFULLY', {
            value: this.translate.instant('PROFILE_PHOTO'),
          }),
        );
        this.imgURL = reader.result;
        this.securityService.updateUserProfile(user);
        e.target.value = '';
      });
    };
  }

  removeImage() {
    this.userService.updateProfilePhoto(new FormData()).subscribe((user: User) => {
      this.toastr.success(
        this.translate.instant('TOASTR.DELETED_SUCCESSFULLY', {
          value: this.translate.instant('PROFILE_PHOTO'),
        }),
      );
      this.imgURL = '';
      this.securityService.updateUserProfile(user);
    });
  }

  submit(e: MouseEvent) {
    e.preventDefault();

    if (this.userForm.invalid) {
      this.userForm.markAllAsTouched();
      return;
    }

    this.sub$.sink = this.userService
      .updateUserProfile({
        id: this.userForm.get('id').value,
        firstName: this.userForm.get('firstName').value,
        lastName: this.userForm.get('lastName').value,
        email: this.userForm.get('email').value,
        phoneNumber: this.userForm.get('phoneNumber').value,
        userName: this.userForm.get('email').value,
        address: this.userForm.get('address').value,
      })
      .subscribe((user: User) => {
        this.toastr.success(
          this.translationService.instant('TOASTR.UPDATED_SUCCESSFULLY', {
            value: this.translationService.instant('PROFILE'),
          }),
        );
        this.securityService.updateUserProfile(user);
      });
  }

  changePassword(): void {
    this.dialog.open(ChangePasswordComponent, {
      width: '40%',
      data: Object.assign({}, this.user),
    });
  }
}
