export class Languages {
  static languages: LanguageFlag[] = [
    {
      lang: 'tr',
      name: 'Türkçe',
      flag: '../../../assets/images/flags/turkish.png',
    },
    {
      lang: 'en',
      name: 'English',
      flag: '../../../assets/images/flags/united-states.svg',
    },
    {
      lang: 'cn',
      name: '中文',
      flag: '../../../assets/images/flags/china.svg',
    },
    {
      lang: 'es',
      name: 'Español',
      flag: '../../../assets/images/flags/south-korea.svg',
    },
    {
      lang: 'ar',
      name: 'عربي',
      flag: '../../../assets/images/flags/saudi-arabia.svg',
    },
    {
      lang: 'ru',
      name: 'Pусский',
      flag: '../../../assets/images/flags/russia.svg',
    },
    {
      lang: 'ja',
      name: '日本語',
      flag: '../../../assets/images/flags/japan.svg',
    },
    {
      lang: 'de',
      name: 'Deutsch',
      flag: '../../../assets/images/flags/germany.png',
    },
    {
      lang: 'fr',
      name: 'Français',
      flag: '../../../assets/images/flags/french.png',
    },
  ];
}

export interface LanguageFlag {
  lang: string;
  name: string;
  flag: string;
  active?: boolean;
}
