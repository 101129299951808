import { Directive, ElementRef, Renderer2 } from '@angular/core';
import { TranslationService } from '@core/services/translation.service';

@Directive({
  selector: '[lang-dir]',
})
export class LangDirDirective {
  constructor(
    private _elRef: ElementRef,
    private _renderer: Renderer2,
    public translationService: TranslationService,
  ) {
  }

  ngOnInit() {
    this._renderer.setAttribute(this._elRef.nativeElement, 'dir', 'rtl');
  }
}
