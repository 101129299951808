import { ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SidebarComponent } from './sidebar.component';
import { CommonDialogService } from './common-dialog/common-dialog.service';
import { MatDialogModule } from '@angular/material/dialog';
import { SharedModule } from '@shared/shared.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoadingIndicatorModule } from '@shared/loading-indicator/loading-indicator.module';
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
} from 'ngx-perfect-scrollbar';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CommonErrorHandlerService } from './error-handler/common-error-handler.service';
import { HttpClient } from '@angular/common/http';
import { ControlSidebarComponent } from './control-sidebar/control-sidebar.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { OwlDateTimeModule } from 'ng-pick-datetime-ex';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { ItemsComponent } from './reusables/items.component';
import { ManageInvoicePage } from './reusables/manage-invoice';
import { AddPaymentComponent } from './reusables/payments/add-payment.component';
import { PaymentsComponent } from './reusables/payments/payments.component';
import { MatDividerModule } from '@angular/material/divider';
import { DetailPage } from './reusables/detail';
import { ManageOrderPage } from './reusables/manage-order';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ExtraPage } from './reusables/transactions/extra';
import { TransactionsPage } from './reusables/transactions/transactions';
import { ManageTransactionComponent } from './reusables/transactions/manage-transaction.component';
import { MatMenuModule } from '@angular/material/menu';
import { InvoicesShortComponent } from './reusables/invoices-short.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

@NgModule({
  declarations: [
    LayoutComponent,
    AddPaymentComponent,
    PaymentsComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    ControlSidebarComponent,
    ItemsComponent,
    ManageInvoicePage,
    DetailPage,
    ManageOrderPage,
    ExtraPage,
    TransactionsPage,
    ManageTransactionComponent,
    InvoicesShortComponent,
  ],
  imports: [
    MatButtonModule,
    CommonModule,
    RouterModule,
    NgxMatSelectSearchModule,
    OwlDateTimeModule,
    NgSelectModule,
    MatIconModule,
    MatTooltipModule,
    MatMenuModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    TranslateModule,
    MatDialogModule,
    SharedModule,
    MatTooltipModule,
    LoadingIndicatorModule,
    PerfectScrollbarModule,
    MatPaginatorModule,
    MatSortModule,
    MatDividerModule,
    MatSelectModule,
    BsDropdownModule.forRoot(),
  ],
  exports: [
    LayoutComponent,
    ItemsComponent,
    ManageInvoicePage,
    ManageOrderPage,
    DetailPage,
    ExtraPage,
    InvoicesShortComponent,
  ],
  providers: [
    CommonDialogService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    {
      provide: ErrorHandler,
      useClass: CommonErrorHandlerService,
      deps: [HttpClient],
    },
  ],
})
export class CoreModule {}
