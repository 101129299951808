import { trigger, state, style, transition, animate } from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import { Component, OnInit, ViewChild, ElementRef, Input, Inject } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ReminderScheduler } from '@core/domain-classes/reminder/reminder-scheduler';
import { UserAuth } from '@core/domain-classes/user/user-auth';
import { SecurityService } from '@core/security/security.service';
import { SignalrService } from '@core/services/signalr.service';
import { TranslationService } from '@core/services/translation.service';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';
import { BaseComponent } from 'src/app/base.component';
import { LanguageFlag, Languages } from './languages';
import { AppSettings } from '../../app-settings';

@Component({
  selector: 'header',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('slideInOut', [
      state(
        'in',
        style({
          transform: 'translate3d(0,0,0)',
        }),
      ),
      state(
        'out',
        style({
          transform: 'translate3d(100%, 0, 0)',
        }),
      ),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out')),
    ]),
  ],
  template: `<div class="topbar">
    <nav class="navbar header-nav navbar-expand-lg navbar-custom">
      <div class="topbar-left" [class.display-none]="isFromPos">
        <a [routerLink]="routes.initRoute" class="logo">
          <span>
            <img *ngIf="!logoImage" src="../../../favicon.ico" alt="logo-large" class="logo-lg" />
            <img *ngIf="logoImage" [src]="logoImage" alt="logo-large" class="logo-lg" />
          </span>
        </a>
      </div>
      <div class="form-row align-items-center ml-2">
        <div class="col-md-auto col-auto" [class.display-none]="isFromPos">
          <div id="hamburger-menu" class="hamburger cursor-pointer" (click)="togglediv()">
            <span class="line"></span><span class="line"></span><span class="line"></span>
          </div>
        </div>
        <div class="col-md-auto col-auto ml-2" [class.display-none]="isFromPos">
          <div class="dropdown">
            <button
              class="btn blue-btn btn-sm"
              type="button"
              id="dropdownMenu2"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              *hasClaim="[
                claims.SO_ADD_SO,
                claims.PO_ADD_PO,
                claims.CUST_ADD_CUSTOMER,
                claims.SUPP_ADD_SUPPLIER,
                claims.PRO_ADD_PRODUCT,
                claims.EXP_ADD_EXPENSE
              ]"
            >
              <i class="glyphicon glyphicon-plus"></i><i class="fa fa-plus"></i>
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
              <button *hasClaim="claims.SO_ADD_SO" class="dropdown-item p-2 no-unserline" type="button">
                <a [routerLink]="[routes.salesOrderManage]"
                  ><i class="fa fa-plus ml-1 blackiconcolor"></i>&nbsp;
                  <span style="color: black">{{
                    'TITLES.ADD' | translate : { value: 'SALES_ORDER' | translate }
                  }}</span></a
                >
              </button>
              <button *hasClaim="claims.PO_ADD_PO" class="dropdown-item p-2 no-unserline" type="button">
                <a [routerLink]="[routes.purchaseOrderManage]"
                  ><i class="fa fa-plus ml-1 blackiconcolor"></i>&nbsp;
                  <span style="color: black">{{
                    'TITLES.ADD' | translate : { value: 'PURCHASE_ORDER' | translate }
                  }}</span>
                </a>
              </button>
              <button *hasClaim="claims.CUST_ADD_CUSTOMER" class="dropdown-item p-2 no-unserline" type="button">
                <a [routerLink]="[routes.customersManage]"
                  ><i class="fa fa-plus ml-1 blackiconcolor"></i>&nbsp;
                  <span style="color: black">{{ 'TITLES.ADD' | translate : { value: 'CUSTOMER' | translate } }}</span>
                </a>
              </button>
              <button *hasClaim="claims.SUPP_ADD_SUPPLIER" class="dropdown-item p-2 no-unserline" type="button">
                <a [routerLink]="[routes.suppliersManage]"
                  ><i class="fa fa-plus ml-1 blackiconcolor"></i>&nbsp;
                  <span style="color: black">{{
                    'TITLES.ADD' | translate : { value: 'SUPPLIER' | translate }
                  }}</span></a
                >
              </button>
              <button *hasClaim="claims.PRO_ADD_PRODUCT" class="dropdown-item p-2 no-unserline" type="button">
                <a [routerLink]="[routes.productsManage]"
                  ><i class="fa fa-plus ml-1 blackiconcolor"></i>&nbsp;
                  <span style="color: black">{{ 'TITLES.ADD' | translate : { value: 'PRODUCT' | translate } }}</span></a
                >
              </button>
              <button *hasClaim="claims.EXP_ADD_EXPENSE" class="dropdown-item p-2 no-unserline" type="button">
                <a (click)="addExpense()"
                  ><i class="fa fa-plus ml-1 blackiconcolor"></i>&nbsp;
                  <span class="text-black" style="color: black">{{
                    'TITLES.ADD' | translate : { value: 'EXPENSE' | translate }
                  }}</span></a
                >
              </button>
            </div>
          </div>
        </div>

        <a
          [class.display-none]="isFromPos"
          [routerLink]="['/' + routes.pos]"
          *hasClaim="'POS_POS'"
          class="btn blue-btn btn-sm m-right-10"
        >
          {{ 'POS' | translate }}
        </a>

        <div class="col-md-auto col-auto ml-3 d-block d-md-none">
          <div>
            <span class="notification-area">
              <i class="far fa-bell fs-24"></i>
              <span class="noti-count">
                {{ notificationCount }}
              </span>
            </span>
          </div>
        </div>
        <div class="col-md-auto col-auto">
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon hamburger" style="margin-top: -10px">
              <span class="line"></span><span class="line"></span><span class="line"></span>
            </span>
          </button>
        </div>
      </div>

      <div class="d-flex" style="gap: 0.5rem">
        <a href="javascript:history.back()"
          ><i class="fas fa-lg" [ngClass]="langDir == 'ltr' ? 'fa-arrow-left' : 'fa-arrow-right'"> </i>
        </a>

        <a href="javascript:history.forward()"
          ><i class="fas fa-lg" [ngClass]="langDir == 'ltr' ? 'fa-arrow-right' : 'fa-arrow-left'"></i
        ></a>
      </div>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="list-unstyled topbar-nav ml-auto topbar-nav-right mb-0 float-right hide-when-toogle">
          <ng-container *ngIf="security.getUserDetail().id == 'bb6f4041-0436-447f-bbf6-6b4744104f8d'">
            <li class="nav-item toggle-mode" [style.margin-left]="langDir == 'rtl' ? '2rem' : null">
              <label class="switch">
                <input
                  class="switch__input"
                  type="checkbox"
                  role="switch"
                  [(ngModel)]="mode"
                  (change)="appSettings.mode.next(this.mode ? 'dark' : 'light')"
                />
                <svg
                  class="switch__icon switch__icon--light"
                  viewBox="0 0 12 12"
                  width="12px"
                  height="12px"
                  aria-hidden="true"
                >
                  <g fill="none" stroke="#fff" stroke-width="1" stroke-linecap="round">
                    <circle cx="6" cy="6" r="2" />
                    <g stroke-dasharray="1.5 1.5">
                      <polyline points="6 10,6 11.5" transform="rotate(0,6,6)" />
                      <polyline points="6 10,6 11.5" transform="rotate(45,6,6)" />
                      <polyline points="6 10,6 11.5" transform="rotate(90,6,6)" />
                      <polyline points="6 10,6 11.5" transform="rotate(135,6,6)" />
                      <polyline points="6 10,6 11.5" transform="rotate(180,6,6)" />
                      <polyline points="6 10,6 11.5" transform="rotate(225,6,6)" />
                      <polyline points="6 10,6 11.5" transform="rotate(270,6,6)" />
                      <polyline points="6 10,6 11.5" transform="rotate(315,6,6)" />
                    </g>
                  </g>
                </svg>
                <svg
                  class="switch__icon switch__icon--dark"
                  viewBox="0 0 12 12"
                  width="12px"
                  height="12px"
                  aria-hidden="true"
                >
                  <g fill="none" stroke="#fff" stroke-width="1" stroke-linejoin="round" transform="rotate(-45,6,6)">
                    <path
                      d="m9,10c-2.209,0-4-1.791-4-4s1.791-4,4-4c.304,0,.598.041.883.105-.995-.992-2.367-1.605-3.883-1.605C2.962.5.5,2.962.5,6s2.462,5.5,5.5,5.5c1.516,0,2.888-.613,3.883-1.605-.285.064-.578.105-.883.105Z"
                    />
                  </g>
                </svg>
              </label>
            </li>
          </ng-container>
          <li class="nav-item dropdown">
            <a href="#" class="dropdown-toggle nav-link" data-toggle="dropdown">
              <img class="contry-flag rounded" src="{{ language?.flag }}" alt="language" /> {{ language?.name }}
            </a>
            <ul class="dropdown-menu contry-dropdown" aria-labelledby="dropdownMenuButton">
              <ng-container *ngFor="let language of languages">
                <li role="button" class="dropdown-item" (click)="setNewLanguageRefresh(language.lang)">
                  <img class="contry-flag ml-2" src="{{ language.flag }}" /> {{ language.name }}
                </li>
              </ng-container>
            </ul>
          </li>
          <li class="nav-item dropdown d-none d-md-block">
            <span class="notification-area" data-toggle="dropdown">
              <i class="far fa-bell fs-20 text-white"></i>
              <span class="noti-count">
                {{ notificationCount }}
              </span>
            </span>
            <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
              <span class="dropdown-item dropdown-header"></span>
              <div class="dropdown-divider"></div>
              <div *ngFor="let notificationUser of notificationUserList">
                <div class="dropdown-item" title="{{ notificationUser.duration | utcToLocalTime : 'short' }}">
                  <i class="fas fa-envelope mr-2"></i>
                  <ng-container [ngSwitch]="notificationUser.application">
                    <ng-container *ngSwitchCase="1">
                      <a [routerLink]="[routes.salesOrderDetail, notificationUser.referenceId]">
                        {{ notificationUser.subject }} -{{ 'SALES_ORDER_EXPECTED_SHIPMENT_DATE_IS' | translate }}
                        {{ notificationUser.duration | utcToLocalTime : 'shortDate' }}.
                      </a>
                    </ng-container>
                    <ng-container *ngSwitchCase="2">
                      <a [routerLink]="['/purchase-order/detail', notificationUser.referenceId]">
                        {{ notificationUser.subject }}-{{ 'PURCHASE_ORDER_EXPECTED_DELIVERY_DATE_IS' | translate }}
                        {{ notificationUser.duration | utcToLocalTime : 'shortDate' }}.
                      </a>
                    </ng-container>
                    <ng-container *ngSwitchCase="3">
                      <a [routerLink]="['/inquiry/manage', notificationUser.referenceId]">
                        {{ notificationUser.subject }}
                        {{ notificationUser.duration | utcToLocalTime : 'shortDate' }}.
                      </a>
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                      {{ notificationUser.subject }}
                    </ng-container>
                  </ng-container>
                  <small class="float-right text-muted text-sm">
                    {{ notificationUser.duration | dateAgo }}
                  </small>
                </div>
                <hr class="mb-0" />
              </div>
              <a
                [routerLink]="['/notifications']"
                routerLinkActive="router-link-active"
                class="dropdown-item dropdown-footer"
                >{{ 'SEE_ALL_NOTIFICATIONS' | translate }}</a
              >
            </div>
          </li>
          <li class="nav-item">
            <a class="nav-link cursor-pointer" [routerLink]="['/my-profile']">
              <span class="left-fa-icon">
                <img
                  *ngIf="appUserAuth?.profilePhoto"
                  [src]="profilePath"
                  alt="User Avatar"
                  class="user-image mt-1" /></span
              ><span class="mb-0 fs-14 text-capitalize">{{ appUserAuth.firstName }} {{ appUserAuth.lastName }}</span>
              <img
                *ngIf="!appUserAuth?.profilePhoto"
                src="assets/images/user-profile.jpg"
                alt="User Avatar"
                class="user-image mt-1"
              />
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" (click)="onLogout()">
              <span class="left-fa-icon"><i class="fas fa-sign-out-alt fs-15"></i></span>
              <span class="mb-0 fs-14 text-capitalize">{{ 'LOGOUT' | translate }}</span>
            </a>
          </li>
        </ul>
      </div>
    </nav>
  </div> `,
})
export class HeaderComponent extends BaseComponent implements OnInit {
  @ViewChild('selectElem', { static: true }) el: ElementRef;
  @Input() public lead: any;
  public isFromPos = false;
  navbarOpen = false;
  appUserAuth: UserAuth = null;
  language: LanguageFlag;
  notificationCount: number = 0;
  notificationUserList: ReminderScheduler[] = [];
  languages: LanguageFlag[] = [];
  profilePath = '';
  logoImage = '';
  oldLang: string = '';
  mode: boolean = this.appSettings.mode.value == 'dark' ? true : false;

  constructor(
    private router: Router,
    private securityService: SecurityService,
    private signalrService: SignalrService,
    public translationService: TranslationService,
    public translate: TranslateService,
    @Inject(DOCUMENT) private document: Document,
    route: ActivatedRoute,
    public appSettings: AppSettings,
    public security: SecurityService,
  ) {
    super(translationService);

    this.sub$.sink = route.data.subscribe((data) => {
      this.sub$.sink = this.signalrService.userNotification$.subscribe(() => {
        this.notificationCount = data.userNotificationCount;
        this.notificationUserList = data.top10UserNotification;
      });
    });
  }

  ngOnInit(): void {
    this.hideOrShowBaseOnCurrentUrl();
    this.languages = Languages.languages;
    this.routerNavigate();
    this.setTopLogAndName();
    this.setDefaultLanguage();
    this.companyProfileSubscription();
  }

  addExpense() {
    this.router.navigate([this.routes.expenses]);

    const event = this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        setTimeout(() => {
          this.document.getElementById('add-expense').click();
        }, 0);
        event.unsubscribe();
      }
    });
  }

  routerNavigate() {
    this.sub$.sink = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if (event.url.indexOf('pos') > -1) {
          this.isFromPos = true;
          this.lead.className = 'pos-page';
        } else {
          this.isFromPos = false;
          this.lead.className = '';
        }
      });
  }

  hideOrShowBaseOnCurrentUrl() {
    if (this.router.url.indexOf('pos') > -1) {
      this.isFromPos = true;
      this.lead.className = 'pos-page';
    } else {
      this.isFromPos = false;
      this.lead.className = '';
    }
  }

  companyProfileSubscription() {
    this.securityService.companyProfile.subscribe((profile) => {
      if (profile) {
        this.logoImage = profile.logoUrl;
      }
    });
  }

  setDefaultLanguage() {
    const lang = this.translationService.getSelectedLanguage();
    if (lang) this.setLanguageWithRefresh(lang);
  }

  setLanguageWithRefresh(lang: string) {
    this.languages.forEach((language: LanguageFlag) => {
      if (language.lang === lang) {
        language.active = true;
        this.language = language;
      } else {
        language.active = false;
      }
    });
    this.translationService.setLanguage(lang);
    if (this.oldLang == 'ar' || lang == 'ar') {
      //this.setLanguage(lang);
    }
  }

  setLanguageWithRefreshNew(lang: string) {
    this.languages.forEach((language: LanguageFlag) => {
      if (language.lang === lang) {
        language.active = true;
        this.language = language;
      } else {
        language.active = false;
      }
    });
    this.translationService.setLanguage(lang);
    this.setLanguage(lang);
  }

  setNewLanguageRefresh(lang: string) {
    location.reload();
    this.translationService.setLanguage(lang);
    this.oldLang = this.translationService.getSelectedLanguage();
    this.sub$.sink = this.translationService.setLanguage(lang).subscribe((response) => {
      this.setLanguageWithRefreshNew(response['LANGUAGE']);
    });
  }

  setLanguage(currentLang) {
    if (currentLang == 'ar') {
      this.setDynamicStyleMain(`main-style`, currentLang);
      this.setDynamicStyleMain(`common-style`, currentLang);
      this.setDynamicStyleBootstrap(`boostrap-style`, currentLang);
    } else {
      this.setDynamicStyleMain('main-style', '');
      this.setDynamicStyleMain('common-style', '');
      this.setDynamicStyleBootstrap(`boostrap-style`, '');
    }
  }

  setDynamicStyleBootstrap(styleName: string, lang: string) {
    const head = this.document.getElementsByTagName('head')[0];
    let themeLink = this.document.getElementById(`${styleName}`) as HTMLLinkElement;
    if (themeLink) {
      if (lang) {
        themeLink.href = 'bootstrap-rtl-style.css';
      } else {
        themeLink.href = 'bootstrap-style.css';
      }
    } else {
      const style = this.document.createElement('link');
      style.id = `${styleName}`;
      style.rel = 'stylesheet';
      style.href = lang ? `bootstrap-rtl-style.css` : `bootstrap-style.css`;
      head.appendChild(style);
    }
  }

  setDynamicStyleMain(styleName: string, lang: string) {
    const head = this.document.getElementsByTagName('head')[0];
    let themeLink = this.document.getElementById(`${styleName}`) as HTMLLinkElement;
    if (themeLink) {
      if (lang) {
        themeLink.href = styleName + '-ar.css';
      } else {
        themeLink.href = styleName + '.css';
      }
    } else {
      const style = this.document.createElement('link');
      style.id = `${styleName}`;
      style.rel = 'stylesheet';
      style.href = lang ? `${styleName}-ar.css` : `${styleName}.css`;
      head.appendChild(style);
    }
  }

  removedDynamicStyleMain(styleName) {
    const style = this.document.getElementById(styleName);
    if (style) {
      this.document.removeChild(style);
    }
  }

  setTopLogAndName() {
    this.sub$.sink = this.securityService.securityObject$.subscribe((c) => {
      if (c) {
        this.appUserAuth = c;
        if (this.appUserAuth.profilePhoto) {
          this.profilePath = environment.apiUrl + this.appUserAuth.profilePhoto;
        }
      }
    });
  }

  onLogout(): void {
    this.signalrService.logout(this.appUserAuth.id);
    this.securityService.logout();
  }

  public togglediv() {
    if (this.lead.className === 'toggled') {
      this.lead.className = '';
    } else {
      this.lead.className = 'toggled';
    }
  }
}
