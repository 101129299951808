import { Component, inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'common-dialog',
  standalone: true,
  imports: [TranslateModule],
  template: `
    <style>
      .primary-message {
        font: 400 16px/28px Roboto, Helvetica Neue, sans-serif !important;
        letter-spacing: normal !important;
        margin: 0 0 16px !important;
      }
    </style>
    <div class="modal-content1">
      <div class="modal-body">
        <div class="row">
          <h5 [innerHTML]="primaryMessage" class="col-10 primary-message" md-dialog-title></h5>
          <div class="col-2 cursor-pointer" (click)="dialogRef.close(false)">
            <i class="fas fa-times-circle text-danger" style="font-size: 32px;"></i>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <div class="row w-100">
          <button (click)="this.dialogRef.close(true)" class="col btn btn-success btn-sm m-right-10 w-100">
            <i class="fas fa-check"></i> {{ 'YES' | translate }}
          </button>
          <button (click)="this.dialogRef.close(false)" class="col btn btn-danger btn-sm w-100">
            <i class="fas fa-times-circle"></i> {{ 'CANCEL' | translate }}
          </button>
        </div>
      </div>
    </div>
  `,
})
export class CommonDialogComponent {
  primaryMessage: string;

  dialogRef = inject(MatDialogRef<CommonDialogComponent>);
}
