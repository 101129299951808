import { Component } from '@angular/core';
import { UserAuth } from '@core/domain-classes/user/user-auth';
import { SecurityService } from '@core/security/security.service';
import { CommonService } from '@core/services/common.service';
import { TranslationService } from '@core/services/translation.service';
import { environment } from '@environments/environment';
import { BaseComponent } from 'src/app/base.component';

@Component({
  selector: 'sidebar',
  template: `<style>
      span {
        padding-right: 0.25rem;
      }
      li {
        padding-bottom: 0.1rem;
      }
    </style>

    <div class="left-sidenav" id="sidebar-accordion">
      <div class="main-menu-inner">
        <div class="menu-body sidebar-nav">
          <ul class="nav" data-widget="treeview" role="menu" data-accordion="false">
            <!-- DASHBOARD -->
            <div>
              <li
                class="nav-item"
                *hasClaim="[
                  claims.DB_STATISTICS,
                  claims.DB_BEST_SELLING_PROS,
                  claims.DB_REMINDERS,
                  claims.DB_LATEST_INQUIRIES,
                  claims.DB_RECENT_SO_SHIPMENT,
                  claims.DB_RECENT_PO_DELIVERY
                ]"
              >
                <a
                  [routerLink]="[routes.initRoute]"
                  [ngClass]="{ active: currentUrl == routes.initRoute }"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  class="nav-link"
                >
                  <i class="nav-icon fas fa-tachometer-alt"></i>
                  <p>
                    {{ 'DASHBOARD' | translate }}
                  </p>
                </a>
              </li>
            </div>

            <!-- PRODUCTS -->
            <div>
              <li
                id="ProductHeading"
                aria-expanded="true"
                aria-controls="collapseOne"
                data-toggle="collapse"
                data-target="#ProductCategory"
                class="collapsed"
                *hasClaim="[
                  claims.PRO_VIEW_PRODUCTS,
                  claims.PRO_ADD_PRODUCT,
                  claims.PRO_MANAGE_PRO_CAT,
                  claims.PRO_MANAGE_TAX,
                  claims.PRO_MANAGE_UNIT,
                  claims.PRO_MANAGE_BRAND,
                  claims.PRO_MANAGE_WAREHOUSE
                ]"
              >
                <a class="nav-link" [ngClass]="{ active: currentUrl.includes(routes.products) }">
                  <i class="nav-icon fas fa-cube"></i>
                  <span>{{ 'PRODUCT' | translate }}</span>
                  <span class="fas fa-angle-down rotate-arrow ml-auto"></span>
                </a>
              </li>
              <ul
                class="sub-menu collapse"
                [ngClass]="{ show: currentUrl.includes(routes.products) }"
                id="ProductCategory"
                aria-labelledby="ProductHeading"
                data-parent="#sidebar-accordion"
              >
                <li *hasClaim="claims.PRO_VIEW_PRODUCTS" class="nav-item">
                  <a
                    [routerLink]="[routes.products]"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    class="nav-link"
                  >
                    <i class="fas fa-cubes nav-icon"></i>
                    <p>{{ 'PRODUCTS' | translate }}</p>
                  </a>
                </li>
                <li *hasClaim="claims.PRO_MANAGE_PRO_CAT" class="nav-item">
                  <a
                    [routerLink]="[routes.productsCategory]"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    class="nav-link"
                  >
                    <i class="fas fa-th-large nav-icon"></i>
                    <p>{{ 'PRODUCT_CATEGORIES' | translate }}</p>
                  </a>
                </li>

                <li *hasClaim="claims.PRO_MANAGE_TAX" class="nav-item">
                  <a
                    [routerLink]="[routes.productsTaxes]"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    class="nav-link"
                  >
                    <i class="fas fa-coins nav-icon"></i>
                    <p>{{ 'TAXES' | translate }}</p>
                  </a>
                </li>

                <li class="nav-item" *hasClaim="claims.PRO_MANAGE_UNIT">
                  <a
                    [routerLink]="[routes.productsUnits]"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    class="nav-link"
                  >
                    <i class="fas fa-weight-hanging nav-icon"></i>
                    <p>{{ 'UNIT_CONVERSATIONS' | translate }}</p>
                  </a>
                </li>
                <li *hasClaim="claims.PRO_MANAGE_BRAND" class="nav-item">
                  <a
                    [routerLink]="[routes.productsBrands]"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    class="nav-link"
                  >
                    <i class="fas fa-tags"></i>
                    <p>{{ 'BRANDS' | translate }}</p>
                  </a>
                </li>
                <li *hasClaim="claims.PRO_MANAGE_WAREHOUSE" class="nav-item">
                  <a
                    [routerLink]="[routes.productsWarehouses]"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    class="nav-link"
                  >
                    <i class="fas fa-warehouse nav-icon" style="font-size: 14.5px;"></i>
                    <p>{{ 'WAREHOUSES' | translate }}</p>
                  </a>
                </li>
              </ul>
            </div>

            <!-- CUSTOMERS -->
            <li *hasClaim="claims.CUST_VIEW_CUSTOMERS" class="nav-item">
              <a
                [ngClass]="{ active: currentUrl.includes(routes.customers) }"
                [routerLink]="[routes.customers]"
                class="nav-link"
              >
                <i class="nav-icon fas fa-user-tie"></i>
                <p>{{ 'CUSTOMER' | translate }}</p>
              </a>
            </li>

            <!-- SUPPLIERS -->
            <li *hasClaim="claims.SUPP_VIEW_SUPPLIERS" class="nav-item">
              <a
                [ngClass]="{ active: currentUrl.includes(routes.suppliers) }"
                [routerLink]="[routes.suppliersList]"
                class="nav-link"
              >
                <i class="fas fa-truck nav-icon"></i>
                <p>{{ 'SUPPLIER' | translate }}</p>
              </a>
            </li>

            <!-- SALE -->
            <div>
              <li
                id="SalesHeading"
                aria-expanded="true"
                aria-controls="collapseOne"
                data-toggle="collapse"
                data-target="#Sales"
                class="collapsed"
                *hasClaim="[
                  claims.SO_VIEW_SALES_ORDERS,
                  claims.SO_RETURN_SO,
                  claims.SI_VIEW_SALES_INVOICES,
                  claims.SI_RETURN_SI
                ]"
              >
                <a class="nav-link" [ngClass]="{ active: currentUrl.split('/')[1].includes(routes.sales) }">
                  <i class="nav-icon fas fa-cart-arrow-down"></i>
                  <span>{{ 'SALE' | translate }}</span>
                  <span class="fas fa-angle-down rotate-arrow ml-auto"></span>
                </a>
              </li>
              <ul
                class="sub-menu collapse"
                id="Sales"
                aria-labelledby="SalesHeading"
                [ngClass]="{ show: currentUrl.split('/')[1].includes(routes.sales) }"
                data-parent="#sidebar-accordion"
              >
                <li class="nav-item" *hasClaim="claims.SO_VIEW_SALES_ORDERS">
                  <a
                    [routerLink]="[routes.salesOrderList]"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    class="nav-link"
                  >
                    <i class="fas fa-th-list nav-icon"></i>
                    <p>{{ 'ORDER' | translate }}</p>
                  </a>
                </li>
                <li class="nav-item" *hasClaim="[claims.SO_VIEW_SALES_ORDERS, claims.SO_RETURN_SO]">
                  <a
                    [routerLink]="[routes.salesOrderReturnList]"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    class="nav-link"
                  >
                    <i class="fas fa-list-ul nav-icon"></i>
                    <p>{{ 'ORDER_RETURN' | translate }}</p>
                  </a>
                </li>
                <li class="nav-item" *hasClaim="claims.SI_VIEW_SI_DETAIL">
                  <a
                    [routerLink]="[routes.salesInvoiceList]"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    class="nav-link"
                  >
                    <i class="fas fa-th-list nav-icon"></i>
                    <p>{{ 'INVOICE' | translate }}</p>
                  </a>
                </li>
                <li class="nav-item" *hasClaim="[claims.SI_RETURN_SI]">
                  <a
                    [routerLink]="[routes.salesInvoiceReturnList]"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    class="nav-link"
                  >
                    <i class="fas fa-list-ul nav-icon"></i>
                    <p>{{ 'INVOICE_RETURN' | translate }}</p>
                  </a>
                </li>
              </ul>
            </div>

            <!-- PURCHASE -->
            <div>
              <li
                id="PurchaseHeading"
                aria-expanded="true"
                aria-controls="collapseOne"
                data-toggle="collapse"
                data-target="#Purchase"
                class="collapsed"
                *hasClaim="[
                  claims.PO_VIEW_PURCHASE_ORDERS,
                  claims.PO_RETURN_PO,
                  claims.PI_VIEW_PURCHASE_INVOICES,
                  claims.PI_RETURN_PI
                ]"
              >
                <a class="nav-link" [ngClass]="{ active: currentUrl.split('/')[1].includes(routes.purchase) }">
                  <i class="nav-icon fas fa-cart-plus"></i>
                  <span>{{ 'PURCHASE' | translate }}</span>
                  <span class="fas fa-angle-down rotate-arrow ml-auto"></span>
                </a>
              </li>
              <ul
                id="Purchase"
                class="sub-menu collapse"
                [ngClass]="{ show: currentUrl.split('/')[1].includes(routes.purchase) }"
                aria-labelledby="PurchaseHeading"
                data-parent="#sidebar-accordion"
              >
                <li class="nav-item" *hasClaim="claims.POR_VIEW_PO_REQUESTS">
                  <a
                    [routerLink]="[routes.purchaseOrderRequestList]"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    class="nav-link"
                  >
                    <i class="fas fa-briefcase nav-icon"></i>
                    <p>{{ 'PURCHASE_ORDER_REQUEST' | translate }}</p>
                  </a>
                </li>
                <li class="nav-item" *hasClaim="claims.PO_VIEW_PURCHASE_ORDERS">
                  <a
                    [routerLink]="[routes.purchaseOrderList]"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    class="nav-link"
                  >
                    <i class="fas fa-th-list nav-icon"></i>
                    <p>{{ 'ORDER' | translate }}</p>
                  </a>
                </li>
                <li class="nav-item" *hasClaim="[claims.PO_RETURN_PO]">
                  <a
                    [routerLink]="[routes.purchaseOrderReturnList]"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    class="nav-link"
                  >
                    <i class="fas fa-list-ul nav-icon"></i>
                    <p>{{ 'ORDER_RETURN' | translate }}</p>
                  </a>
                </li>
                <li class="nav-item" *hasClaim="claims.PI_VIEW_PURCHASE_INVOICES">
                  <a
                    [routerLink]="[routes.purchaseInvoiceList]"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    class="nav-link"
                  >
                    <i class="fas fa-th-list nav-icon"></i>
                    <p>{{ 'INVOICE' | translate }}</p>
                  </a>
                </li>
                <li class="nav-item" *hasClaim="[claims.PI_RETURN_PI, claims.PI_VIEW_PURCHASE_INVOICES]">
                  <a
                    [routerLink]="[routes.purchaseInvoiceReturnList]"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    class="nav-link"
                  >
                    <i class="fas fa-list-ul nav-icon"></i>
                    <p>{{ 'INVOICE_RETURN' | translate }}</p>
                  </a>
                </li>
              </ul>
            </div>

            <!-- Incoming E-Invoice -->
            <li *hasClaim="claims.SI_ADD_SI" class="nav-item">
              <a
                [routerLink]="[routes.incomingEInvoice]"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                class="nav-link"
              >
                <i class="fas fa-solid fa-receipt nav-icon"></i>
                <p>{{ 'INCOMING_E_INVOICE' | translate }}</p>
              </a>
            </li>

            <!-- INVENTORY -->
            <li *hasClaim="claims.INVE_VIEW_INVENTORIES" class="nav-item">
              <a
                [routerLink]="['/inventory']"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                class="nav-link"
              >
                <i class="fas fa-boxes nav-icon"></i>
                <p>{{ 'INVENTORY' | translate }}</p>
              </a>
            </li>

            <!-- EXPENSE -->
            <div>
              <li
                id="ExpenseHeading"
                aria-expanded="true"
                aria-controls="collapseOne"
                data-toggle="collapse"
                data-target="#Expense"
                class="collapsed"
                *hasClaim="[claims.EXP_VIEW_EXPENSES, claims.EXP_ADD_EXPENSE, claims.EXP_MANAGE_EXP_CATEGORY]"
              >
                <a class="nav-link" [ngClass]="{ active: currentUrl.split('/')[1].includes(routes.expenses) }">
                  <i class="fas fa-solid fa-wallet nav-icon"></i>
                  <span>{{ 'EXPENSE' | translate }}</span>
                  <span class="fas fa-angle-down rotate-arrow ml-auto"></span>
                </a>
              </li>
              <ul
                class="sub-menu collapse"
                id="Expense"
                [ngClass]="{ show: currentUrl.split('/')[1].includes(routes.expenses) }"
                aria-labelledby="ExpenseHeading"
                data-parent="#sidebar-accordion"
              >
                <li class="nav-item" *hasClaim="claims.EXP_VIEW_EXPENSES">
                  <a
                    [routerLink]="[routes.expensesList]"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    class="nav-link"
                  >
                    <i class="fas fa-list-ul nav-icon"></i>
                    <p>
                      {{ 'EXPENSES' | translate }}
                    </p>
                  </a>
                </li>
                <li class="nav-item" *hasClaim="claims.EXP_MANAGE_EXP_CATEGORY">
                  <a
                    [routerLink]="[routes.expensesCategories]"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    class="nav-link"
                  >
                    <i class="fas fa-th-large nav-icon"></i>
                    <p>
                      {{ 'EXPENSE_CATEGORIES' | translate }}
                    </p>
                  </a>
                </li>
              </ul>
            </div>

            <!-- REPORTS -->
            <div>
              <li
                id="ReportsHeading"
                aria-expanded="true"
                aria-controls="collapseOne"
                data-toggle="collapse"
                data-target="#Reports"
                class="collapsed"
                *hasClaim="[
                  claims.SI_VIEW_SALES_INVOICES,
                  claims.REP_PO_REP,
                  claims.REP_SO_REP,
                  claims.REP_PRO_PP_REP,
                  claims.REP_PRO_SO_REPORT,
                  claims.REP_VIEW_WAR_REP,
                  claims.REP_STOCK_REPORT,
                  claims.REP_PO_PAYMENT_REP,
                  claims.REP_SO_PAYMENT_REP,
                  claims.REP_EXPENSE_REP,
                  claims.REP_VIEW_PRO_LOSS_REP,
                  claims.REP_SALES_VS_PURCHASE_REP,
                  claims.REP_SUP_PAYMENT_REP,
                  claims.REP_CUST_PAYMENT_REP,
                  claims.REP_VIEW_WAR_REP
                ]"
              >
                <a class="nav-link" [ngClass]="{ active: currentUrl.includes(routes.reports) }">
                  <i class="nav-icon fas fa-chart-bar"></i>
                  <span>{{ 'REPORTS' | translate }}</span>
                  <span class="fas fa-angle-down rotate-arrow ml-auto"></span>
                </a>
              </li>
              <ul
                class="sub-menu collapse"
                id="Reports"
                aria-labelledby="ReportsHeading"
                [ngClass]="{ show: currentUrl == 'report' }"
                data-parent="#sidebar-accordion"
              >
                <li *hasClaim="claims.SI_VIEW_SALES_INVOICES" class="nav-item">
                  <a
                    [routerLink]="[routes.reportsSalesInvoice]"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    class="nav-link"
                  >
                    <i class="far fa-list-alt nav-icon"></i>
                    <p>
                      {{ 'SALES_INVOICE_REPORT' | translate }}
                    </p>
                  </a>
                </li>
                <li *hasClaim="claims.PI_VIEW_PURCHASE_INVOICES" class="nav-item">
                  <a
                    [routerLink]="[routes.reportsPurchaseInvoice]"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    class="nav-link"
                  >
                    <i class="far fa-list-alt nav-icon"></i>
                    <p>
                      {{ 'PURCHASE_INVOICE_REPORT' | translate }}
                    </p>
                  </a>
                </li>
                <li *hasClaim="claims.REP_PO_REP" class="nav-item">
                  <a
                    [routerLink]="[routes.reportsPurchaseOrder]"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    class="nav-link"
                  >
                    <i class="far fa-list-alt nav-icon"></i>
                    <p>
                      {{ 'PURCHASE_ORDER_REPORT' | translate }}
                    </p>
                  </a>
                </li>
                <li *hasClaim="claims.REP_SO_REP" class="nav-item">
                  <a
                    [routerLink]="[routes.reportsSalesOrder]"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    class="nav-link"
                  >
                    <i class="far fa-list-alt nav-icon"></i>
                    <p>
                      {{ 'SALES_ORDER_REPORT' | translate }}
                    </p>
                  </a>
                </li>
                <li *hasClaim="claims.REP_PRO_PP_REP" class="nav-item">
                  <a
                    [routerLink]="[routes.reportsProductPurchase]"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    class="nav-link"
                  >
                    <i class="far fa-list-alt nav-icon"></i>
                    <p>
                      {{ 'PRODUCT_PURCHASE_REPORT' | translate }}
                    </p>
                  </a>
                </li>
                <li *hasClaim="claims.REP_PRO_SO_REPORT" class="nav-item">
                  <a
                    [routerLink]="[routes.reportsProductSales]"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    class="nav-link"
                  >
                    <i class="far fa-list-alt nav-icon"></i>
                    <p>
                      {{ 'PRODUCT_SALES_REPORT' | translate }}
                    </p>
                  </a>
                </li>
                <li *hasClaim="claims.REP_VIEW_WAR_REP" class="nav-item">
                  <a
                    [routerLink]="[routes.reportsProductWarehouse]"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    class="nav-link"
                  >
                    <i class="far fa-list-alt nav-icon"></i>
                    <p>
                      {{ 'PRODUCT_WAREHOUSE_REPORT' | translate }}
                    </p>
                  </a>
                </li>
                <li *hasClaim="claims.REP_STOCK_REPORT" class="nav-item">
                  <a
                    [routerLink]="[routes.reportsStock]"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    class="nav-link"
                  >
                    <i class="far fa-list-alt nav-icon"></i>
                    <p>
                      {{ 'STOCK_REPORT' | translate }}
                    </p>
                  </a>
                </li>
                <li *hasClaim="claims.REP_PO_PAYMENT_REP" class="nav-item">
                  <a
                    [routerLink]="[routes.reportsPurchasePayment]"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    class="nav-link"
                  >
                    <i class="far fa-list-alt nav-icon"></i>
                    <p>
                      {{ 'PURCHASE_PAYMENT_REPORT' | translate }}
                    </p>
                  </a>
                </li>
                <li *hasClaim="claims.REP_SO_PAYMENT_REP" class="nav-item">
                  <a
                    [routerLink]="[routes.reportsSalesPayment]"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    class="nav-link"
                  >
                    <i class="far fa-list-alt nav-icon"></i>
                    <p>
                      {{ 'SALES_PAYMENT_REPORT' | translate }}
                    </p>
                  </a>
                </li>
                <li *hasClaim="claims.REP_EXPENSE_REP" class="nav-item">
                  <a
                    [routerLink]="[routes.reportsExpenses]"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    class="nav-link"
                  >
                    <i class="far fa-list-alt nav-icon"></i>
                    <p>
                      {{ 'EXPENSE_REPORT' | translate }}
                    </p>
                  </a>
                </li>
                <li *hasClaim="claims.REP_VIEW_PRO_LOSS_REP" class="nav-item">
                  <a
                    [routerLink]="[routes.reportsProfitLoss]"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    class="nav-link"
                  >
                    <i class="far fa-list-alt nav-icon"></i>
                    <p>
                      {{ 'PROFIT_VS_LOSS_REPORT' | translate }}
                    </p>
                  </a>
                </li>
                <li *hasClaim="claims.REP_SALES_VS_PURCHASE_REP" class="nav-item">
                  <a
                    [routerLink]="[routes.reportsSalesPurchase]"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    class="nav-link"
                  >
                    <i class="far fa-list-alt nav-icon"></i>
                    <p>
                      {{ 'SALES_VS_PURCHASE' | translate }}
                    </p>
                  </a>
                </li>
                <li *hasClaim="claims.REP_SUP_PAYMENT_REP" class="nav-item">
                  <a
                    [routerLink]="[routes.reportsSupplierPayments]"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    class="nav-link"
                  >
                    <i class="far fa-money-bill-alt nav-icon"></i>
                    <p>
                      {{ 'SUPPLIER_PAYMENT_REPORT' | translate }}
                    </p>
                  </a>
                </li>
                <li *hasClaim="claims.REP_CUST_PAYMENT_REP" class="nav-item">
                  <a
                    [routerLink]="[routes.reportsCustomersPayment]"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    class="nav-link"
                  >
                    <i class="fas fa-money-bill-alt nav-icon"></i>
                    <p>
                      {{ 'CUSTOMER_PAYMENT_REPORT' | translate }}
                    </p>
                  </a>
                </li>
                <li *hasClaim="claims.REP_VIEW_WAR_REP" class="nav-item">
                  <a
                    [routerLink]="[routes.reportsWarehouseStock]"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    class="nav-link"
                  >
                    <i class="fas fa-box nav-icon"></i>
                    <p>
                      {{ 'WAREHOUSE_STOCK_REPORT' | translate }}
                    </p>
                  </a>
                </li>
              </ul>
            </div>

            <!-- INQUIRY -->
            <!-- <div>
              <li
                id="InquiryHeading"
                aria-expanded="true"
                aria-controls="collapseOne"
                *hasClaim="[claims.INQ_VIEW_INQUIRIES, claims.INQ_ADD_INQUIRY]"
                data-toggle="collapse"
                data-target="#Inquiry"
                class="collapsed"
              >
                <a class="nav-link" [ngClass]="{ active: currentUrl.includes('inquiry') }">
                  <i class="nav-icon fas fa-question-circle"></i>
                  <span>{{ 'INQUIRY' | translate }}</span>
                  <span class="fas fa-angle-down rotate-arrow ml-auto"></span>
                </a>
              </li>
              <ul
                class="sub-menu collapse"
                id="Inquiry"
                [ngClass]="{ show: currentUrl.includes('inquiry') }"
                aria-labelledby="InquiryHeading"
                data-parent="#sidebar-accordion"
              >
                <li *hasClaim="claims.INQ_VIEW_INQUIRIES" class="nav-item">
                  <a
                    [routerLink]="['/inquiry']"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    class="nav-link"
                  >
                    <i class="far fa-list-alt nav-icon"></i>
                    <p>{{ 'LIST' | translate }}</p>
                  </a>
                </li>
                <li *hasClaim="claims.INQ_ADD_INQUIRY" class="nav-item">
                  <a
                    [routerLink]="['/inquiry/add']"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    class="nav-link"
                  >
                    <i class="fas fa-plus-square nav-icon"></i>
                    <p>{{ 'ADD_NEW' | translate }}</p>
                  </a>
                </li>
                <li *hasClaim="'INQ_MANAGE_INQ_STATUS'" class="nav-item">
                  <a
                    [routerLink]="['/inquiry-status']"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    class="nav-link"
                  >
                    <i class="far fa-list-alt nav-icon"></i>
                    <p>{{ 'INQUIRY_STATUS' | translate }}</p>
                  </a>
                </li>
                <li *hasClaim="'INQ_MANAGE_INQ_SOURCE'" class="nav-item">
                  <a
                    [routerLink]="['/inquiry-source']"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    class="nav-link"
                  >
                    <i class="far fa-list-alt nav-icon"></i>
                    <p>{{ 'INQUIRY_SOURCES' | translate }}</p>
                  </a>
                </li>
              </ul>
            </div> -->

            <!-- REMINDER -->
            <li *hasClaim="claims.REM_VIEW_REMINDERS" class="nav-item">
              <a
                [routerLink]="['/reminders']"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                class="nav-link"
              >
                <i class="far fa-bell nav-icon"></i>
                <p>
                  {{ 'REMINDER' | translate }}
                </p>
              </a>
            </li>

            <!-- NOTIFICATIONS -->

            <li *hasClaim="null" class="nav-item">
              <a
                [routerLink]="['/notifications']"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                class="nav-link"
              >
                <i class="fas fa-stopwatch"></i>
                <p>{{ 'NOTIFICATIONS' | translate }}</p>
              </a>
            </li>

            <!-- EMAIL -->
            <div>
              <li
                id="EmailHeading"
                aria-expanded="true"
                aria-controls="collapseOne"
                *hasClaim="[
                  claims.EMAIL_MANAGE_EMAIL_SMTP_SETTINS,
                  claims.EMAIL_MANAGE_EMAIL_TEMPLATES,
                  claims.EMAIL_SEND_EMAIL
                ]"
                data-toggle="collapse"
                data-target="#Email"
                class="collapsed"
              >
                <a class="nav-link" [ngClass]="{ active: currentUrl.includes(routes.email) }">
                  <i class="nav-icon fas fa-envelope-open-text"></i>
                  <span>{{ 'EMAIL' | translate }}</span>
                  <span class="fas fa-angle-down rotate-arrow ml-auto"></span>
                </a>
              </li>
              <ul
                class="sub-menu collapse"
                id="Email"
                aria-labelledby="EmailHeading"
                [ngClass]="{ show: currentUrl.includes(routes.email) }"
                data-parent="#sidebar-accordion"
              >
                <li *hasClaim="claims.EMAIL_MANAGE_EMAIL_SMTP_SETTINS" class="nav-item">
                  <a
                    [routerLink]="routes.emailSmtpsList"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    class="nav-link"
                  >
                    <i class="fas fa-cog nav-icon"></i>
                    <p>{{ 'SMTP_SETTINGS' | translate }}</p>
                  </a>
                </li>
                <li *hasClaim="claims.EMAIL_MANAGE_EMAIL_TEMPLATES" class="nav-item">
                  <a
                    [routerLink]="[routes.emailTemplatesList]"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    class="nav-link"
                  >
                    <i class="fas fa-mail-bulk nav-icon"></i>
                    <p>{{ 'EMAIL_TEMPLATE' | translate }}</p>
                  </a>
                </li>
                <li *hasClaim="claims.EMAIL_SEND_EMAIL" class="nav-item">
                  <a
                    [routerLink]="[routes.emailSend]"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    class="nav-link"
                  >
                    <i class="fas fa-paper-plane nav-icon"></i>
                    <p>{{ 'SEND_EMAIL' | translate }}</p>
                  </a>
                </li>
              </ul>
            </div>

            <!-- <li *hasClaim="claims.SETT_UPDATE_COM_PROFILE" class="nav-item">
                  <a
                    [routerLink]="[routes.companyProfile]"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    class="nav-link"
                  >
                    <i class="fas fa-building nav-icon"></i>
                    <p>{{ 'COMPANY_PROFILE.TITLE' | translate }}</p>
                  </a>
                </li>
                <li *hasClaim="'SETT_MANAGE_COUNTRY'" class="nav-item">
                  <a
                    [routerLink]="['/country']"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    class="nav-link"
                  >
                    <i class="far fa-list-alt nav-icon"></i>
                    <p>{{ 'COUNTRY' | translate }}</p>
                  </a>
                </li>
                <li *hasClaim="'SETT_MANAGE_CITY'" class="nav-item">
                  <a
                    [routerLink]="['/cities']"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    class="nav-link"
                  >
                    <i class="far fa-list-alt nav-icon"></i>
                    <p>{{ 'CITY' | translate }}</p>
                  </a>
                </li> -->

            <!-- LOGS -->
            <div>
              <li
                id="LogsHeading"
                aria-expanded="true"
                aria-controls="collapseOne"
                *hasClaim="[claims.LOGS_VIEW_LOGIN_AUDITS, claims.LOGS_VIEW_ERROR_LOGS]"
                data-toggle="collapse"
                data-target="#Logs"
                class="collapsed"
              >
                <a
                  class="nav-link"
                  [ngClass]="{ active: currentUrl.includes(routes.logs) || currentUrl.includes(routes.loginAudits) }"
                >
                  <i class="fas fa-exclamation-triangle nav-icon"></i>
                  <span>{{ 'LOGS' | translate }}</span>
                  <span class="fas fa-angle-down rotate-arrow ml-auto"></span>
                </a>
              </li>
              <ul
                class="sub-menu collapse"
                id="Logs"
                aria-labelledby="LogsHeading"
                [ngClass]="{ show: currentUrl.includes(routes.logs) || currentUrl.includes(routes.loginAudits) }"
                data-parent="#sidebar-accordion"
              >
                <li *hasClaim="claims.LOGS_VIEW_LOGIN_AUDITS" class="nav-item">
                  <a
                    [routerLink]="[routes.loginAudits]"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    class="nav-link"
                  >
                    <i class="fas fa-clipboard-list nav-icon"></i>

                    <p>{{ 'LOGIN_AUDIT' | translate }}</p>
                  </a>
                </li>
                <li *hasClaim="claims.LOGS_VIEW_ERROR_LOGS" class="nav-item">
                  <a
                    [routerLink]="[routes.logs]"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    class="nav-link"
                  >
                    <i class="fas fa-exclamation-circle nav-icon"></i>
                    <p>{{ 'ERROR_LOGS' | translate }}</p>
                  </a>
                </li>
              </ul>
            </div>

            <!-- Settings -->
            <div>
              <li
                id="SettingsHeading"
                aria-expanded="true"
                aria-controls="collapseOne"
                data-toggle="collapse"
                *hasClaim="[
                  claims.SETT_UPDATE_COM_PROFILE,
                  claims.USR_VIEW_USERS,
                  claims.USR_ASSIGN_USR_ROLES,
                  claims.USR_ONLINE_USERS,
                  claims.ROLES_VIEW_ROLES
                ]"
                data-target="#Settings"
                class="collapsed"
              >
                <a class="nav-link" [ngClass]="{ active: currentUrl.includes(routes.settings) }">
                  <i class="nav-icon fa fa-tools"></i>
                  <span>{{ 'SETTINGS' | translate }}</span>
                  <span class="fas fa-angle-down rotate-arrow ml-auto"></span>
                </a>
              </li>
              <ul
                class="sub-menu collapse"
                id="Settings"
                aria-labelledby="SettingsHeading"
                [ngClass]="{ show: currentUrl.includes(routes.settings) }"
                data-parent="#sidebar-accordion"
              >
                <li *hasClaim="claims.SETT_UPDATE_COM_PROFILE" class="nav-item">
                  <a
                    [routerLink]="[routes.settingsCompanyProfile]"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    class="nav-link"
                  >
                    <i class="fas fa-building nav-icon"></i>
                    <p>{{ 'COMPANY_PROFILE.TITLE' | translate }}</p>
                  </a>
                </li>
                <li *hasClaim="claims.USR_VIEW_USERS" class="nav-item">
                  <a
                    [routerLink]="[routes.settingsUsers]"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    class="nav-link"
                  >
                    <i class="fas fa-users nav-icon"></i>
                    <p>{{ 'USERS' | translate }}</p>
                  </a>
                </li>
                <li *hasClaim="claims.USR_ASSIGN_USR_ROLES" class="nav-item">
                  <a
                    [routerLink]="[routes.settingsRolesUsers]"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    class="nav-link"
                  >
                    <i class="fas fa-user-shield nav-icon"></i>
                    <p>{{ 'USER_ROLES' | translate }}</p>
                  </a>
                </li>
                <li *hasClaim="claims.USR_ONLINE_USERS" class="nav-item">
                  <a
                    [routerLink]="[routes.settingsSession]"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    class="nav-link"
                  >
                    <i class="nav-icon fa fa-chalkboard-teacher"></i>
                    <p>
                      {{ 'CURRENT_ONLINE_USERS' | translate }}
                    </p>
                  </a>
                </li>
                <li *hasClaim="claims.ROLES_VIEW_ROLES" class="nav-item">
                  <a
                    [routerLink]="routes.settingsRoles"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    class="nav-link"
                  >
                    <i class="fas fa-users-cog nav-icon"></i>
                    <p>{{ 'ROLE_MANAGE' | translate }}</p>
                  </a>
                </li>
              </ul>
            </div>
          </ul>
        </div>
      </div>
    </div> `,
})
export class SidebarComponent extends BaseComponent {
  appUserAuth: UserAuth = null;
  currentUrl: string = this.routes.initRoute;

  constructor(
    private securityService: SecurityService,
    private commonService: CommonService,
    public translationService: TranslationService,
  ) {
    super(translationService);
  }

  ngOnInit() {
    this.setTopLogAndName();
    this.routerNavigate();
  }

  setTopLogAndName() {
    this.sub$.sink = this.securityService.securityObject$.subscribe((c) => {
      if (c) {
        this.appUserAuth = c;
        if (this.appUserAuth.profilePhoto) {
          this.appUserAuth.profilePhoto = `${environment.apiUrl}${this.appUserAuth.profilePhoto}`;
        }
      }
    });
  }

  routerNavigate() {
    this.sub$.sink = this.commonService.currentUrl$.subscribe((c) => {
      this.currentUrl = c;
    });
  }

  getState(currentMenu) {
    if (currentMenu.active) {
      return 'down';
    } else {
      return 'up';
    }
  }
}
