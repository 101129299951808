import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { ReactiveFormsModule, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  standalone: true,
  imports: [ReactiveFormsModule, TranslateModule, MatTooltipModule],
  selector: 'request-dialog',
  template: `
    <form class="modal-content1" [formGroup]="requestForm" (ngSubmit)="submit($event)">
      <div class="modal-header">
        <h3>{{ 'CONTACT_US' | translate }}</h3>
        <div style="cursor: pointer;" (click)="dialogRef.close()">
          <i class="fas fa-times-circle text-danger" style="font-size: 32px;"></i>
        </div>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col form-group">
            <label class="form-label">{{ 'REQUEST.NAME' | translate }}</label>
            <input
              [placeholder]="'PLACEHOLDERS.INPUT' | translate : { value: 'REQUEST.NAME' | translate }"
              class="form-control"
              formControlName="name"
              type="text"
            />
          </div>
          <div class="col form-group">
            <label class="form-label">{{ 'REQUEST.SURNAME' | translate }}</label>
            <input
              [placeholder]="'PLACEHOLDERS.INPUT' | translate : { value: 'REQUEST.SURNAME' | translate }"
              class="form-control"
              formControlName="surname"
              type="text"
            />
          </div>
        </div>
        <div class="row">
          <div class="col form-group">
            <label class="form-label">{{ 'REQUEST.EMAIL' | translate }}</label>
            <input
              [placeholder]="'PLACEHOLDERS.INPUT' | translate : { value: 'REQUEST.EMAIL' | translate }"
              class="form-control"
              formControlName="email"
              type="text"
            />
          </div>
        </div>
        <div class="row">
          <div class="col form-group">
            <label class="form-label"
              >{{ 'REQUEST.REQUESTS' | translate }}

              <i
                mat-raised-button
                matTooltipPosition="above"
                matTooltipClass="fs-18"
                matTooltip="Bize Herhangi Bir İstek, Öneri veya Şikayetlerinizi İletebilirsiniz."
                class="fas fa-xl fa-info-circle"
              ></i>
            </label>
            <textarea
              [placeholder]="'PLACEHOLDERS.INPUT' | translate : { value: 'REQUEST.REQUESTS' | translate }"
              class="form-control"
              formControlName="requestDetail"
              cols="30"
              rows="10"
            ></textarea>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <div class="row w-100">
          <button class="col btn btn-success btn-sm m-right-10 w-100" type="submit">
            <i class="fas fa-save"></i>
            {{ 'BUTTONS.SEND' | translate }}
          </button>
          <button class="col btn btn-danger btn-sm w-100" type="button" (click)="dialogRef.close()">
            <i class="fas fa-times-circle"></i>
            {{ 'CANCEL' | translate }}
          </button>
        </div>
      </div>
    </form>
  `,
})
export class RequestComponent {
  requestForm = this.formBuilder.group({
    name: '',
    surname: '',
    email: ['', [Validators.required, Validators.email]],
    requestDetail: '',
  });

  constructor(
    public dialogRef: MatDialogRef<RequestComponent>,
    private formBuilder: UntypedFormBuilder,
    private http: HttpClient,
    private toastr: ToastrService,
    private translate: TranslateService,
  ) {}

  submit(e: MouseEvent) {
    e.preventDefault();

    if (this.requestForm.invalid) {
      return;
    }

    this.http.post('addrequest', this.requestForm.value).subscribe(
      () => {
        this.toastr.success(this.translate.instant('REQUEST.SUCCESS'));

        this.dialogRef.close();
      },
      () => {
        this.toastr.error(this.translate.instant('REQUEST.ERROR'));
      },
    );
  }
}
