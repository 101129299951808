export class InventorySource {
  id: number;
  name: string;
}

export const inventorySource: InventorySource[] = [
  {
    id: 0,
    name: 'DIRECT',
  },
  {
    id: 1,
    name: 'PURCHASE_INVOICE.TITLE',
  },
  {
    id: 2,
    name: 'SALES_INVOICE.TITLE',
  },
  {
    id: 3,
    name: 'PURCHASE_INVOICE_RETURN',
  },
  {
    id: 4,
    name: 'SALES_INVOICE_RETURN',
  },
];
