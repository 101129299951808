import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { CommonHttpErrorService } from '@core/error-handler/common-http-error.service';
import { User } from '@core/domain-classes/user/user';
import { forkJoin, Observable } from 'rxjs';
import { CommonError } from '@core/error-handler/common-error';
import { catchError, map } from 'rxjs/operators';
import { UserClaim } from '@core/domain-classes/user/user-claim';
import { UserResource } from '@core/domain-classes/user/user-resource';
import { ActivatedRouteSnapshot } from '@angular/router';
import { ActionService } from '../core/services/action.service';
import { PageService } from '../core/services/page.service';
import { Action } from '../core/domain-classes/action';

@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(private httpClient: HttpClient, private commonHttpErrorService: CommonHttpErrorService) {}

  static getUserPermissionResolver(route: ActivatedRouteSnapshot) {
    const userService = inject(UserService);

    return forkJoin([
      inject(ActionService).getAll(),
      inject(PageService).getAll(),
      userService.getUser(route.paramMap?.get('id')),
    ]).pipe(
      map(([actions, pages, user]) => {
        pages = pages.map((p: any) => Object.assign({}, p, { pageActions: actions.filter((c) => c.pageId == p.id) }));

        pages.forEach((page) => {
          page.pageActions = page.pageActions.map((action: Action) => {
            if (userService.checkPermission(user as User, action.id)) {
              return { ...action, selected: true } as Action;
            } else {
              return { ...action, selected: false } as Action;
            }
          });

          page.allSelected = page.pageActions.every((t: any) => t.selected);
        });

        return { user, pages };
      }),
    );
  }

  checkPermission(user: User, actionId: string): boolean {
    return user.userClaims.find((c) => c.actionId === actionId) ? true : false;
  }

  updateUser(user: User): Observable<User | CommonError> {
    const url = `user/${user.id}`;
    return this.httpClient.put<User>(url, user).pipe(catchError(this.commonHttpErrorService.handleError));
  }

  addUser(user: User): Observable<User | CommonError> {
    const url = `user`;
    return this.httpClient.post<User>(url, user).pipe(catchError(this.commonHttpErrorService.handleError));
  }

  deleteUser(id: string): Observable<void | CommonError> {
    const url = `user/${id}`;
    return this.httpClient.delete<void>(url).pipe(catchError(this.commonHttpErrorService.handleError));
  }

  getUser(id: string): Observable<User | CommonError> {
    const url = `user/${id}`;
    return this.httpClient.get<User>(url).pipe(catchError(this.commonHttpErrorService.handleError));
  }

  updateUserClaim(userClaims: UserClaim[], userId: string): Observable<User | CommonError> {
    const url = `UserClaim/${userId}`;
    return this.httpClient.put<User>(url, { userClaims }).pipe(catchError(this.commonHttpErrorService.handleError));
  }

  resetPassword(user: User): Observable<User | CommonError> {
    const url = `user/resetpassword`;
    return this.httpClient.post<User>(url, user).pipe(catchError(this.commonHttpErrorService.handleError));
  }

  changePassword(user: any): Observable<User | CommonError> {
    const url = `user/changepassword`;
    return this.httpClient.post<User>(url, user).pipe(catchError(this.commonHttpErrorService.handleError));
  }

  updateUserProfile(user: User): Observable<User | CommonError> {
    const url = `user/profile`;
    return this.httpClient.put<User>(url, user).pipe(catchError(this.commonHttpErrorService.handleError));
  }

  static getUserProfileResolver() {
    return inject(UserService).getUserProfile();
  }

  getUserProfile(): Observable<User | CommonError> {
    const url = `user/profile`;
    return this.httpClient.get<User>(url).pipe(catchError(this.commonHttpErrorService.handleError));
  }

  static getUserResolver(route: ActivatedRouteSnapshot) {
    return inject(UserService).getUser(route.paramMap.get('id'));
  }

  static getUsersResolver() {
    let userResource = new UserResource();
    userResource.orderBy = 'firstName desc';

    return inject(UserService).getUsers(userResource);
  }

  getUsers(resource: UserResource): Observable<HttpResponse<User[]> | CommonError> {
    const url = `user/GetUsers`;
    const customParams = new HttpParams()
      .set('Fields', resource.fields)
      .set('OrderBy', resource.orderBy)
      .set('PageSize', resource.pageSize.toString())
      .set('Skip', resource.skip.toString())
      .set('SearchQuery', resource.searchQuery)
      .set('name', resource.name.toString());

    return this.httpClient
      .get<User[]>(url, {
        params: customParams,
        observe: 'response',
      })
      .pipe(catchError(this.commonHttpErrorService.handleError));
  }

  getRecentlyRegisteredUsers(): Observable<User[] | CommonError> {
    const url = `user/GetRecentlyRegisteredUsers`;
    return this.httpClient.get<User[]>(url).pipe(catchError(this.commonHttpErrorService.handleError));
  }

  updateProfilePhoto(form: FormData): Observable<User | CommonError> {
    const url = `user/UpdateUserProfilePhoto`;
    return this.httpClient.post<User>(url, form).pipe(catchError(this.commonHttpErrorService.handleError));
  }
}
