import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  CanActivateChild,
  CanLoad,
  Route,
} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { SecurityService } from './security.service';
import { TranslationService } from '../services/translation.service';
import { AppRoutes } from '../../shared/constants';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(
    private securityService: SecurityService,
    private router: Router,
    private toastr: ToastrService,
    private translate: TranslationService,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.securityService.isLogin()) {
      let claimType: string = next.data['claimType'];

      if (claimType) {
        if (!this.securityService.hasClaim(claimType)) {
          this.toastr.error(this.translate.instant('TOASTR.YOU_DONT_HAVE_RIGHT_TO_ACCESS_THIS_PAGE'));
          this.router.navigate([AppRoutes.myProfile]);
          return false;
        }
      }
    } else {
      this.router.navigate([AppRoutes.login], {
        queryParams: { returnUrl: state.url },
      });
      return false;
    }
    return true;
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.securityService.isLogin()) {
      let claimType: string = next.data['claimType'];
      if (claimType) {
        if (!this.securityService.hasClaim(claimType)) {
          this.toastr.error(this.translate.instant('TOASTR.YOU_DONT_HAVE_RIGHT_TO_ACCESS_THIS_PAGE'));
          return false;
        }
      }
      return true;
    } else {
      this.router.navigate([AppRoutes.login], {
        queryParams: { returnUrl: state.url },
      });
      return false;
    }
  }
  canLoad(route: Route): boolean {
    if (this.securityService.isLogin()) {
      return true;
    } else {
      this.router.navigate([AppRoutes.login]);
      return false;
    }
  }
}
