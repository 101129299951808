import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CompanyProfile } from '@core/domain-classes/company-profile';
import { CommonError } from '@core/error-handler/common-error';
import { CommonHttpErrorService } from '@core/error-handler/common-http-error.service';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap, take } from 'rxjs/operators';
import { AppRoutes } from '../../../shared/constants';

@Injectable({
  providedIn: 'root',
})
export class CompanyProfileService {
  constructor(private http: HttpClient, private commonHttpErrorService: CommonHttpErrorService) {}

  static getCompanyProfileResolver() {
    return inject(CompanyProfileService)
      .getCompanyProfile()
      .pipe(
        take(1),
        mergeMap((companyProfile: CompanyProfile) => {
          if (companyProfile) {
            return of(companyProfile);
          } else {
            inject(Router).navigate([AppRoutes.initRoute]);
            return null;
          }
        }),
      );
  }

  getCompanyProfile(): Observable<CompanyProfile | CommonError> {
    return this.http.get<CompanyProfile>('companyProfile').pipe(catchError(this.commonHttpErrorService.handleError));
  }

  updateCompanyProfile(companyProfile: any): Observable<CompanyProfile | CommonError> {
    return this.http
      .post<CompanyProfile>('companyProfile', companyProfile)
      .pipe(catchError(this.commonHttpErrorService.handleError));
  }
}
