import { HttpResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Customer } from '@core/domain-classes/customer/customer';
import { CustomerResourceParameter } from '@core/domain-classes/customer/customer-resource-parameter';
import { DeliveryStatusEnum } from '@core/domain-classes/delivery-status-enum';
import { Product } from '@core/domain-classes/product/product';
import { ProductResourceParameter } from '@core/domain-classes/product/product-resource-parameter';
import { SalesOrder } from '@core/domain-classes/sales-order/sales-order';
import { SalesOrderItem } from '@core/domain-classes/sales-order/sales-order-item';
import { SalesOrderItemTax } from '@core/domain-classes/sales-order/sales-order-item-tax';
import { SalesOrderStatusEnum } from '@core/domain-classes/sales-order/sales-order-status';
import { Tax } from '@core/domain-classes/tax';
import { TranslationService } from '@core/services/translation.service';
import { QuantitiesUnitPriceTaxPipe } from '@shared/pipes/quantities-unitprice-tax.pipe';
import { QuantitiesUnitPricePipe } from '@shared/pipes/quantities-unitprice.pipe';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, finalize, switchMap, tap } from 'rxjs/operators';
import { BaseComponent } from '../../base.component';
import { UnitConversation } from '@core/domain-classes/unit-conversation';
import { Operators } from '@core/domain-classes/operator';
import { Warehouse } from '@core/domain-classes/warehouse';
import { CustomerService } from '../../pages/customers/customer.service';
import { SalesOrderService } from '../../pages/sales/sales-order.service';
import { ProductService } from '../../pages/products/products.service';
import { SalesOrderResourceParameter } from '../domain-classes/sales-order/sales-order-resource-parameter';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'manage-order',
  host: { class: 'position-relative' },
  template: `
    <style></style>

    <div class="page-header-main">
      <div class="row align-items-center justify-content-between">
        <div class="col-md-auto col-sm-auto">
          <div class="content-header">
            <h1>
              {{ 'TITLES.MANAGE' | translate : { value: data.title | translate } }}
              <ng-container *ngIf="salesOrderForm.get('orderNumber').value">
                (<a class="text-info" [routerLink]="salesOrder?.id ? [data.detail, salesOrder.id] : null">{{
                  salesOrderForm.get('orderNumber').value
                }}</a
                >)</ng-container
              >
            </h1>
          </div>
        </div>
      </div>
    </div>

    <form *ngIf="data.isReturn && !salesOrder" [formGroup]="salesOrderForm">
      <div class="row">
        <div class="col-4 form-group">
          <label class="form-label">{{ 'CUSTOMER' | translate }} <span class="text-danger">*</span></label>
          <ng-select
            appendTo="manage-order"
            formControlName="customerId"
            [placeholder]="'PLACEHOLDERS.SELECT' | translate : { value: 'CUSTOMER' | translate }"
            [items]="customers"
            [hideSelected]="true"
            bindLabel="customerName"
            bindValue="id"
            [virtualScroll]="true"
            [searchable]="true"
            [selectableGroup]="true"
            [loading]="customerResource.loading"
            (search)="customerNameSearch($event.term)"
          >
            <ng-template ng-option-tmp let-item="item"> {{ item.customerName }} </ng-template>
          </ng-select>
        </div>
        <div class="col-4 form-group">
          <label>{{ 'SALES_ORDER' | translate }} </label>
          <ng-select
            appendTo="manage-order"
            formControlName="id"
            [placeholder]="'PLACEHOLDERS.SELECT' | translate : { value: data.title | translate }"
            [items]="salesOrders"
            [hideSelected]="true"
            bindValue="id"
            [virtualScroll]="true"
            [selectableGroup]="true"
            [searchable]="false"
          >
            <ng-template ng-option-tmp let-item="item">
              {{ item.orderNumber }} - <span class="text-info">{{ item.totalAmount | customCurrency }}</span>
            </ng-template>
          </ng-select>
        </div>
      </div>
    </form>

    <form *ngIf="!(data.isReturn && !salesOrder)" [formGroup]="salesOrderForm">
      <div class="row">
        <div class="col form-group">
          <label class="form-label">{{ 'ORDER_NUMBER' | translate }} <span class="text-danger">*</span></label>
          <input formControlName="orderNumber" class="form-control" type="text" />
          <div
            class="text-danger"
            *ngIf="salesOrderForm.get('orderNumber').touched && salesOrderForm.get('orderNumber').hasError('required')"
          >
            {{ 'VALIDATIONS.REQUIRED' | translate : { value: 'ORDER_NUMBER' | translate } }}
          </div>
        </div>

        <div class="col form-group">
          <label class="text-danger" for="soCreatedDate">{{ 'ORDER_DATE' | translate }} *</label>
          <input
            class="form-control"
            id="soCreatedDate"
            formControlName="soCreatedDate"
            [owlDateTimeTrigger]="soCreatedDate"
            [owlDateTime]="soCreatedDate"
            placeholder="{{ 'DELIVERY_DATE' | translate }}"
          />
          <div
            class="text-danger"
            *ngIf="
              salesOrderForm.get('soCreatedDate').touched && salesOrderForm.get('soCreatedDate').hasError('required')
            "
          >
            {{ 'VALIDATIONS.REQUIRED' | translate : { value: 'ORDER_DATE' | translate } }}
          </div>
          <owl-date-time [pickerType]="'calendar'" #soCreatedDate></owl-date-time>
        </div>

        <div class="col form-group">
          <label class="text-danger" for="deliveryDate">{{ 'DELIVERY_DATE' | translate }} *</label>
          <input
            class="form-control"
            id="deliveryDate"
            formControlName="deliveryDate"
            [owlDateTimeTrigger]="deliveryDate"
            [owlDateTime]="deliveryDate"
            placeholder="{{ 'DELIVERY_DATE' | translate }}"
          />
          <div
            class="text-danger"
            *ngIf="
              salesOrderForm.get('deliveryDate').touched && salesOrderForm.get('deliveryDate').hasError('required')
            "
          >
            {{ 'VALIDATIONS.REQUIRED' | translate : { value: 'DELIVERY_DATE' | translate } }}
          </div>
          <owl-date-time [pickerType]="'calendar'" #deliveryDate></owl-date-time>
        </div>

        <div class="col form-group">
          <label class="form-label">{{ 'CUSTOMER' | translate }} <span class="text-danger">*</span></label>
          <ng-select
            appendTo="manage-order"
            formControlName="customerId"
            [placeholder]="'PLACEHOLDERS.SELECT' | translate : { value: 'CUSTOMER' | translate }"
            [items]="customers"
            [hideSelected]="true"
            bindLabel="customerName"
            bindValue="id"
            [virtualScroll]="true"
            [searchable]="true"
            [selectableGroup]="true"
            [loading]="customerResource.loading"
            (search)="customerNameSearch($event.term)"
          >
            <ng-template ng-option-tmp let-item="item"> {{ item.customerName }} </ng-template>
          </ng-select>
          <div
            class="text-danger"
            *ngIf="salesOrderForm.get('customerId')?.hasError('required') && salesOrderForm.get('customerId').touched"
          >
            {{ 'VALIDATIONS.REQUIRED' | translate : { value: 'CUSTOMER' | translate } }}
          </div>
        </div>
      </div>

      <div class="row">
        <div *ngIf="!data.isReturn" class="col form-group">
          <label class="form-label">{{ 'FIELDS.TERMS_AND_CONDITIONS' | translate }} </label>
          <div class="input-group">
            <textarea
              [placeholder]="'PLACEHOLDERS.INPUT' | translate : { value: 'FIELDS.TERMS_AND_CONDITIONS' | translate }"
              formControlName="termAndCondition"
              class="form-control"
            >
            </textarea>
          </div>
        </div>
        <div class="col form-group">
          <label class="form-label">{{ 'FIELDS.NOTES' | translate }} </label>
          <div class="input-group">
            <textarea
              [placeholder]="'PLACEHOLDERS.INPUT' | translate : { value: 'FIELDS.NOTES' | translate }"
              formControlName="note"
              class="form-control"
            >
            </textarea>
          </div>
        </div>
      </div>

      <div *ngIf="!data.isReturn" class="row">
        <div class="col-6 form-group">
          <h6 class="form-label">{{ 'SCAN_BARCODE' | translate }}</h6>
          <input
            class="form-control"
            formControlName="filterBarCodeValue"
            type="text"
            [placeholder]="'PLACEHOLDERS.SCAN' | translate : { value: 'BARCODE' | translate }"
          />
        </div>
      </div>

      <div *ngIf="!data.isReturn" class="row">
        <div class="col text-right mb-3">
          <button type="button" class="btn blue-btn btn-sm" (click)="addAnotherProduct()">
            <i class="fas fa-plus"></i> {{ 'ADD_ANOTHER_PRODUCT' | translate }}
          </button>
        </div>
      </div>

      <div class="row">
        <div class="col table-responsive" formArrayName="salesOrderItems">
          <table mat-table [dataSource]="dataSource" class="table table-bordered table-hover">
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef></th>
              <td
                style="max-width: 2.90vw; min-width: 2.90vw;"
                mat-cell
                [formGroupName]="i"
                *matCellDef="let row; let i = index"
              >
                <div class="d-flex justify-content-center">
                  <button
                    class="btn btn-danger btn-sm"
                    type="button"
                    *ngIf="salesOrderItemsArray?.controls.length !== 1"
                    (click)="removeSalesOrderItem(i)"
                  >
                    <i class="fas fa-trash-alt"></i>
                  </button>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="productId">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'PRODUCT' | translate }}
                <span class="text-danger">*</span>
              </th>
              <td
                style="max-width: 10vw; min-width: 10vw;"
                mat-cell
                [formGroupName]="i"
                *matCellDef="let row; let i = index"
              >
                <mat-select
                  class="form-control"
                  formControlName="productId"
                  (selectionChange)="productNameChange($event.value, i)"
                  [placeholder]="'PLACEHOLDERS.SELECT' | translate : { value: 'PRODUCT' | translate }"
                >
                  <ng-option>
                    <ngx-mat-select-search [searching]="productResource.loading" formControlName="filterProductValue">
                    </ngx-mat-select-search>
                  </ng-option>
                  <mat-option *ngFor="let item of filterProductsMap[i]" [value]="item.id">
                    {{ item.name }}
                  </mat-option>
                </mat-select>

                <div
                  class="text-danger p-1"
                  *ngIf="row.get('productId').touched && row.get('productId').hasError('required')"
                >
                  {{ 'REQUIRED_FIELD' | translate }}
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="warehouseId">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'WAREHOUSE' | translate }}
              </th>
              <td
                style="max-width: 8vw; min-width: 8vw;"
                mat-cell
                [formGroupName]="i"
                *matCellDef="let row; let i = index"
              >
                <mat-select
                  [placeholder]="'PLACEHOLDERS.SELECT' | translate : { value: 'WAREHOUSE' | translate }"
                  class="form-control"
                  formControlName="warehouseId"
                >
                  <mat-option *ngFor="let item of warehouseMap[i]" [value]="item.id">
                    {{ item.name }}
                  </mat-option>
                </mat-select>
              </td>
            </ng-container>
            <ng-container matColumnDef="unitId">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'UNIT' | translate }}
                <span class="text-danger">*</span>
              </th>
              <td
                style="max-width: 10vw; min-width: 10vw;"
                mat-cell
                [formGroupName]="i"
                *matCellDef="let row; let i = index"
              >
                <mat-select
                  [placeholder]="'PLACEHOLDERS.SELECT' | translate : { value: 'UNIT' | translate }"
                  class="form-control"
                  formControlName="unitId"
                  (selectionChange)="unitChange($event, i)"
                >
                  <mat-option *ngFor="let item of unitsMap[i]" [value]="item.id">
                    {{ item.name }}
                  </mat-option>
                </mat-select>

                <div
                  class="text-danger p-1"
                  *ngIf="row.get('unitId').touched && row.get('unitId').hasError('required')"
                >
                  {{ 'REQUIRED_FIELD' | translate }}
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="unitPrice">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'PRICE' | translate }}
                <span class="text-danger">*</span>
              </th>
              <td
                style="max-width: 10vw; min-width: 10vw;"
                mat-cell
                [formGroupName]="i"
                *matCellDef="let row; let i = index"
              >
                <input
                  class="form-control"
                  type="number"
                  min="0"
                  (change)="getAllTotal()"
                  formControlName="unitPrice"
                  [placeholder]="'PLACEHOLDERS.INPUT' | translate : { value: 'FIELDS.UNIT_PRICE' | translate }"
                />
                <div
                  class="text-danger p-1"
                  *ngIf="row.get('unitPrice').touched && row.get('unitId').hasError('unitPrice')"
                >
                  {{ 'REQUIRED_FIELD' | translate }}
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="quantity">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'QUANTITY' | translate }}
                <span class="text-danger">*</span>
              </th>
              <td
                style="max-width: 8vw; min-width: 8vw;"
                mat-cell
                [formGroupName]="i"
                *matCellDef="let row; let i = index"
              >
                <input
                  min="0"
                  [max]="data.isReturn ? row.get('maxQuantity').value : null"
                  class="form-control"
                  type="number"
                  (change)="getAllTotal()"
                  formControlName="quantity"
                  [placeholder]="'PLACEHOLDERS.INPUT' | translate : { value: 'QUANTITY' | translate }"
                />

                <div
                  class="text-danger p-1"
                  *ngIf="row.get('quantity').touched && row.get('quantity').hasError('required')"
                >
                  {{ 'REQUIRED_FIELD' | translate }}
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="subtotalBeforeDiscount">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'SUBTOTAL_BEFORE_DISCOUNT' | translate }}
              </th>
              <td
                style="max-width: 11vw; min-width: 11vw;"
                class="text-right"
                mat-cell
                [formGroupName]="i"
                *matCellDef="let row; let i = index"
              >
                {{ row.get('quantity').value | quantitiesunitprice : row.get('unitPrice').value | customCurrency }}
              </td>
            </ng-container>

            <ng-container matColumnDef="discountPercentage">
              <th mat-header-cell *matHeaderCellDef>{{ 'DISCOUNT' | translate }}%</th>
              <td
                style="max-width: 5.5vw; min-width: 5.5vw;"
                mat-cell
                [formGroupName]="i"
                *matCellDef="let row; let i = index"
              >
                <input
                  max="100"
                  min="0"
                  [maxLength]="3"
                  type="number"
                  class="form-control"
                  formControlName="discountPercentage"
                  (change)="getAllTotal()"
                />
              </td>
            </ng-container>

            <ng-container matColumnDef="subtotalAfterDiscount">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'SUBTOTAL_AFTER_DISCOUNT' | translate }}
              </th>
              <td
                style="max-width: 11vw; min-width: 11vw;"
                class="text-right"
                mat-cell
                [formGroupName]="i"
                *matCellDef="let row; let i = index"
              >
                {{
                  row.get('quantity').value
                    | quantitiesunitprice : row.get('unitPrice').value : row.get('discountPercentage').value
                    | customCurrency
                }}
                <div class="text-secondary" *ngIf="row.get('discountPercentage').value != 0">
                  {{ 'DISCOUNT' | translate }}:

                  <span class="text-danger">
                    {{
                      row.get('quantity').value
                        | quantitiesunitpriceTax : row.get('unitPrice').value : row.get('discountPercentage').value
                        | customCurrency
                    }}
                  </span>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="taxValue">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'TAX' | translate }}
                <span class="text-danger">*</span>
              </th>
              <td
                style="max-width: 10vw; min-width: 10vw;"
                class="p-2"
                mat-cell
                [formGroupName]="i"
                *matCellDef="let row; let i = index"
              >
                <mat-select
                  formControlName="taxValue"
                  [placeholder]="'PLACEHOLDERS.SELECT' | translate : { value: 'TAX' | translate }"
                  class="form-control"
                  (selectionChange)="getAllTotal()"
                  [multiple]="true"
                >
                  <mat-option *ngFor="let tax of taxesMap[i]" [value]="tax.id"
                    >{{ tax.name }} ({{ tax.percentage }}%)
                  </mat-option>
                </mat-select>

                <div class="text-danger p-1" *ngIf="row.get('taxValue').touched && row.get('taxValue').errors">
                  {{ 'REQUIRED_FIELD' | translate }}
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="total">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'TOTAL' | translate }}
                <span class="text-danger">*</span>
              </th>
              <td
                style="max-width: 12vw; min-width: 12vw;"
                class="text-right"
                mat-cell
                [formGroupName]="i"
                *matCellDef="let row; let i = index"
              >
                {{
                  row.value.quantity
                    | quantitiesunitprice
                      : row.value.unitPrice
                      : row.value.discountPercentage
                      : row.value.taxValue
                      : taxesMap[i]
                    | customCurrency
                }}
                <div class="text-secondary" *ngIf="row.value.taxValue">
                  {{ 'TAX' | translate }}:

                  <span class="text-danger">
                    {{
                      row.value.quantity
                        | quantitiesunitpriceTax
                          : row.value.unitPrice
                          : row.value.discountPercentage
                          : row.value.taxValue
                          : taxesMap[i]
                        | customCurrency
                    }}
                  </span>
                </div>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-9 text-right font-weight-bold">{{ 'SUB_TOTAL_BEFORE_DISCOUNT' | translate }}:</div>
        <div class="col-3 text-left font-weight-bold">{{ totalBeforeDiscount | customCurrency }}</div>
      </div>

      <div class="row">
        <div class="col-9 text-right font-weight-bold">{{ 'TOTAL_DISCOUNT' | translate }}:</div>
        <div class="col-3 text-left text-danger font-weight-bold">{{ totalDiscount | customCurrency }}</div>
      </div>

      <div class="row">
        <div class="col-9 text-right font-weight-bold">{{ 'TOTAL_TAX' | translate }}:</div>
        <div class="col-3 text-left font-weight-bold">{{ totalTax | customCurrency }}</div>
      </div>

      <div class="row">
        <div class="col">
          <mat-divider></mat-divider>
        </div>
      </div>

      <div class="row">
        <div class="col-9 text-right font-weight-bold">{{ 'GRAND_TOTAL' | translate }} :</div>
        <div class="col-3 text-left font-weight-bold">{{ grandTotal | customCurrency }}</div>
      </div>

      <div class="row">
        <div class="col form-group">
          <button
            type="button"
            *hasClaim="data.claim"
            (click)="submit()"
            class="col-1 btn btn-success btn-sm m-right-10"
            [disabled]="salesOrderForm.invalid"
          >
            <i class="fas fa-save"></i> {{ 'SAVE' | translate }}
          </button>
          <a type="button" [routerLink]="data.list" class="col-1 btn btn-danger btn-sm">
            <i class="fas fa-times-circle"></i>
            {{ 'CANCEL' | translate }}
          </a>
        </div>
      </div>
    </form>
  `,
  viewProviders: [QuantitiesUnitPricePipe, QuantitiesUnitPriceTaxPipe],
})
export class ManageOrderPage extends BaseComponent {
  salesOrderForm: UntypedFormGroup;

  products: Product[] = [];
  customers: Customer[] = [];
  warehouses: Warehouse[] = [];
  taxes: Tax[] = [];
  units: UnitConversation[] = [];

  salesOrders: SalesOrder[] = [];

  customerResource = new CustomerResourceParameter();
  productResource = new ProductResourceParameter();
  salesOrderResource = new SalesOrderResourceParameter();

  filterProductsMap: { [key: string]: Product[] } = {};
  unitsMap: { [key: string]: UnitConversation[] } = {};
  warehouseMap: { [key: string]: Warehouse[] } = {};
  taxesMap: { [key: string]: Tax[] } = {};

  totalBeforeDiscount: number = 0;
  totalAfterDiscount: number = 0;
  totalDiscount: number = 0;
  grandTotal: number = 0;
  totalTax: number = 0;

  salesOrder: SalesOrder;
  isEdit: boolean = false;

  data: any;

  dataSource = new MatTableDataSource();

  displayedColumns = [
    'action',
    'productId',
    'warehouseId',
    'unitId',
    'unitPrice',
    'quantity',
    'subtotalBeforeDiscount',
    'discountPercentage',
    'subtotalAfterDiscount',
    'taxValue',
    'total',
  ];

  constructor(
    private fb: UntypedFormBuilder,
    private customerService: CustomerService,
    private toastrService: ToastrService,
    private salesOrderService: SalesOrderService,
    private router: Router,
    private productService: ProductService,
    route: ActivatedRoute,
    private quantitiesUnitPricePipe: QuantitiesUnitPricePipe,
    private quantitiesUnitPriceTaxPipe: QuantitiesUnitPriceTaxPipe,
    public translationService: TranslationService,
  ) {
    super(translationService);

    this.customerResource = new CustomerResourceParameter();
    this.productResource = new ProductResourceParameter();

    route.data.subscribe((data) => {
      this.data = {
        title: data.title,
        isReturn: data.isReturn,
        list: data.list,
        claim: data.claimType,
        detail: data.detail,
      };

      // if (!this.data.isReturn || route.snapshot.paramMap.get('id')) {
      //   setTimeout(() => {
      //     document.getElementById('hamburger-menu').click();
      //   }, 0);
      // }

      this.products = data.products;

      this.units = data.units;
      this.warehouses = data.warehouses;
      this.customers = data?.customers?.body;
      this.taxes = data.taxes;
      this.salesOrders = data?.salesOrders?.body;
      this.salesOrder = data?.salesOrder;

      if (this.salesOrder) {
        this.isEdit = true;

        this.salesOrderForm = this.fb.group({
          id: [this.salesOrder?.id],
          orderNumber: [{ value: this.salesOrder?.orderNumber, disabled: true }, { validators: [Validators.required] }],
          deliveryDate: [
            { value: this.salesOrder?.deliveryDate, disabled: this.data.isReturn ? true : false },
            { validators: [Validators.required] },
          ],
          soCreatedDate: [
            { value: this.salesOrder?.soCreatedDate, disabled: this.data.isReturn ? true : false },
            { validators: [Validators.required] },
          ],
          deliveryStatus: [{ value: this.salesOrder?.deliveryStatus, disabled: this.data.isReturn ? true : false }],
          customerId: [
            { value: this.salesOrder?.customerId, disabled: this.data.isReturn ? true : false },
            { validators: [Validators.required] },
          ],
          note: [this.data.isReturn ? null : this.salesOrder?.note],
          filterBarCodeValue: [''],
          termAndCondition: [this.salesOrder?.termAndCondition],
          salesOrderItems: this.fb.array([]),
        });

        this.salesOrder.salesOrderItems.forEach((c) => {
          this.salesOrderItemsArray.push(this.createSalesOrderItemPatch(this.salesOrderItemsArray.length, c));
        });

        this.getAllTotal();
      } else {
        this.salesOrderForm = this.fb.group({
          id: [null],
          orderNumber: [
            { value: route.snapshot?.data['orderNumber']?.orderNumber, disabled: true },
            [Validators.required],
          ],
          deliveryDate: [new Date(), [Validators.required]],
          soCreatedDate: [new Date(), [Validators.required]],
          deliveryStatus: [1],
          customerId: [null, [Validators.required]],
          note: [''],
          filterBarCodeValue: [null],
          termAndCondition: [''],
          salesOrderItems: this.fb.array([]),
        });

        this.salesOrderItemsArray.push(this.createSalesOrderItem(this.salesOrderItemsArray.length));
      }

      this.dataSource.data = this.salesOrderItemsArray.controls;

      if (data.isReturn) {
        this.salesOrderResource.status = SalesOrderStatusEnum.Not_Return;

        this.salesOrderForm.get('customerId').valueChanges.subscribe((c) => {
          this.salesOrderResource.customerId = c;
          this.salesOrderService.getAllSalesOrder(this.salesOrderResource).subscribe((resp) => {
            this.salesOrders = [...resp.body];
          });
        });

        this.salesOrderForm.get('id').valueChanges.subscribe((c) => {
          this.router.navigate([this.routes.salesOrderReturnManage, c]);
        });
      }

      this.sub$.sink = this.salesOrderForm
        .get('filterBarCodeValue')
        .valueChanges.pipe(
          debounceTime(500),
          distinctUntilChanged(),
          switchMap((c) => {
            if (c) {
              this.productResource.barcode = c;
              return this.productService.getProducts(this.productResource);
            }
            {
              return of([]);
            }
          }),
        )
        .subscribe((resp: HttpResponse<Product[]>) => {
          if (resp && resp.headers) {
            if (resp.body.length == 1) {
              if (this.salesOrderItemsArray.length == 1) {
                if (!this.salesOrderItemsArray.controls[0].get('productId').value) {
                  this.removeSalesOrderItem(0);

                  this.dataSource.data = this.salesOrderItemsArray.controls;
                }
              }

              const productId = (resp.body[0] as Product).id;
              let purchaseOrderItems: SalesOrderItem[] = this.salesOrderItemsArray.value;
              var existingProductIndex = purchaseOrderItems.findIndex((c) => c.productId == productId);

              if (existingProductIndex >= 0) {
                let iteamToUpdate = purchaseOrderItems[existingProductIndex];
                this.salesOrderItemsArray
                  .at(existingProductIndex)
                  .get('quantity')
                  .patchValue(iteamToUpdate.quantity + 1);
              } else {
                this.addAnotherProduct();
                const currentIndex = this.salesOrderItemsArray.length - 1;
                this.filterProductsMap[currentIndex.toString()] = [...resp.body];

                this.productNameChange(resp.body[0].id, currentIndex, false);
              }
              this.getAllTotal();
            } else {
              this.toastrService.warning(
                this.translationService.instant('NOT_FOUND', { value: this.translationService.instant('PRODUCT') }),
              );
            }
            this.productResource.barcode = '';
            this.salesOrderForm.get('filterBarCodeValue').patchValue('');
            this.dataSource.data = this.salesOrderItemsArray.controls;
          }
        });
    });
  }

  get salesOrderItemsArray(): UntypedFormArray {
    return <UntypedFormArray>this.salesOrderForm.get('salesOrderItems');
  }

  unitChange(unitId: string, index: number) {
    const unit = this.unitsMap[index].find((c) => c.id === unitId);

    const productId = this.salesOrderItemsArray.controls[index].get('productId').value;
    const product = this.filterProductsMap[index].find((c) => c.id === productId);
    let price = 0;

    if (unit.value) {
      switch (unit.operator) {
        case Operators.Plus:
          price = product.salesPrice + parseFloat(unit.value);
          break;
        case Operators.Minus:
          price = product.salesPrice - parseFloat(unit.value);
          break;
        case Operators.Multiply:
          price = product.salesPrice * parseFloat(unit.value);
          break;
        case Operators.Divide:
          price = product.salesPrice / parseFloat(unit.value);
          break;
      }
      this.salesOrderItemsArray.controls[index].patchValue({
        unitPrice: price,
      });
    } else {
      this.salesOrderItemsArray.controls[index].patchValue({
        unitPrice: product.salesPrice,
        warehouseId: product.warehouseId,
      });
    }
  }

  productNameChange(productId: string, index: number, isFromUI = true) {
    const product = this.filterProductsMap[index].find((c) => c.id === productId);

    this.unitsMap[index] = this.units.filter((c) => c.id == product.unitId || c.parentId == product.unitId);

    if (isFromUI) {
      this.salesOrderItemsArray.controls[index].patchValue({
        unitPrice: '',
      });
    } else {
      this.salesOrderItemsArray.controls[index].patchValue({
        productId: product.id,
        productName: product.name,
      });
    }

    this.salesOrderItemsArray.controls[index].patchValue({
      unitPrice: product.salesPrice,
      unitId: product.unitId,
      unitName: product.unitName,
      warehouseId: product.warehouseId,
    });

    if (product.productTaxes.length) {
      this.salesOrderItemsArray.controls[index].patchValue({
        taxValue: product.productTaxes.map((c) => c.taxId),
      });
    }

    this.getAllTotal();
  }

  customerNameSearch(term: string) {
    of(term)
      .pipe(
        tap(() => (this.customerResource.loading = true)),
        debounceTime(500),
        delay(1000),
        distinctUntilChanged(),
        finalize(() => (this.customerResource.loading = false)),
      )
      .subscribe((term) => {
        if (term != '') {
          this.customerResource.customerName = term;
          this.customerResource.id = null;
          this.customerService.getCustomers(this.customerResource).subscribe((resp) => {
            if (resp && resp.headers) {
              this.customers = [...resp.body];
            }
          });
        } else {
          this.customerService.getCustomers(this.customerResource).subscribe((resp) => {
            if (resp && resp.headers) {
              this.customers = [...resp.body];
            }
          });
        }
        this.customerResource.customerName = null;
        this.customerResource.id = null;
      });
  }

  addAnotherProduct() {
    this.salesOrderItemsArray.push(this.createSalesOrderItem(this.salesOrderItemsArray.length));

    this.dataSource.data = this.salesOrderItemsArray.controls;
  }

  createSalesOrderItemPatch(index: number, salesOrderItem: SalesOrderItem) {
    const taxes = salesOrderItem.salesOrderItemTaxes.map((c) => c.taxId);

    const formGroup = this.fb.group({
      productId: [salesOrderItem.productId, [Validators.required]],
      filterProductValue: [null],
      unitPrice: [salesOrderItem.unitPrice, [Validators.required]],
      maxQuantity: [salesOrderItem.quantity, [Validators.required]],
      quantity: [salesOrderItem.quantity, [Validators.required]],
      taxValue: [taxes],
      unitId: [salesOrderItem.unitId, [Validators.required]],
      warehouseId: [salesOrderItem.warehouseId],
      discountPercentage: [salesOrderItem.discountPercentage],
    });
    this.unitsMap[index] = this.units.filter(
      (c) => c.id == salesOrderItem.product.unitId || c.parentId == salesOrderItem.product.unitId,
    );
    this.taxesMap[index] = this.taxes;
    this.warehouseMap[index] = this.warehouses;
    this.filterProductsMap[index.toString()] = [salesOrderItem.product];

    this.searchFilterProductValue(formGroup, index);

    return formGroup;
  }

  searchFilterProductValue(formGroup: UntypedFormGroup, index: number) {
    formGroup
      .get('filterProductValue')
      .valueChanges.pipe(
        tap(() => (this.productResource.loading = true)),
        debounceTime(750),
        distinctUntilChanged(),
      )
      .subscribe((term) => {
        if (term != '') {
          this.productResource.name = term;
          this.productResource.id = null;
          this.productService.getProducts(this.productResource).subscribe((resp) => {
            if (resp && resp.headers) {
              this.filterProductsMap[index] = [...resp.body];
            }
          });
        } else {
          this.productService.getProducts(this.productResource).subscribe((resp) => {
            if (resp && resp.headers) {
              this.filterProductsMap[index] = [...resp.body];
            }
          });
        }
        this.productResource.loading = false;
        this.productResource.name = null;
      });
  }

  createSalesOrderItem(index: number) {
    const formGroup = this.fb.group({
      productId: [null, [Validators.required]],
      filterProductValue: [null],
      unitPrice: [0, [Validators.required, Validators.min(0)]],
      quantity: [1, [Validators.required, Validators.min(1)]],
      taxValue: [null],
      unitId: [null, [Validators.required]],
      warehouseId: [null],
      discountPercentage: [0, [Validators.min(0)]],
    });
    this.taxesMap[index] = this.taxes;
    this.warehouseMap[index] = this.warehouses;
    this.filterProductsMap[index.toString()] = this.products;

    this.getProductByNameValue(formGroup, index);

    this.searchFilterProductValue(formGroup, index);

    return formGroup;
  }

  getProductByNameValue(formGroup: UntypedFormGroup, index: number) {
    if (this.salesOrder) {
      this.getProducts(index);
    }
    this.sub$.sink = formGroup
      .get('filterProductValue')
      .valueChanges.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((c) => {
          this.productResource.name = c;
          return this.productService.getProducts(this.productResource);
        }),
      )
      .subscribe((resp: HttpResponse<Product[]>) => {
        if (resp && resp.headers) {
          this.filterProductsMap[index.toString()] = [...resp.body];
        }
      });
  }

  getAllTotal() {
    let salesOrderItems = this.salesOrderForm.get('salesOrderItems').value;
    this.totalBeforeDiscount = 0;
    this.grandTotal = 0;
    this.totalDiscount = 0;
    this.totalTax = 0;
    if (salesOrderItems && salesOrderItems.length > 0) {
      salesOrderItems.forEach((so) => {
        if (so.unitPrice && so.quantity) {
          const totalBeforeDiscount =
            this.totalBeforeDiscount + parseFloat(this.quantitiesUnitPricePipe.transform(so.quantity, so.unitPrice));
          this.totalBeforeDiscount = parseFloat(totalBeforeDiscount.toFixed(2));
          const gradTotal =
            this.grandTotal +
            parseFloat(
              this.quantitiesUnitPricePipe.transform(
                so.quantity,
                so.unitPrice,
                so.discountPercentage,
                so.taxValue,
                this.taxesMap[0],
              ),
            );
          this.grandTotal = parseFloat(gradTotal.toFixed(2));
          const totalTax =
            this.totalTax +
            parseFloat(
              this.quantitiesUnitPriceTaxPipe.transform(
                so.quantity,
                so.unitPrice,
                so.discountPercentage,
                so.taxValue,
                this.taxesMap[0],
              ),
            );
          this.totalTax = parseFloat(totalTax.toFixed(2));
          const totalDiscount =
            this.totalDiscount +
            parseFloat(this.quantitiesUnitPriceTaxPipe.transform(so.quantity, so.unitPrice, so.discountPercentage));
          this.totalDiscount = parseFloat(totalDiscount.toFixed(2));
        }
      });
    }
  }

  removeSalesOrderItem(index: number) {
    this.salesOrderItemsArray.removeAt(index);
    this.salesOrderItemsArray.controls.forEach((c: UntypedFormGroup, index: number) => {
      const productId = c.get('productId').value;
      if (productId) {
        this.salesOrder?.salesOrderItems?.map((pi) => {
          if (pi.product.id === productId) {
            if (this.products.find((c) => c.id === productId)) {
              this.getProducts(index);
            } else {
              this.getProducts(index, productId);
            }
          }
        });
      } else {
        this.getProducts(index);
      }
    });

    this.dataSource.data = this.salesOrderItemsArray.controls;

    this.getAllTotal();
  }

  getProducts(index: number, productId?: string) {
    if (this.products.length === 0 || productId) {
      this.productResource.name = '';
      this.productResource.id = productId ? productId : '';
      this.productService.getProducts(this.productResource).subscribe((resp: HttpResponse<Product[]>) => {
        this.products = [...resp.body];
        this.filterProductsMap[index.toString()] = [...resp.body];
      });
    } else {
      this.filterProductsMap[index.toString()] = [...this.products];
    }
  }

  submit() {
    if (this.salesOrderForm.invalid) {
      this.salesOrderForm.markAllAsTouched();
      return;
    }

    if (this.salesOrder && this.salesOrder.salesOrderStatus === SalesOrderStatusEnum.Return) {
      this.toastrService.error(this.translationService.instant('RETURN_SALES_ORDER_CANT_BE_EDIT'));
      return;
    }

    const salesOrder = this.buildSalesOrder();

    if (this.data.isReturn) {
      if (salesOrder.id) {
        this.salesOrderService.updateSalesOrderReturn(salesOrder).subscribe(() => {
          this.toastrService.success(
            this.translationService.instant('TOASTR.ADDED_SUCCESSFULLY', {
              value: this.translationService.instant(this.data.title),
            }),
          );
          this.router.navigate([this.routes.salesOrderReturnList]);
        });
      }
    } else {
      if (salesOrder.id) {
        this.salesOrderService.updateSalesOrder(salesOrder).subscribe((c: SalesOrder) => {
          this.toastrService.success(
            this.translationService.instant('TOASTR.UPDATED_SUCCESSFULLY', {
              value: this.translationService.instant(this.data.title),
            }),
          );
          this.router.navigate([this.data.list]);
        });
      } else {
        this.salesOrderService.addSalesOrder(salesOrder).subscribe((c: SalesOrder) => {
          this.toastrService.success(
            this.translationService.instant('TOASTR.ADDED_SUCCESSFULLY', {
              value: this.translationService.instant(this.data.title),
            }),
          );
          this.router.navigate([this.data.list]);
        });
      }
    }
  }

  buildSalesOrder() {
    const salesOrder: SalesOrder = {
      id: this.salesOrder ? this.salesOrder.id : '',
      orderNumber: this.salesOrderForm.get('orderNumber').value,
      deliveryDate: this.salesOrderForm.get('deliveryDate').value,
      deliveryStatus: DeliveryStatusEnum.UnDelivery,
      isSalesOrderRequest: false,
      soCreatedDate: this.salesOrderForm.get('soCreatedDate').value,
      salesOrderStatus: SalesOrderStatusEnum.Not_Return,
      customerId: this.salesOrderForm.get('customerId').value,
      totalAmount: this.grandTotal,
      totalDiscount: this.totalDiscount,
      totalTax: this.totalTax,
      note: this.salesOrderForm.get('note').value,
      termAndCondition: this.salesOrderForm.get('termAndCondition').value,
      salesOrderItems: [],
    };

    const salesOrderItems = this.salesOrderForm.get('salesOrderItems').value;

    if (salesOrderItems && salesOrderItems.length > 0) {
      salesOrderItems.forEach((so) => {
        salesOrder.salesOrderItems.push({
          discount: parseFloat(
            this.quantitiesUnitPriceTaxPipe.transform(so.quantity, so.unitPrice, so.discountPercentage),
          ),
          discountPercentage: so.discountPercentage,
          productId: so.productId,
          unitId: so.unitId,
          warehouseId: so.warehouseId,
          quantity: so.quantity,
          taxValue: parseFloat(
            this.quantitiesUnitPriceTaxPipe.transform(
              so.quantity,
              so.unitPrice,
              so.discountPercentage,
              so.taxValue,
              this.taxesMap[0],
            ),
          ),
          unitPrice: parseFloat(so.unitPrice),
          salesOrderItemTaxes: so.taxValue
            ? [
                ...so.taxValue.map((element) => {
                  const salesOrderItemTaxes: SalesOrderItemTax = {
                    taxId: element,
                  };
                  return salesOrderItemTaxes;
                }),
              ]
            : [],
        });
      });
    }
    return salesOrder;
  }
}
