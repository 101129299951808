export class AppClaims {
  static DB_STATISTICS = 'DB_STATISTICS';
  static DB_BEST_SELLING_PROS = 'DB_BEST_SELLING_PROS';
  static DB_REMINDERS = 'DB_REMINDERS';
  static DB_LATEST_INQUIRIES = 'DB_LATEST_INQUIRIES';
  static DB_RECENT_SO_SHIPMENT = 'DB_RECENT_SO_SHIPMENT';
  static DB_RECENT_PO_DELIVERY = 'DB_RECENT_PO_DELIVERY';
  static PRO_VIEW_PRODUCTS = 'PRO_VIEW_PRODUCTS';
  static PRO_ADD_PRODUCT = 'PRO_ADD_PRODUCT';
  static PRO_UPDATE_PRODUCT = 'PRO_UPDATE_PRODUCT';
  static PRO_DELETE_PRODUCT = 'PRO_DELETE_PRODUCT';
  static PRO_MANAGE_PRO_CAT = 'PRO_MANAGE_PRO_CAT';
  static PRO_MANAGE_TAX = 'PRO_MANAGE_TAX';
  static PRO_MANAGE_UNIT = 'PRO_MANAGE_UNIT';
  static PRO_MANAGE_BRAND = 'PRO_MANAGE_BRAND';
  static PRO_MANAGE_WAREHOUSE = 'PRO_MANAGE_WAREHOUSE';
  static SUPP_VIEW_SUPPLIERS = 'SUPP_VIEW_SUPPLIERS';
  static SUPP_ADD_SUPPLIER = 'SUPP_ADD_SUPPLIER';
  static SUPP_UPDATE_SUPPLIER = 'SUPP_UPDATE_SUPPLIER';
  static SUPP_DELETE_SUPPLIER = 'SUPP_DELETE_SUPPLIER';
  static CUST_VIEW_CUSTOMERS = 'CUST_VIEW_CUSTOMERS';
  static CUST_ADD_CUSTOMER = 'CUST_ADD_CUSTOMER';
  static CUST_UPDATE_CUSTOMER = 'CUST_UPDATE_CUSTOMER';
  static CUST_DELETE_CUSTOMER = 'CUST_DELETE_CUSTOMER';
  static INQ_VIEW_INQUIRIES = 'INQ_VIEW_INQUIRIES';
  static INQ_ADD_INQUIRY = 'INQ_ADD_INQUIRY';
  static INQ_UPDATE_INQUIRY = 'INQ_UPDATE_INQUIRY';
  static INQ_DELETE_INQUIRY = 'INQ_DELETE_INQUIRY';
  static INQ_MANAGE_INQ_STATUS = 'INQ_MANAGE_INQ_STATUS';
  static INQ_MANAGE_INQ_SOURCE = 'INQ_MANAGE_INQ_SOURCE';
  static INQ_MANAGE_REMINDERS = 'INQ_MANAGE_REMINDERS';
  static POR_VIEW_PO_REQUESTS = 'POR_VIEW_PO_REQUESTS';
  static POR_ADD_PO_REQUEST = 'POR_ADD_PO_REQUEST';
  static POR_UPDATE_PO_REQUEST = 'POR_UPDATE_PO_REQUEST';
  static POR_DELETE_PO_REQUEST = 'POR_DELETE_PO_REQUEST';
  static POR_CONVERT_TO_PO = 'POR_CONVERT_TO_PO';
  static POR_POR_DETAIL = 'POR_POR_DETAIL';
  static POR_GENERATE_INVOICE = 'POR_GENERATE_INVOICE';
  static PO_VIEW_PURCHASE_ORDERS = 'PO_VIEW_PURCHASE_ORDERS';
  static PO_ADD_PO = 'PO_ADD_PO';
  static PO_UPDATE_PO = 'PO_UPDATE_PO';
  static PO_DELETE_PO = 'PO_DELETE_PO';
  static PO_VIEW_PO_DETAIL = 'PO_VIEW_PO_DETAIL';
  static PO_RETURN_PO = 'PO_RETURN_PO';
  static PO_VIEW_PO_PAYMENTS = 'PO_VIEW_PO_PAYMENTS';
  static PO_ADD_PO_PAYMENT = 'PO_ADD_PO_PAYMENT';
  static PO_DELETE_PO_PAYMENT = 'PO_DELETE_PO_PAYMENT';
  static PO_GENERATE_INVOICE = 'PO_GENERATE_INVOICE';
  static POS_POS = 'POS_POS';
  static SO_VIEW_SALES_ORDERS = 'SO_VIEW_SALES_ORDERS';
  static SO_ADD_SO = 'SO_ADD_SO';
  static SO_UPDATE_SO = 'SO_UPDATE_SO';
  static SO_DELETE_SO = 'SO_DELETE_SO';
  static SO_VIEW_SO_DETAIL = 'SO_VIEW_SO_DETAIL';
  static SO_RETURN_SO = 'SO_RETURN_SO';
  static SO_VIEW_SO_PAYMENTS = 'SO_VIEW_SO_PAYMENTS';
  static SO_ADD_SO_PAYMENT = 'SO_ADD_SO_PAYMENT';
  static SO_DELETE_SO_PAYMENT = 'SO_DELETE_SO_PAYMENT';
  static SO_GENERATE_INVOICE = 'SO_GENERATE_INVOICE';
  static INVE_VIEW_INVENTORIES = 'INVE_VIEW_INVENTORIES';
  static INVE_MANAGE_INVENTORY = 'INVE_MANAGE_INVENTORY';
  static EXP_VIEW_EXPENSES = 'EXP_VIEW_EXPENSES';
  static EXP_ADD_EXPENSE = 'EXP_ADD_EXPENSE';
  static EXP_UPDATE_EXPENSE = 'EXP_UPDATE_EXPENSE';
  static EXP_DELETE_EXPENSE = 'EXP_DELETE_EXPENSE';
  static EXP_MANAGE_EXP_CATEGORY = 'EXP_MANAGE_EXP_CATEGORY';
  static REP_PO_REP = 'REP_PO_REP';
  static REP_SO_REP = 'REP_SO_REP';
  static REP_PO_PAYMENT_REP = 'REP_PO_PAYMENT_REP';
  static REP_EXPENSE_REP = 'REP_EXPENSE_REP';
  static REP_SO_PAYMENT_REP = 'REP_SO_PAYMENT_REP';
  static REP_SALES_VS_PURCHASE_REP = 'REP_SALES_VS_PURCHASE_REP';
  static REP_PRO_PP_REP = 'REP_PRO_PP_REP';
  static REP_PRO_SO_REPORT = 'REP_PRO_SO_REPORT';
  static REP_STOCK_REPORT = 'REP_STOCK_REPORT';
  static REP_SUP_PAYMENT_REP = 'REP_SUP_PAYMENT_REP';
  static REP_CUST_PAYMENT_REP = 'REP_CUST_PAYMENT_REP';
  static REP_VIEW_WAR_REP = 'REP_VIEW_WAR_REP';
  static REP_VIEW_PRO_LOSS_REP = 'REP_VIEW_PRO_LOSS_REP';
  static REM_VIEW_REMINDERS = 'REM_VIEW_REMINDERS';
  static REM_ADD_REMINDER = 'REM_ADD_REMINDER';
  static REM_UPDATE_REMINDER = 'REM_UPDATE_REMINDER';
  static REM_DELETE_REMINDER = 'REM_DELETE_REMINDER';
  static ROLES_VIEW_ROLES = 'ROLES_VIEW_ROLES';
  static ROLES_ADD_ROLE = 'ROLES_ADD_ROLE';
  static ROLES_UPDATE_ROLE = 'ROLES_UPDATE_ROLE';
  static ROLES_DELETE_ROLE = 'ROLES_DELETE_ROLE';
  static USR_VIEW_USERS = 'USR_VIEW_USERS';
  static USR_ADD_USER = 'USR_ADD_USER';
  static USR_UPDATE_USER = 'USR_UPDATE_USER';
  static USR_DELETE_USER = 'USR_DELETE_USER';
  static USR_RESET_PWD = 'USR_RESET_PWD';
  static USR_ASSIGN_USR_ROLES = 'USR_ASSIGN_USR_ROLES';
  static USR_ASSIGN_USR_PERMISSIONS = 'USR_ASSIGN_USR_PERMISSIONS';
  static USR_ONLINE_USERS = 'USR_ONLINE_USERS';
  static EMAIL_MANAGE_EMAIL_SMTP_SETTINS = 'EMAIL_MANAGE_EMAIL_SMTP_SETTINS';
  static EMAIL_MANAGE_EMAIL_TEMPLATES = 'EMAIL_MANAGE_EMAIL_TEMPLATES';
  static EMAIL_SEND_EMAIL = 'EMAIL_SEND_EMAIL';
  static SETT_UPDATE_COM_PROFILE = 'SETT_UPDATE_COM_PROFILE';
  static SETT_MANAGE_COUNTRY = 'SETT_MANAGE_COUNTRY';
  static SETT_MANAGE_CITY = 'SETT_MANAGE_CITY';
  static LOGS_VIEW_LOGIN_AUDITS = 'LOGS_VIEW_LOGIN_AUDITS';
  static LOGS_VIEW_ERROR_LOGS = 'LOGS_VIEW_ERROR_LOGS';
  static PI_VIEW_PURCHASE_INVOICES = 'PI_VIEW_PURCHASE_INVOICES';
  static PI_ADD_PI = 'PI_ADD_PI';
  static PI_UPDATE_PI = 'PI_UPDATE_PI';
  static PI_DELETE_PI = 'PI_DELETE_PI';
  static PI_VIEW_PI_DETAIL = 'PI_VIEW_PI_DETAIL';
  static PI_RETURN_PI = 'PI_RETURN_PI';
  static PI_VIEW_PI_PAYMENTS = 'PI_VIEW_PI_PAYMENTS';
  static PI_ADD_PI_PAYMENT = 'PI_ADD_PI_PAYMENT';
  static PI_DELETE_PI_PAYMENT = 'PI_DELETE_PI_PAYMENT';
  static SI_VIEW_SALES_INVOICES = 'SI_VIEW_SALES_INVOICES';
  static SI_ADD_SI = 'SI_ADD_SI';
  static SI_UPDATE_SI = 'SI_UPDATE_SI';
  static SI_DELETE_SI = 'SI_DELETE_SI';
  static SI_VIEW_SI_DETAIL = 'SI_VIEW_SI_DETAIL';
  static SI_RETURN_SI = 'SI_RETURN_SI';
  static SI_VIEW_SI_PAYMENTS = 'SI_VIEW_SI_PAYMENTS';
  static SI_ADD_SI_PAYMENT = 'SI_ADD_SI_PAYMENT';
  static SI_DELETE_SI_PAYMENT = 'SI_DELETE_SI_PAYMENT';

  static CT_ADD_CT = 'CT_ADD_CT';
  static CT_UPDATE_CT = 'CT_UPDATE_CT';
  static CT_DELETE_CT = 'CT_DELETE_CT';
  static CT_VIEW_CT = 'CT_VIEW_CT';

  static ST_ADD_ST = 'ST_ADD_ST';
  static ST_UPDATE_ST = 'ST_UPDATE_ST';
  static ST_DELETE_ST = 'ST_DELETE_ST';
  static ST_VIEW_ST = 'ST_VIEW_ST';
}
