import { inject, Injectable } from '@angular/core';
import { PurchaseInvoiceResourceParameter } from '../../../core/domain-classes/sales-order/sales-order-resource-parameter';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import * as printJS from 'print-js';
import { ActivatedRouteSnapshot } from '@angular/router';
@Injectable({
  providedIn: 'root',
})
export class PurchaseInvoiceService {
  http = inject(HttpClient);

  static getPurchaseInvoiceByIdResolver(route) {
    const id = route.paramMap.get('id') ?? route?.queryParamMap?.get('id');

    if (id == null || id == undefined) {
      return null;
    }

    return inject(PurchaseInvoiceService).getPurchaseInvoiceById(id);
  }

  static getAllResolver() {
    const purchaseInvoiceResource = new PurchaseInvoiceResourceParameter();
    purchaseInvoiceResource.pageSize = 50;
    purchaseInvoiceResource.orderBy = 'piCreatedDate asc';
    purchaseInvoiceResource.invoiceType = 0;

    return inject(PurchaseInvoiceService).getAll(purchaseInvoiceResource);
  }

  printEInvoice(id: string) {
    return this.http.get(`purchaseinvoice/printpurchaseeinvoice/${id}`);
  }

  handlePrintEInvoice(id: string) {
    this.printEInvoice(id).subscribe((res) => {
      const link = environment.apiUrl + res;
      const frame = document.createElement('iframe');
      frame.id = 'invoice';
      frame.setAttribute('src', link);
      frame.setAttribute('style', 'border:none; height:100vh; width: 100vh;');
      frame.setAttribute('frameborder', '0');
      frame.setAttribute('marginheight', '0');
      frame.setAttribute('marginwidth', '0');
      frame.setAttribute('scrolling', 'no');

      document.body.appendChild(frame);

      printJS({
        printable: 'invoice',
        type: 'html',
        maxWidth: 2000,
      });

      document.body.removeChild(frame);
    });
  }

  getPurchaseInvoiceById(id: string) {
    return this.http.get(`purchaseinvoice/${id}`);
  }

  addPayment(body: any) {
    return this.http.post('purchaseinvoicepayment', body);
  }

  deletePayment(id: string) {
    return this.http.delete(`purchaseinvoicepayment/${id}`);
  }

  getAllPayments(id: string) {
    return this.http.get(`purchaseinvoicepayment/${id}`);
  }

  static getPINumberResolver(route: ActivatedRouteSnapshot) {
    return inject(PurchaseInvoiceService).getPINumber(route.data.invoiceType || 0);
  }

  static getByIdResolver(route) {
    if (route.paramMap.get('id') == null || route.paramMap.get('id') == undefined) {
      return null;
    }

    return inject(this).getById(route.paramMap.get('id'));
  }

  getAll(resource: PurchaseInvoiceResourceParameter) {
    const params = new HttpParams()
      .set('InvoiceNumber', resource.invoiceNumber)
      .set('SupplierName', resource.supplierName)
      .set('SiCreatedDate', resource.piCreatedDate ? resource.piCreatedDate.toDateString() : '')
      .set('SupplierId', resource.supplierId || '')
      .set('FromDate', resource.fromDate ? resource.fromDate.toDateString() : '')
      .set('ToDate', resource.toDate ? resource.fromDate.toDateString() : '')
      .set('ProductId', resource.productId || '')
      .set('InvoiceType', resource.invoiceType || 0)
      .set('ProductName', resource.productName || '')
      .set('Skip', resource.skip.toString() || '')
      .set('PageSize', resource.pageSize.toString() || '')
      .set('SearchQuery', resource.searchQuery)
      .set('OrderBy', resource.orderBy)
      .set('Fields', resource.fields);

    return this.http.get('purchaseinvoice', {
      params: params,
      observe: 'response',
    });
  }

  addPurchaseInvoice(purchaseInvoice: any) {
    return this.http.post('purchaseinvoice', purchaseInvoice);
  }

  getPurchaseInvoiceItems(purchaseInvoiceId: string, isReturn: boolean = false) {
    const url = `purchaseInvoice/${purchaseInvoiceId}/items?isReturn=${isReturn}`;
    return this.http.get(url);
  }

  updatePurchaseInvoice(purchaseInvoice: any, isReturn: boolean = false) {
    return this.http.put(`purchaseinvoice/${purchaseInvoice.id}/${isReturn ? 'return' : ''}`, purchaseInvoice);
  }

  deletePurchaseInvoice(id: string) {
    return this.http.delete(`purchaseinvoice/${id}`);
  }

  getById(id: string) {
    return this.http.get(`purchaseinvoice/${id}`);
  }

  getPINumber(invoiceType: number = 0) {
    return this.http.get(`purchaseinvoice/newInvoiceNumber/${invoiceType == 0 ? true : false}`);
  }
}
