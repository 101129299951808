import { Component } from '@angular/core';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BaseComponent } from '../base.component';
import { Router } from '@angular/router';
import { UserAuth } from '@core/domain-classes/user/user-auth';
import { SecurityService } from '@core/security/security.service';
import { ToastrService } from 'ngx-toastr';
import { User } from '@core/domain-classes/user/user';
import { OnlineUser } from '@core/domain-classes/online-user';
import { SignalrService } from '@core/services/signalr.service';
import { TranslationService } from '@core/services/translation.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { AppRoutes } from '../shared/constants';

@Component({
  selector: 'login',
  standalone: true,
  imports: [ReactiveFormsModule, TranslateModule, CommonModule],
  template: `<style>
      .login-logo {
        max-height: 96px;
        max-width: 96px;
      }
    </style>

    <div class="container-login100 bg-body-secondary">
      <form [formGroup]="loginFormGroup" (submit)="submit($event)" class="login100-form validate-form">
        <div class="row">
          <img alt="convert_ico" class="mb-4" src="../../favicon.ico" width="175" />
        </div>
        <div class="row">
          <div class="col form-group">
            <label class="form-label"> {{ 'EMAIL' | translate }} <span class="text-danger">*</span> </label>
            <input
              [placeholder]="'PLACEHOLDERS.INPUT' | translate : { value: 'EMAIL' | translate }"
              class="form-control"
              formControlName="userName"
              type="text"
            />
            <div
              *ngIf="loginFormGroup.get('userName').touched && loginFormGroup.get('userName').errors"
              class="k-tooltip-validation"
            >
              <div class="text-danger" *ngIf="loginFormGroup.get('userName').errors?.required">
                {{ 'VALIDATIONS.REQUIRED' | translate : { value: 'EMAIL' | translate } }}
              </div>
              <div class="text-danger" *ngIf="loginFormGroup.get('userName').errors?.email">
                {{ 'PLEASE_ENTER_VALID_EMAIL' | translate }}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col form-group">
            <label class="text-danger"> {{ 'PASSWORD' | translate }} * </label>
            <div class="input-group">
              <input
                [placeholder]="'PLACEHOLDERS.INPUT' | translate : { value: 'PASSWORD' | translate }"
                [type]="fieldTextType ? 'text' : 'password'"
                class="form-control"
                formControlName="password"
              />
              <div class="input-group-append">
                <span class="input-group-text cursor-pointer">
                  <i
                    (click)="fieldTextType = !fieldTextType"
                    [ngClass]="{ 'fa-eye-slash': !fieldTextType, 'fa-eye': fieldTextType }"
                    class="fa"
                  ></i>
                </span>
              </div>
            </div>
            <div
              *ngIf="loginFormGroup.get('password').touched && loginFormGroup.get('password').errors?.required"
              class="k-tooltip-validation"
            >
              <div class="text-danger" *ngIf="loginFormGroup.get('password').errors?.required">
                {{ 'VALIDATIONS.REQUIRED' | translate : { value: 'PASSWORD' | translate } }}
              </div>
            </div>
          </div>
        </div>
        <div class="row pt-2 pb-2" style="height: 3rem; padding: 0 1rem 0 1rem;">
          <button type="submit" class="col btn-main-large">
            {{ 'LOGIN' | translate }}
          </button>
        </div>
      </form>
    </div> `,
})
export class LoginPage extends BaseComponent {
  loginFormGroup: UntypedFormGroup = this.fb.group({
    userName: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required]],
  });

  userData: User;
  resultMessage: string;
  fieldTextType: boolean = false;
  lat: number;
  lng: number;
  logoImage: string;
  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private securityService: SecurityService,
    private toastr: ToastrService,
    private signalrService: SignalrService,
    public translationService: TranslationService,
    public spinner: NgxSpinnerService,
  ) {
    super(translationService);
  }

  ngOnInit(): void {
    this.companyProfileSubscription();
    navigator.geolocation.getCurrentPosition((position) => {
      this.lat = position.coords.latitude;
      this.lng = position.coords.longitude;
    });
  }

  companyProfileSubscription() {
    this.securityService.companyProfile.subscribe((profile) => {
      if (profile) {
        this.logoImage = profile.logoUrl;
      }
    });
  }

  submit(e: Event) {
    e.preventDefault();

    if (this.loginFormGroup.invalid) {
      this.loginFormGroup.markAllAsTouched();
      return;
    }

    const userObject = Object.assign(this.loginFormGroup.value, { latitude: this.lat, longitude: this.lng });
    this.sub$.sink = this.securityService.login(userObject).subscribe(
      (c: UserAuth) => {
        const userInfo: OnlineUser = {
          email: c.email,
          id: c.id,
          connectionId: null,
          companyId: c.companyId,
        };
        this.signalrService.addUser(userInfo);
        this.router.navigate([AppRoutes.initRoute]);
        setTimeout(() => {
          this.toastr.success(this.translationService.instant('WELLCOME'));
        }, 500);
      },
      () => {
        this.loginFormGroup.reset();
      },
    );
  }
}
