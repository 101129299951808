import { NgModule, inject } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@core/security/auth.guard';
import { AppComponent } from './app.component';
import { LayoutComponent } from './core/layout/layout.component';
import { AppClaims, AppRoutes } from './shared/constants';
import { UserService } from './user/user.service';
import { CompanyProfileService } from './pages/settings/company-profile/company-profile.service';
import { CommonService } from './core/services/common.service';
import { IncomingEInvoiceService } from './pages/incoming-e-invoice/incoming-e-invoice.service';
import { MyProfilePage } from './user/my-profile';
import { ProductService } from './pages/products/products.service';
import { TaxService } from './core/services/tax.service';
import { UnitConversationService } from './pages/products/units/unit.service';
import { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';
import { SecurityService } from './core/security/security.service';
import { LoginPage } from './pages/login';
import { NotificationService } from './notifications/notification.service';

export const authenticatedGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => {
  if (route?.component?.name === LoginPage?.name) {
    if (inject(SecurityService).isLogin()) {
      inject(Router).navigate([AppRoutes.initRoute]);
      return false;
    }
    return true;
  }

  return true;
};

const routes: Routes = [
  {
    path: AppRoutes.login,
    component: LoginPage,
    canActivate: [authenticatedGuard],
  },

  {
    path: AppRoutes.empty,
    component: AppComponent,
    resolve: {
      profile: CompanyProfileService.getCompanyProfileResolver,
      userNotificationCount: CommonService.getUserNotificationCountResolver,
      top10UserNotification: CommonService.getTop10UserNotificationResolver,
    },
    children: [
      {
        path: AppRoutes.empty,
        component: LayoutComponent,
        children: [
          {
            path: AppRoutes.empty,
            canActivate: [AuthGuard],
            canLoad: [AuthGuard],
            loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
          },

          {
            path: AppRoutes.myProfile,
            component: MyProfilePage,
            canActivate: [AuthGuard],
            resolve: {
              profile: UserService.getUserProfileResolver,
            },
          },

          {
            path: AppRoutes.pos,
            loadComponent: () => import('./pages/pos').then((m) => m.PosPage),
            data: { claimType: AppClaims.POS_POS },
            canActivate: [AuthGuard],
            resolve: {
              units: UnitConversationService.getUnitConversationsResolver,
              taxs: TaxService.getTaxesResolver,
              products: ProductService.getProductsResolver,
            },
          },

          {
            path: 'users',
            loadChildren: () => import('./user/user.module').then((m) => m.UserModule),
            canLoad: [AuthGuard],
          },

          {
            path: AppRoutes.loginAudits,
            loadChildren: () => import('./login-audit/login-audit.module').then((m) => m.LoginAuditModule),
            canLoad: [AuthGuard],
          },

          {
            path: AppRoutes.email,
            loadChildren: () => import('./pages/email/email.module').then((m) => m.EmailModule),
            canLoad: [AuthGuard],
          },

          {
            path: AppRoutes.logs,
            loadChildren: () => import('./logs/logs.module').then((m) => m.LogModule),
            canLoad: [AuthGuard],
          },

          {
            path: AppRoutes.suppliers,
            loadChildren: () => import('./pages/suppliers/supplier.module').then((m) => m.SupplierModule),
            canLoad: [AuthGuard],
          },

          {
            path: 'testimonial',
            loadChildren: () => import('./testimonial/testimonial.module').then((m) => m.TestimonialModule),
          },

          {
            path: AppRoutes.customers,
            loadChildren: () => import('./pages/customers/customer.module').then((m) => m.CustomerModule),
          },

          {
            path: AppRoutes.notifications,
            loadComponent: () => import('./notifications/notifications').then((m) => m.NotificationsPage),
            resolve: {
              notifications: NotificationService.getNotificationsResolver,
            },
            canLoad: [AuthGuard],
          },
          {
            path: AppRoutes.reminders,
            loadChildren: () => import('./reminder/reminder.module').then((m) => m.ReminderModule),
            canLoad: [AuthGuard],
          },

          {
            path: AppRoutes.sales,
            loadChildren: () => import('./pages/sales/sales.module').then((m) => m.SalesModule),
            canLoad: [AuthGuard],
          },

          {
            path: AppRoutes.purchase,
            loadChildren: () => import('./pages/purchases/purchase.module').then((m) => m.PurchaseModule),
          },
          {
            path: AppRoutes.settings,
            loadChildren: () => import('./pages/settings/settings.module').then((m) => m.SettingsModule),
            canLoad: [AuthGuard],
          },
          {
            path: AppRoutes.expenses,
            loadChildren: () => import('./expense/expense.module').then((m) => m.ExpenseModule),
            canLoad: [AuthGuard],
          },
          {
            path: 'inquiry',
            loadChildren: () => import('./inquiry/inquiry.module').then((m) => m.InquiryModule),
            canLoad: [AuthGuard],
          },
          {
            path: 'inquiry-status',
            loadChildren: () => import('./inquiry-status/inquiry-status.module').then((m) => m.InquiryStatusModule),
            canLoad: [AuthGuard],
          },
          {
            path: 'inquiry-source',
            loadChildren: () => import('./inquiry-source/inquiry-source.module').then((m) => m.InquirySourceModule),
            canLoad: [AuthGuard],
          },
          {
            path: AppRoutes.products,
            loadChildren: () => import('./pages/products/products.module').then((m) => m.ProductModule),
            canLoad: [AuthGuard],
          },
          {
            path: AppRoutes.inventory,
            loadChildren: () => import('./inventory/inventory.module').then((m) => m.InventoryModule),
            canLoad: [AuthGuard],
          },
          {
            path: AppRoutes.reports,
            loadChildren: () => import('./reports/reports.module').then((m) => m.ReportsModule),
            canLoad: [AuthGuard],
          },
          {
            path: AppRoutes.incomingEInvoice,
            loadComponent: () =>
              import('./pages/incoming-e-invoice/incoming-e-invoice').then((m) => m.IncomingEInvoicePage),
            resolve: {
              invoices: IncomingEInvoiceService.getIncomingInvoiceResolver,
            },
            canLoad: [AuthGuard],
          },
        ],
      },
    ],
  },
  {
    path: AppRoutes.prefix,
    pathMatch: 'prefix',
    redirectTo: AppRoutes.initRoute,
  },
  {
    path: AppRoutes.empty,
    pathMatch: 'full',
    redirectTo: AppRoutes.initRoute,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
