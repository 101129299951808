import { NgModule } from '@angular/core';
import {
  MatPaginatorIntl,
  MatPaginatorDefaultOptions,
  MAT_PAGINATOR_DEFAULT_OPTIONS,
} from '@angular/material/paginator';
import { NgSelectConfig } from '@ng-select/ng-select';
import { TranslateService } from '@ngx-translate/core';

export class IntlNgSelectConfig extends NgSelectConfig {
  constructor(translate: TranslateService) {
    super();

    this.addTagText = translate.instant('NG_SELECT.ADD_TAG_TEXT');
    this.notFoundText = translate.instant('NG_SELECT.NOT_FOUND_TEXT');
    this.loadingText = translate.instant('NG_SELECT.LOADING_TEXT');
    this.placeholder = translate.instant('NG_SELECT.PLACEHOLDER');
    this.typeToSearchText = translate.instant('NG_SELECT.TYPE_TO_SEARCH_TEXT');
    this.bindLabel = 'name';
    this.openOnEnter = true;
    this.bindValue = 'id';
    this.appearance = 'legacy';
  }
}

class IntlMatPaginatorIntl extends MatPaginatorIntl {
  translate: TranslateService;

  getRangeLabel = function (page: any, pageSize: any, length: any) {
    const of = this.translate.instant('MAT.PAGINATOR.OF');
    if (length === 0 || pageSize === 0) {
      return '';
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    return startIndex + 1 + ' - ' + endIndex + ' ' + of + ' ' + length;
  };

  injectTranslateService(translate: TranslateService) {
    this.translate = translate;

    this.translate.onLangChange.subscribe(() => {
      this.translateLabels();
    });

    this.translateLabels();
  }

  translateLabels() {
    this.itemsPerPageLabel = this.translate.instant('MAT.PAGINATOR.ITEMS_PER_PAGE');
    this.nextPageLabel = this.translate.instant('MAT.PAGINATOR.NEXT_PAGE');
    this.previousPageLabel = this.translate.instant('MAT.PAGINATOR.PREVIOUS_PAGE');
    this.firstPageLabel = this.translate.instant('MAT.PAGINATOR.FIRST_PAGE');
    this.lastPageLabel = this.translate.instant('MAT.PAGINATOR.LAST_PAGE');
    this.changes.next();
  }
}

@NgModule({
  providers: [
    {
      provide: MatPaginatorIntl,
      useFactory: (translate) => {
        const service = new IntlMatPaginatorIntl();
        service.injectTranslateService(translate);
        return service;
      },
      deps: [TranslateService],
    },
    {
      provide: MAT_PAGINATOR_DEFAULT_OPTIONS,
      useValue: {
        pageSize: 50,
        pageSizeOptions: [10, 20, 30, 50, 100, 250, 500],
        hidePageSize: false,
        formFieldAppearance: 'legacy',
        showFirstLastButtons: true,
      } as MatPaginatorDefaultOptions,
    },
    {
      provide: NgSelectConfig,
      useClass: IntlNgSelectConfig,
      deps: [TranslateService],
    },
  ],
})
export class I18nMaterialModule {}
