import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SalesInvoiceResourceParameter } from '../../core/domain-classes/sales-order/sales-order-resource-parameter';
import { environment } from '../../../environments/environment';
import * as printJS from 'print-js';
import { ActivatedRouteSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class SalesInvoiceService {
  http = inject(HttpClient);

  static getSINumberResolver(route: ActivatedRouteSnapshot) {
    console.log(route.data.invoiceType);
    return inject(SalesInvoiceService).getSINumber(route.data.invoiceType);
  }

  printEInvoice(id: string) {
    return this.http.get(`salesinvoice/printsaleseinvoice/${id}`);
  }

  static getSalesInvoiceByIdResolver(route) {
    const id = route?.paramMap?.get('id') ?? route?.queryParamMap?.get('id');

    if (!id) {
      return null;
    }

    return inject(SalesInvoiceService).getSalesInvoiceById(id);
  }

  static getAllResolver(route: ActivatedRouteSnapshot) {
    const salesInvoiceResource = new SalesInvoiceResourceParameter();

    salesInvoiceResource.pageSize = 50;
    salesInvoiceResource.orderBy = 'siCreatedDate asc';
    salesInvoiceResource.invoiceType = route.data.invoiceType;

    return inject(SalesInvoiceService).getAll(salesInvoiceResource);
  }

  addPayment(body: any) {
    return this.http.post('salesinvoicepayment', body);
  }

  deletePayment(id: string) {
    return this.http.delete(`salesinvoicepayment/${id}`);
  }

  getAllPayments(id: string) {
    return this.http.get(`salesinvoicepayment/${id}`);
  }

  getSalesInvoiceItems(salesInvoiceId: string, isReturn: boolean = false) {
    const url = `salesInvoice/${salesInvoiceId}/items?isReturn=${isReturn}`;
    return this.http.get(url);
  }

  sendEInvoice(id: string) {
    return this.http.post('salesInvoice/sendEInvoice', {
      invoiceId: id,
    });
  }

  getAll(resource: any) {
    const params = new HttpParams()
      .set('InvoiceNumber', resource.invoiceNumber)
      .set('CustomerName', resource.customerName)
      .set('SiCreatedDate', resource.siCreatedDate ? resource.siCreatedDate.toDateString() : '')
      .set('CustomerId', resource.customerId || '')
      .set('FromDate', resource.fromDate ? resource.fromDate.toDateString() : '')
      .set('ToDate', resource.toDate ? resource.fromDate.toDateString() : '')
      .set('ProductId', resource.productId || '')
      .set('InvoiceType', resource.invoiceType || 0)
      .set('ProductName', resource.productName || '')
      .set('Skip', resource.skip.toString() || '')
      .set('PageSize', resource.pageSize.toString() || '')
      .set('SearchQuery', resource.searchQuery)
      .set('OrderBy', resource.orderBy)
      .set('Fields', resource.fields);

    return this.http.get('salesinvoice', {
      params: params,
      observe: 'response',
    });
  }

  addSalesInvoice(salesInvoice: any) {
    return this.http.post('salesinvoice', salesInvoice);
  }

  updateSalesInvoice(salesInvoice: any, isReturn: boolean = false) {
    const url = `salesinvoice/${salesInvoice.id}/${isReturn ? 'return' : ''}`;

    return this.http.put(url, salesInvoice);
  }

  deleteSalesInvoice(id: string) {
    return this.http.delete(`salesinvoice/${id}`);
  }

  getSalesInvoiceById(id: string) {
    return this.http.get(`salesinvoice/${id}`);
  }

  getSINumber(invoiceType = 0) {
    return this.http.get(`salesinvoice/newInvoiceNumber?isSalesInvoice=${invoiceType == 0 ? true : false}`);
  }

  printEInvoiceHandle(salesInvoiceId: string) {
    this.printEInvoice(salesInvoiceId).subscribe((res: any) => {
      const link = environment.apiUrl + res;
      const frame = document.createElement('iframe');
      frame.id = 'invoice';
      frame.setAttribute('src', link);
      frame.setAttribute('style', 'border:none; height:100vh; width: 100vh;');
      frame.setAttribute('frameborder', '0');
      frame.setAttribute('marginheight', '0');
      frame.setAttribute('marginwidth', '0');
      frame.setAttribute('scrolling', 'no');

      document.body.appendChild(frame);

      printJS({
        printable: 'invoice',
        type: 'html',
        maxWidth: 2000,
      });

      document.body.removeChild(frame);
    });
  }
}
