import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslationService } from '@core/services/translation.service';
import { BaseComponent } from '../../../base.component';

@Component({
  selector: 'add-payment',
  host: { class: 'position-relative' },
  template: `<form [formGroup]="paymentForm" class="modal-content1" (ngSubmit)="submit()">
    <div class="modal-header position-relative">
      <h3>
        {{ 'ADD_PAYMENT' | translate }}
        <ng-container *ngIf="data.data.invoiceNumber ?? data.data.orderNumber">
          (<a [routerLink]="[data.detail, data.data.id]" class="text-info" (click)="dialogRef.close()">{{
            data.data.invoiceNumber ?? data.data.orderNumber
          }}</a
          >)
        </ng-container>
      </h3>
      <div class="cursor-pointer position-absolute" style="top: 2.5px; right: 6px;" (click)="dialogRef.close()">
        <i class="fas fa-times-circle text-danger" style="font-size: 32px;"></i>
      </div>
    </div>

    <div class="modal-body">
      <div class="row">
        <div class="col form-group">
          <label class="form-label">{{ 'PAYMENT_DATE' | translate }} <span class="text-danger">*</span></label>
          <input
            class="form-control"
            formControlName="paymentDate"
            [owlDateTimeTrigger]="paymentDate"
            [owlDateTime]="paymentDate"
            [placeholder]="'PLACEHOLDERS.SELECT' | translate : { value: 'PAYMENT_DATE' | translate }"
          />
          <div
            class="text-danger"
            *ngIf="paymentForm.get('paymentDate').touched && paymentForm.get('paymentDate').hasError('required')"
          >
            {{ 'VALIDATIONS.REQUIRED' | translate : { value: 'PAYMENT_DATE' | translate } }}
          </div>
          <owl-date-time [pickerType]="'calendar'" #paymentDate></owl-date-time>
        </div>

        <div class="col form-group">
          <label class="form-label">{{ 'REFERENCE_NUMBER' | translate }}</label>
          <input
            [placeholder]="'PLACEHOLDERS.INPUT' | translate : { value: 'REFERENCE_NUMBER' | translate }"
            formControlName="referenceNumber"
            type="text"
            class="form-control"
          />
        </div>
      </div>

      <div class="row">
        <div class="col form-group">
          <label class="form-label">{{ 'AMOUNT' | translate }} <span class="text-danger">*</span></label>
          <input
            [placeholder]="'PLACEHOLDERS.INPUT' | translate : { value: 'AMOUNT' | translate }"
            formControlName="amount"
            type="text"
            type="number"
            min="0"
            class="form-control"
          />
          <div class="text-danger" *ngIf="paymentForm.get('amount').hasError('required')">
            {{ 'VALIDATIONS.REQUIRED' | translate : { value: 'AMOUNT' | translate } }}
          </div>
          <div
            class="text-danger"
            *ngIf="paymentForm.get('amount').touched && paymentForm.get('amount').hasError('min')"
          >
            {{ 'VALIDATIONS.MUST_BE_TOP_ZERO' | translate : { value: 'AMOUNT' | translate } }}
          </div>
        </div>

        <div class="col form-group">
          <label class="form-label"> {{ 'PAYING_BY' | translate }} <span class="text-danger">*</span></label>
          <ng-select
            [placeholder]="'PLACEHOLDERS.SELECT' | translate : { value: 'PAYING_BY' | translate }"
            appendTo="add-payment"
            formControlName="paymentMethod"
          >
            <ng-option *ngFor="let paymentMethod of paymentMethods" [value]="paymentMethod.id">
              {{ paymentMethod.id | paymentmethod }}
            </ng-option>
          </ng-select>
          <div
            class="text-danger"
            *ngIf="paymentForm.get('paymentMethod').touched && paymentForm.get('paymentMethod').hasError('required')"
          >
            {{ 'VALIDATIONS.REQUIRED' | translate : { value: 'PAYING_BY' | translate } }}
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col form-group">
          <label class="form-label">
            {{ 'NOTE' | translate }}
          </label>
          <textarea
            [placeholder]="'PLACEHOLDERS.INPUT' | translate : { value: 'NOTE' | translate }"
            formControlName="note"
            class="form-control"
          >
          </textarea>
        </div>
      </div>
    </div>

    <div class="modal-footer">
      <div class="row w-100">
        <button class="col btn btn-success btn-sm m-right-10 w-100" type="submit">
          <i class="fas fa-save"></i>
          {{ 'BUTTONS.SAVE' | translate }}
        </button>
        <button class="col btn btn-danger btn-sm w-100" (click)="dialogRef.close()">
          <i class="fas fa-times-circle"></i>
          {{ 'CANCEL' | translate }}
        </button>
      </div>
    </div>
  </form> `,
})
export class AddPaymentComponent extends BaseComponent {
  paymentForm: UntypedFormGroup = this.fb.group({
    id: [this.data.data.id],
    paymentDate: [new Date(), [Validators.required]],
    referenceNumber: [''],
    amount: [
      this.data.data.totalAmount - this.data.data.totalPaidAmount,
      { validators: [Validators.required, Validators.min(0.00000000000000000001)] },
    ],
    note: [''],
    paymentMethod: [0, Validators.required],
  });

  constructor(
    public dialogRef: MatDialogRef<AddPaymentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder,
    public translationService: TranslationService,
  ) {
    super(translationService);
  }

  submit() {
    if (!this.paymentForm.valid) {
      this.paymentForm.markAllAsTouched();
      return;
    }

    this.data.handle(this.paymentForm.getRawValue(), this.dialogRef);
  }
}
