export abstract class ResourceParameter {
  fields = '';
  orderBy = '';
  searchQuery = '';
  pageSize = 50;
  skip = 0;
  name = '';
  totalCount = 0;

  loading: boolean = false;
}
