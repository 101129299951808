export const customerSupplierTransactions = [
  {
    id: 0,
    name: 'CasheCredit',
  },
  {
    id: 1,
    name: 'CasheDebit',
  },
  {
    id: 2,
    name: 'OpenCredit',
  },
  {
    id: 3,
    name: 'OpenDebit',
  },
  {
    id: 4,
    name: 'DevirCredit',
  },
  {
    id: 5,
    name: 'DevirDebit',
  },
];
