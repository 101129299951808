import { ResourceParameter } from '../resource-parameter';
import { SalesOrderStatusEnum } from './sales-order-status';

export class SalesOrderResourceParameter extends ResourceParameter {
  orderNumber?: string = '';
  customerName?: string = '';
  sOCreatedDate?: Date;
  customerId?: string = '';
  fromDate?: Date;
  toDate?: Date;
  productId: string;
  isSalesOrderRequest: boolean = false;
  status?: SalesOrderStatusEnum = SalesOrderStatusEnum.All;
  productName?: string;
  pageSize: number = 50;
  orderBy: string = 'soCreatedDate asc';
}

export class SalesInvoiceResourceParameter extends ResourceParameter {
  invoiceNumber?: string = '';
  customerName?: string = '';
  siCreatedDate?: Date;
  customerId?: string = '';
  fromDate?: Date;
  toDate?: Date;
  invoiceType?: number;
  productId: string;
  productName?: string;
}

export class PurchaseInvoiceResourceParameter extends ResourceParameter {
  invoiceNumber?: string = '';
  supplierName?: string = '';
  piCreatedDate?: Date;
  supplierId?: string = '';
  fromDate?: Date;
  toDate?: Date;
  invoiceType?: number;
  productId: string;
  productName?: string;
}
