import { Component } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ClonerService } from '../services/clone.service';
import { SecurityService } from '../security/security.service';
import { TranslationService } from '../services/translation.service';
import { SalesInvoiceService } from '../../pages/sales/sales-invoice.service';
import { TransactionService } from '../services/transaction.service';
import { SalesOrderService } from '../../pages/sales/sales-order.service';
import { PurchaseInvoiceService } from '../../pages/purchases/purchase-invoice/purchase-invoice.service';
import { CustomerService } from '../../pages/customers/customer.service';
import { SupplierService } from '../../pages/suppliers/supplier.service';

@Component({
  selector: 'detail',
  template: `
    <div class="page-header-main">
      <div class="row align-items-center justify-content-between">
        <div class="col-md-auto col-sm-auto content-header">
          <h1>
            {{ 'TITLES.DETAILS' | translate : { value: data.title | translate } }}
            (<a [routerLink]="data.manage ? [data.manage, salesInvoice.id] : null" class="text-info">{{
              salesInvoice.invoiceNumber ?? salesInvoice.orderNumber
            }}</a
            >)
          </h1>
        </div>
        <div class="col-md-auto col-sm-auto">
          <button
            *ngIf="salesInvoice?.isEInvoice || salesInvoice?.isEArchive"
            class="btn blue-btn btn-sm"
            (click)="
              data.isSales
                ? salesInvoiceService.printEInvoiceHandle(salesInvoice?.id)
                : purchaseInvoiceService.handlePrintEInvoice(salesInvoice?.id)
            "
          >
            <i class="fas fa-receipt"></i> {{ 'GENERATE_INVOICE' | translate }}
          </button>
        </div>
      </div>
    </div>

    <ng-container *ngIf="salesInvoice">
      <div class="row justify-content-between">
        <div class="col-4 form-group">
          <h4 class="detai-header">
            {{ (data.isSales ? 'CUSTOMER_INFORMATIONS' : 'SUPPLIER_INFORMATIONS') | translate }}
          </h4>

          <div class="pt-2">
            <a
              class="detail-header text-info"
              [routerLink]="[
                data.isSales ? routes.customersDetail : routes.suppliersDetail,
                data.isSales ? salesInvoice.customer.id : salesInvoice.supplier.id
              ]"
            >
              {{ salesInvoice?.customer?.customerName ?? salesInvoice?.supplier?.supplierName }}
              <ng-container *ngIf="salesInvoice?.customer?.contactPerson ?? salesInvoice?.supplier?.contactPerson"
                >-</ng-container
              >
              {{ salesInvoice?.customer?.contactPerson ?? salesInvoice?.supplier?.contactPerson }}
            </a>
          </div>
          <div class="detail-header pt-3">
            {{ 'ADDRESS' | translate }}:
            <span class="detail-item">{{
              salesInvoice?.customer?.address ?? salesInvoice?.supplier?.supplierAddress?.address
            }}</span>
          </div>

          <ng-container *ngIf="salesInvoice?.customer?.mobileNo ?? salesInvoice?.supplier?.mobileNo">
            <div class="detail-header pt-3">
              {{ 'PHONE_NUMBER' | translate }}:
              <span class="detail-item">
                {{ salesInvoice?.customer?.mobileNo ?? salesInvoice?.supplier?.mobileNo }}</span
              >
            </div>
          </ng-container>

          <ng-container *ngIf="salesInvoice?.customer?.email ?? salesInvoice?.supplier?.email">
            <div class="detail-header pt-1">
              {{ 'EMAIL' | translate }}:
              <a href="mailto:{{ salesInvoice?.supplier?.email }}" class="detail-item">
                {{ salesInvoice?.customer?.email ?? salesInvoice?.supplier?.email }}</a
              >
            </div>
          </ng-container>
        </div>

        <div class="col-4 form-group">
          <h4 class="detai-header">{{ 'COMPANY_INFORMATIONS' | translate }}</h4>

          <div class="pt-2">
            <a [routerLink]="[routes.settingsCompanyProfile]" class="detail-header text-info">{{
              companyProfile.companyName
            }}</a>
          </div>

          <div class="detail-header pt-3">
            {{ 'ADDRESS' | translate }}:
            <span class="detail-item">{{ companyProfile.address }}</span>
          </div>

          <div class="detail-header pt-3">
            {{ 'PHONE_NUMBER' | translate }}: <span class="detail-item"> {{ companyProfile.phone }}</span>
          </div>

          <ng-container *ngIf="companyProfile.email">
            <div class="detail-header pt-1">
              {{ 'EMAIL' | translate }}:
              <a href="mailto:{{ companyProfile.email }}" class="detail-item"> {{ companyProfile.email }}</a>
            </div>
          </ng-container>
        </div>
        <div class="col-3 form-group">
          <h4 class="detai-header">{{ 'REFERENCE' | translate }}</h4>

          <div class="pt-2">
            <a [routerLink]="[data.manage, salesInvoice.id]" class="detail-header text-info">{{
              salesInvoice.invoiceNumber ?? salesInvoice.orderNumber
            }}</a>
          </div>

          <div class="detail-header pt-2">
            {{ 'DATE' | translate }}:
            <div class="badge badge-date detail-item">{{ salesInvoice[data.createdDate] | utcToLocalTime }}</div>
          </div>

          <div class="detail-header pt-2">
            {{ 'PAYMENT_STATUS' | translate }}:
            <div
              class="badge detail-item"
              [ngClass]="{
                'badge-success': salesInvoice?.paymentStatus == 0,
                'badge-danger': salesInvoice?.paymentStatus == 1,
                'badge-warning': salesInvoice?.paymentStatus == 2
              }"
            >
              {{ salesInvoice?.paymentStatus | paymentStatus }}
            </div>
          </div>
        </div>
      </div>

      <div class="page-header-main">
        <div class="row align-items-center justify-content-between">
          <div class="col-md-auto col-sm-auto">
            <div class="content-header">
              <h1>{{ 'TITLES.ITEMS' | translate : { value: data.title | translate } }}</h1>
            </div>
          </div>
        </div>
      </div>

      <div class="table-responsive">
        <table class="table table-bordered">
          <thead class="head">
            <tr>
              <th class="text-center" style="width: 1%">{{ '#' | translate }}</th>
              <th style="width: 20%">{{ 'DESCRIPTION' | translate }}</th>
              <th style="width: 3%">{{ 'UNIT_PRICE' | translate }}</th>
              <th style="width: 2%">{{ 'QUANTITY' | translate }}</th>
              <th style="width: 3%">{{ 'DISCOUNT' | translate }}</th>
              <th style="width: 5%">{{ 'TAX' | translate }}</th>
              <th style="width: 10%">{{ 'TOTAL_AMOUNT' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let salesItem of salesInvoiceItems; let i = index">
              <ng-container *ngIf="salesItem.status == 0">
                <td style="text-align: center">{{ i + 1 }}</td>
                <td>{{ salesItem.productName }}</td>

                <td class="text-right">{{ salesItem.unitPrice | customCurrency }}</td>

                <td class="text-right">
                  <div class="badge" [ngClass]="transactionService.getBalanceFormat(salesItem.quantity, true).color">
                    {{ salesItem.quantity }}
                    <span style="font-size: 12px">({{ salesItem?.unitConversation?.name }})</span>
                  </div>
                </td>

                <td class="text-right">
                  <div class="badge" [ngClass]="transactionService.getBalanceFormat(salesItem.discount, true).color">
                    {{ salesItem.discount | customCurrency }}
                  </div>
                </td>

                <td class="text-right">
                  <div class="badge" [ngClass]="transactionService.getBalanceFormat(salesItem.taxValue, true).color">
                    {{ salesItem.taxValue | customCurrency }}
                    <span
                      style="font-size: 10px"
                      *ngFor="
                        let tax of salesItem.salesInvoiceItemTaxes ??
                          salesItem.salesOrderItemTaxes ??
                          salesItem.purchaseInvoiceItemTaxes ??
                          salesItem.purchaseOrderItemTaxes;
                        let last = last
                      "
                    >
                      {{ tax.taxName }}({{ tax.taxPercentage }}%) <span *ngIf="!last">, </span>
                    </span>
                  </div>
                </td>

                <td class="text-right">
                  <div
                    class="badge"
                    [ngClass]="
                      transactionService.getBalanceFormat(
                        salesItem?.quantity * salesItem?.unitPrice - salesItem.discount + salesItem.taxValue,
                        true
                      ).color
                    "
                  >
                    {{
                      salesItem?.quantity * salesItem?.unitPrice - salesItem.discount + salesItem.taxValue
                        | customCurrency
                    }}
                  </div>
                </td>
              </ng-container>
            </tr>

            <tr class="bg-warning1" *ngFor="let salesItem of salesInvoiceReturnsItems; let i = index">
              <td style="text-align: center">{{ i + 1 }}</td>
              <td>{{ salesItem.productName }}</td>

              <td class="text-right">{{ salesItem.unitPrice | customCurrency }}</td>

              <td class="text-right">
                <div class="badge" [ngClass]="transactionService.getBalanceFormat(-1 * salesItem.quantity, true).color">
                  {{ salesItem.quantity }}
                  <span style="font-size: 12px">({{ salesItem?.unitConversation?.name }})</span>
                </div>
              </td>

              <td class="text-right">
                <div class="badge" [ngClass]="transactionService.getBalanceFormat(-1 * salesItem.discount, true).color">
                  {{ salesItem.discount | customCurrency }}
                </div>
              </td>

              <td class="text-right">
                <div class="badge" [ngClass]="transactionService.getBalanceFormat(-1 * salesItem.taxValue, true).color">
                  {{ salesItem.taxValue | customCurrency }}
                  <span
                    style="font-size: 10px"
                    *ngFor="
                      let tax of salesItem.salesInvoiceItemTaxes ??
                        salesItem.salesOrderItemTaxes ??
                        salesItem.purchaseInvoiceItemTaxes ??
                        salesItem.purchaseOrderItemTaxes;
                      let last = last
                    "
                  >
                    {{ tax.taxName }}({{ tax.taxPercentage }}%) <span *ngIf="!last">, </span>
                  </span>
                </div>
              </td>

              <td class="text-right">
                <div
                  class="badge"
                  [ngClass]="
                    transactionService.getBalanceFormat(
                      -1 * (salesItem?.quantity * salesItem?.unitPrice - salesItem.discount + salesItem.taxValue),
                      true
                    ).color
                  "
                >
                  {{
                    salesItem?.quantity * salesItem?.unitPrice - salesItem.discount + salesItem.taxValue
                      | customCurrency
                  }}
                </div>
              </td>
            </tr>

            <tr>
              <td colspan="3" class="text-right total">{{ 'TOTAL' | translate }}</td>
              <td class="text-right total">
                <div *ngFor="let item of totalQuantityGroupByUnit">
                  <div class="badge mt-2" [ngClass]="item.class">
                    {{ item.quantity }} <span style="font-size: 10px"> ({{ item.unitName }}) </span>
                  </div>
                </div>
              </td>
              <td class="text-right total">
                {{ salesInvoice.totalDiscount | customCurrency }}
              </td>
              <td class="text-right total">{{ salesInvoice.totalTax | customCurrency }}</td>
              <td class="total text-right">{{ salesInvoice.totalAmount | customCurrency }}</td>
            </tr>
            <tr>
              <td colspan="6" class="text-right total">{{ 'PAID' | translate }}</td>
              <td class="total text-right">{{ salesInvoice.totalPaidAmount | customCurrency }}</td>
            </tr>
            <tr>
              <td colspan="6" class="text-right total">{{ 'BALANCE' | translate }}</td>
              <td class="total text-right">
                {{ salesInvoice.totalAmount - salesInvoice.totalPaidAmount | customCurrency }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        *ngIf="
          salesInvoice?.salesInvoicePayments?.length > 0 ||
          salesInvoice.salesOrderPayments?.length > 0 ||
          salesInvoice?.purchaseInvoicePayments?.length > 0 ||
          salesInvoice?.purchaseOrderPayments?.length > 0
        "
      >
        <div class="page-header-main">
          <div class="row align-items-center justify-content-between">
            <div class="col-md-auto col-sm-auto">
              <div class="content-header">
                <h1>{{ 'TITLES.PAYMENTS' | translate : { value: data.title | translate } }}</h1>
              </div>
            </div>
          </div>
        </div>

        <div class="table-responsive">
          <table class="table table-bordered">
            <thead class="head">
              <tr>
                <th class="text-center w-1">#</th>
                <th class="w-8">{{ 'PAYMENT_DATE' | translate }}</th>
                <th>{{ 'REFERENCE_NUMBER' | translate }}</th>
                <th>{{ 'NOTE' | translate }}</th>
                <th>{{ 'PAYMENT_METHOD' | translate }}</th>
                <th>{{ 'AMOUNT' | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let salesItemPayments of salesInvoice?.salesInvoicePayments ??
                    salesInvoice.salesOrderPayments ??
                    salesInvoice?.purchaseInvoicePayments ??
                    salesInvoice?.purchaseOrderPayments;
                  let i = index
                "
              >
                <td class="text-center w-1">
                  {{ i + 1 }}
                </td>
                <td class="head w-8">
                  <div class="badge badge-date">
                    {{ salesItemPayments.paymentDate | utcToLocalTime }}
                  </div>
                </td>
                <td class="head">{{ salesItemPayments.referenceNumber }}</td>
                <td class="head">{{ salesItemPayments.note }}</td>
                <td class="head">
                  <div class="badge badge-warning">
                    {{ salesItemPayments.paymentMethod | paymentmethod }}
                  </div>
                </td>
                <td class="head text-right">{{ salesItemPayments.amount | customCurrency }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div *ngIf="salesInvoice.termAndCondition">
        <div class="page-header-main">
          <div class="row align-items-center justify-content-between">
            <div class="col-md-auto col-sm-auto">
              <div class="content-header">
                <h1>{{ 'TERMS_AND_CONDITION' | translate }}</h1>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <b class="col form-group" [innerText]="salesInvoice?.termAndCondition"></b>
        </div>
      </div>
      <div class="mt-2 mb-2" *ngIf="salesInvoice.note">
        <div class="page-header-main">
          <div class="row align-items-center justify-content-between">
            <div class="col-md-auto col-sm-auto">
              <div class="content-header">
                <h1>{{ 'NOTE' | translate }}</h1>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <b class="col form-group" [innerText]="salesInvoice?.note"> </b>
        </div>
      </div>
      <div class="row">
        <div class="col mb-2">
          <a type="button" [routerLink]="data.list" class="col-1 btn btn-danger btn-sm">
            <i class="fas fa-times-circle"></i>
            {{ 'BUTTONS.BACK' | translate }}
          </a>
        </div>
      </div>
    </ng-container>
  `,
})
export class DetailPage extends BaseComponent {
  companyProfile: any;
  salesInvoice: any = null;
  salesInvoiceItems: any[];
  salesInvoiceReturnsItems: any[];

  totalQuantityGroupByUnit = [];

  data: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private clonerService: ClonerService,
    private securityService: SecurityService,
    public translationService: TranslationService,
    public router: Router,
    public salesInvoiceService: SalesInvoiceService,
    public salesOrderService: SalesOrderService,
    public purchaseInvoiceService: PurchaseInvoiceService,
    public transactionService: TransactionService,
    public customerService: CustomerService,
    public supplierService: SupplierService,
  ) {
    super(translationService);

    // setTimeout(() => {
    //   document.getElementById('hamburger-menu').click();
    // }, 0);

    this.securityService.companyProfile.subscribe((data) => {
      this.companyProfile = data;
    });

    this.sub$.sink = this.activatedRoute.data.subscribe((data) => {
      this.data = {
        isSales: data.isSales,
        title: data.title,
        manage: data.manage,
        list: data.list,
        createdDate: data.createdDate,
      };

      this.salesInvoice = data.salesInvoice ?? data.salesOrder ?? data.purchaseInvoice ?? data.purchaseOrder;

      this.salesInvoice = this.clonerService.deepClone<any>(this.salesInvoice);

      if (this.data.isSales) {
        this.customerService.getCustomer(this.salesInvoice.customer.id).subscribe((data) => {
          this.salesInvoice.customer = data;
        });
      } else {
        this.supplierService.getSupplier(this.salesInvoice.supplier.id).subscribe((data) => {
          this.salesInvoice.supplier = data;
        });
      }

      this.salesInvoice.totalQuantity =
        this.salesInvoice?.salesInvoiceItems ??
        this.salesInvoice?.salesOrderItems ??
        this.salesInvoice?.purchaseInvoiceItems ??
        this.salesInvoice?.purchaseOrderItems
          .map((item) => (item.status == 1 ? -1 * item.quantity : item.quantity))
          .reduce((prev, next) => prev + next);

      this.salesInvoiceItems =
        this.salesInvoice?.salesInvoiceItems?.filter((c) => c.status == 0) ??
        this.salesInvoice?.salesOrderItems?.filter((c) => c.status == 0) ??
        this.salesInvoice?.purchaseInvoiceItems?.filter((c) => c.status == 0) ??
        this.salesInvoice?.purchaseOrderItems?.filter((c) => c.status == 0);

      this.salesInvoiceReturnsItems =
        this.salesInvoice?.salesInvoiceItems?.filter((c) => c.status == 1) ??
        this.salesInvoice?.salesOrderItems?.filter((c) => c.status == 1) ??
        this.salesInvoice?.purchaseInvoiceItems?.filter((c) => c.status == 1) ??
        this.salesInvoice?.purchaseOrderItems?.filter((c) => c.status == 1);

      this.totalQuantityGroupByUnit = this.salesInvoiceItems
        .concat(this.salesInvoiceReturnsItems)
        .reduce((acc, curr) => {
          acc.push({ unitName: curr.unitConversation.name, quantity: curr.quantity, status: curr.status });
          return acc;
        }, [])
        .map((item) => {
          return {
            quantity: item.quantity,
            unitName: item.unitName,
            class: item.status == 0 ? 'badge-success' : 'badge-danger',
          };
        });
    });
  }
}
