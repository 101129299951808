import { Injectable, inject } from '@angular/core';
import { Warehouse } from '@core/domain-classes/warehouse';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Observable } from 'rxjs';
import { WarehouseInventory } from '../domain-classes/warehouseInventory';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class WarehouseService extends EntityCollectionServiceBase<Warehouse> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory, private http: HttpClient) {
    super('Warehouse', serviceElementsFactory);
  }

  static getWarehousesResolver() {
    return inject(WarehouseService).getAll();
  }

  getWarehouseProduct(warehouseId: string): Observable<WarehouseInventory[]> {
    return this.http.get<WarehouseInventory[]>(`warehouse/${warehouseId}/items`);
  }
}
