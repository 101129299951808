import { Injectable, NgModule } from '@angular/core';
import { Observable, throwError, timer } from 'rxjs';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpParams,
  HttpContextToken,
} from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { environment } from '@environments/environment';
import { catchError, finalize, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppSettings } from '../../app-settings';
import { TranslationService } from '../../core/services/translation.service';
import { SecurityService } from '../../core/security/security.service';

export const PASS_SPINNER = new HttpContextToken<boolean>(() => false);
const SPINNER_THRESHOLD = 500;

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  constructor(
    private toastrService: ToastrService,
    private translate: TranslationService,
    private spinner: NgxSpinnerService,
    private appSettings: AppSettings,
    private securityService: SecurityService,
  ) {}

  errorMessages: { [key: string]: string } = {
    'Product Category not Deleted because it is use in product.': 'TOASTR.SERVER.PRODUCT_CATEGORY_IN_USE',
    'Product Category Already Exist.': 'TOASTR.SERVER.PRODUCT_CATEGORY_ALREADY_EXIST',
    'You can not delete default Setting.': 'TOASTR.SERVER.CAN_NOT_DELETE_DEFAULT_SETTING',
    'UserName Or Password is InCorrect.': 'TOASTR.SERVER.USERNAME_OR_PASSWORD_INCORRECT',
    'Sales Invoice Number is already Exists.': 'TOASTR.SERVER.SALES_INVOICE_NUMBER_ALREADY_EXISTS',
    "Sales Invoice can't edit becuase it's already Return.": 'TOASTR.SERVER.SALES_INVOICE_ALREADY_RETURN',
  };

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.lastIndexOf('i18n') > -1) {
      return next.handle(req);
    }

    let started = false;

    if (req.params.keys().length > 0) {
      const modifiedParams = {};

      req.params.keys().forEach((key) => {
        const value = req.params.get(key);

        modifiedParams[key] =
          value === null || value === undefined || value === 'null' || value === 'undefined' ? '' : value;
      });

      req = req.clone({
        params: new HttpParams({ fromObject: modifiedParams }),
      });
    }

    const url = req.url.lastIndexOf('api') > -1 ? req.url : 'api/' + req.url;

    req = req.clone({
      headers: req.headers.set('Authorization', 'Bearer ' + localStorage.getItem('access_token')),
      url: `${environment.apiUrl}${url}`,
    });

    return next.handle(req).pipe(
      tap(() => {
        if (
          !started &&
          this.appSettings.appLoaded.value &&
          this.appSettings.navigationCount.value == 0 &&
          !req.context.has(PASS_SPINNER)
        ) {
          started = true;

          timer(SPINNER_THRESHOLD).subscribe(() => {
            if (started) {
              this.spinner.show();
            }
          });
        }
      }),
      catchError((error: HttpErrorResponse) => {
        const errorMessage: string | string[] = error.error instanceof ErrorEvent ? error.error.message : error.error;

        switch (error.status) {
          case 401:
            if (errorMessage) {
              errorMessage instanceof Array
                ? errorMessage.forEach((message) => {
                    this.toastrService.error(this.translate.instant(this.errorMessages[message] || message));
                  })
                : this.toastrService.error(this.translate.instant(errorMessage as string));
            } else {
              this.toastrService.error(this.translate.instant('TOASTR.UNAUTHORIZED'));
            }

            this.securityService.logout();
            break;
          case 408:
            this.toastrService.warning(this.translate.instant('TOASTR.TIMEOUT'));
            break;
          case 409:
            errorMessage instanceof Array
              ? errorMessage.forEach((message) => {
                  this.toastrService.error(this.translate.instant(this.errorMessages[message] || message));
                })
              : this.toastrService.error(this.translate.instant(this.errorMessages[errorMessage] || errorMessage));
            break;
          case 500:
            setTimeout(() => {
              if (this.toastrService.currentlyActive == 0) {
                this.toastrService.error(this.translate.instant('TOASTR.INTERNAL_SERVER_ERROR'));
              }
            }, 0);
            break;
          default:
            errorMessage instanceof Array
              ? errorMessage.forEach((message) => {
                  this.toastrService.error(this.translate.instant(this.errorMessages[message] || message));
                })
              : this.toastrService.error(this.translate.instant(this.errorMessages[errorMessage] || errorMessage));
            break;
        }

        return throwError(() => error);
      }),

      finalize(() => {
        if (started) {
          this.spinner.hide();
          started = false;
        }
      }),
    );
  }
}

@NgModule({
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true,
    },
  ],
})
export class HttpInterceptorModule {}
