import { Injectable, inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpResponse, HttpClient, HttpParams, HttpContext } from '@angular/common/http';
import { SupplierResourceParameter } from '@core/domain-classes/supplier-resource-parameter';
import { Supplier } from '@core/domain-classes/supplier';
import { Guid } from 'guid-typescript';
import { SupplierPayment } from '@core/domain-classes/supplier-payment';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { mergeMap, take } from 'rxjs/operators';
import { PASS_SPINNER } from '../../shared/interceptors/http-interceptor.module';

@Injectable({
  providedIn: 'root',
})
export class SupplierService {
  constructor(private http: HttpClient) {}

  static getTransactionsResolver(route: ActivatedRouteSnapshot) {
    return inject(SupplierService).getTransactions(route.paramMap.get('id'));
  }

  getTransactions(id: string) {
    return this.http.get(`suppliertransaction?supplierId=${id}`);
  }

  getTransaction(id: string) {
    return this.http.get(`suppliertransaction/${id}`);
  }

  sendSupplierExtra(id) {
    return this.http.post(`supplier/sendsupplierextre`, {
      supplierId: id,
    });
  }

  addTransaction(transaction) {
    return this.http.post('suppliertransaction', transaction);
  }

  updateTransaction(transaction) {
    return this.http.put(`suppliertransaction/${transaction.id}`, transaction);
  }

  deleteTransaction(id) {
    return this.http.delete(`suppliertransaction/${id}`);
  }

  static getExtraResolver(route: ActivatedRouteSnapshot) {
    return inject(SupplierService).getExtra(route.paramMap.get('id'));
  }

  getExtra(id: string) {
    return this.http.get(`supplier/getsupplierextre/${id}`).toPromise();
  }

  static getSupplierResolver(route: ActivatedRouteSnapshot) {
    const id = route.paramMap.get('id');
    if (id === 'add') {
      return null;
    }
    return inject(SupplierService)
      .getSupplier(id)
      .pipe(
        take(1),
        mergeMap((supplier) => {
          if (supplier) {
            return of(supplier);
          } else {
            inject(Router).navigate(['/supplier']);
            return null;
          }
        }),
      );
  }

  static getSuppliersResolver() {
    const supplierResource = new SupplierResourceParameter();
    supplierResource.pageSize = 50;
    supplierResource.orderBy = 'supplierName asc';

    return inject(SupplierService).getSuppliers(supplierResource);
  }

  getSuppliers(resourceParams?: SupplierResourceParameter, passSpinner = false): Observable<HttpResponse<Supplier[]>> {
    const url = 'supplier';
    const customParams = new HttpParams()
      .set('Fields', resourceParams.fields)
      .set('OrderBy', resourceParams.orderBy)
      .set('PageSize', resourceParams.pageSize.toString())
      .set('Skip', resourceParams.skip.toString())
      .set('SearchQuery', resourceParams.searchQuery)
      .set('supplierName', resourceParams.supplierName)
      .set('mobileNo', resourceParams.mobileNo)
      .set('email', resourceParams.email)
      .set('country', resourceParams.country ? resourceParams.country : '')
      .set('website', resourceParams.website);
    if (resourceParams.id) {
      customParams.append('id', resourceParams.id);
    }
    return this.http.get<Supplier[]>(url, {
      params: customParams,
      observe: 'response',
      context: new HttpContext().set(PASS_SPINNER, passSpinner),
    });
  }

  getSupplier(id: string): Observable<Supplier> {
    const url = 'supplier/' + id;
    return this.http.get<Supplier>(url);
  }
  deleteSupplier(id: string): Observable<void> {
    const url = `supplier/${id}`;
    return this.http.delete<void>(url);
  }
  updateSupplier(id: string, supplier: Supplier): Observable<Supplier> {
    const url = 'supplier/' + id;
    return this.http.put<Supplier>(url, supplier);
  }
  saveSupplier(supplier: Supplier): Observable<Supplier> {
    const url = 'supplier';
    return this.http.post<Supplier>(url, supplier);
  }

  checkEmailOrPhoneExist(email: string, mobileNo: string, supplierId: string | Guid): Observable<boolean> {
    const url = `supplier/${supplierId}/Exist?email=${email}&mobileNo=${mobileNo}`;
    return this.http.get<boolean>(url);
  }

  getSuppliersForDropDown(searchString: string): Observable<Supplier[]> {
    const url = 'SupplierSearch';
    if (searchString) {
      let params = `?searchQuery=${searchString.trim()}&pageSize=10`;
      return this.http.get<Supplier[]>(url + params);
    }
    return of([]);
  }

  static getSupplierPaymentsResolver() {
    const supplierResource = new SupplierResourceParameter();
    supplierResource.pageSize = 10;
    supplierResource.orderBy = 'supplierName asc';

    return inject(SupplierService).getSupplierPayments(supplierResource);
  }

  getSupplierPayments(resourceParams: SupplierResourceParameter): Observable<HttpResponse<SupplierPayment[]>> {
    const url = 'supplier/GetSupplierPayment';
    const customParams = new HttpParams()
      .set('Fields', resourceParams.fields)
      .set('OrderBy', resourceParams.orderBy)
      .set('PageSize', resourceParams.pageSize.toString())
      .set('Skip', resourceParams.skip.toString())
      .set('SearchQuery', resourceParams.searchQuery)
      .set('supplierName', resourceParams.supplierName);
    return this.http.get<SupplierPayment[]>(url, {
      params: customParams,
      observe: 'response',
    });
  }

  getSupplierPaymentsExcel(resourceParams: SupplierResourceParameter): Observable<HttpResponse<SupplierPayment[]>> {
    const url = 'supplier/GetSupplierPayment';
    const customParams = new HttpParams()
      .set('Fields', resourceParams.fields)
      .set('OrderBy', resourceParams.orderBy)
      .set('PageSize', resourceParams.pageSize.toString())
      .set('Skip', resourceParams.skip.toString())
      .set('SearchQuery', resourceParams.searchQuery)
      .set('supplierName', resourceParams.supplierName);
    return this.http.get<SupplierPayment[]>(url, {
      params: customParams,
      observe: 'response',
    });
  }
}
