import { Direction } from '@angular/cdk/bidi';
import { Component, ViewChild } from '@angular/core';
import { TranslationService } from '@core/services/translation.service';
import { SubSink } from 'subsink';
import { environment } from '../environments/environment';
import { AppValidators, AppRoutes, AppClaims } from './shared/constants';
import { paymentMethods } from './core/domain-classes/payment-method';
@Component({
  template: '',
})
export class BaseComponent {
  paymentMethods = paymentMethods;

  routes = AppRoutes;
  claims = AppClaims;
  validators = AppValidators;
  env = environment;
  loc = location;
  his = history;
  baseUrl = environment.apiUrl;

  taxTypes = [
    { id: 'TCKN', name: 'TCKN' },
    { id: 'VKN', name: 'VKN' },
  ];

  date = new Date();

  pass = 'pass';

  sub$: SubSink = new SubSink();
  langDir: Direction = 'ltr';

  constructor(public translationService: TranslationService) {
    this.sub$ = new SubSink();
    this.getLangDir();
  }

  ngAfterViewInit(): void {
    document.querySelectorAll('label').forEach((label) => {
      if (label.innerHTML.includes('*')) {
        label.classList.remove('text-danger');
        label.innerHTML = label.innerHTML.replace('*', '<span class="text-danger">*</span>');
      }
      if (!label.classList.contains('form-label')) {
        label.classList.add('form-label');
      }
    });
  }

  getLangDir() {
    this.sub$.sink = this.translationService.lanDir$.subscribe((c: Direction) => {
      this.langDir = c;
    });
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
